/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import { Modal, Button, Row, Col, ListGroup, FloatingLabel, Fade, InputGroup, Alert, Form, Spinner } from "react-bootstrap";
import common from "../../../services/common";
import { loadScript } from "@paypal/paypal-js";
import { toast } from "react-toastify";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

const PaypalPaymentForm = (props) => {
    const [loader, setLoader] = useState(true);
    const [renderForm, setRenderForm] = useState(false);
    const [totalBankCharges, setTotalBankCharges] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0);
    const [payPalClientId, setPayPalClientId] = useState(null);
    const [merchantId, setMerchanttId] = useState(null);
    const [BNCode, setBNCode] = useState(null);
    const [errors, setErrors] = useState(false);
    const [showOtherTip, setOtherTip] = useState(false);
    const [calculatedTip, setCalculatedTip] = useState(0);
    const exceptThisSymbols = ["e", "E", "+", "-", "."];
    const [configurationError, setConfigurationError] = useState(false);

    const bankChargePercentage = 3;
    const [fields, setFields] = useState({
        amount:props.amount ? props.amount : 0,
        tipPercent:12,
        phone:props.phone ? props.phone : ''
    });

    useEffect(()=>{
        if(payPalClientId === null){
            let params;
            if(props.eventDetails){
                params = {event_id:props.eventDetails.id}
            }
            else{
                params = {user_id:props.userDetails.id}
            }
            common.getPaypalConfig(params).then((res) => {
                if (res.data.success) {
                    setPayPalClientId(res.data.client_id);
                    setMerchanttId(res.data.paypal_merchant_id);
                    setBNCode(res.data.BN_Code);
                    let script1 = document.createElement("script");
                    script1.type = "application/json";
                    //script1.fncls = "fnparams-dede7cc5-15fd-4c75-a9f4-36c430ee3a99";
                    script1.text = `{
                        "f": "${res.data.guid}",
                        "s": "${res.data.paypal_merchant_id}_${res.data.page_id}",
                        "sandbox": false
                    }`;
                    script1.setAttribute('fncls','fnparams-dede7cc5-15fd-4c75-a9f4-36c430ee3a99');
                    document.body.appendChild(script1);

                    let script2 = document.createElement("script");
                    script2.type = "application/json";
                    script2.src = 'https://c.paypal.com/da/r/fb.js';
                    document.body.appendChild(script2);
                    setLoader(false);
                } else if (res.data.error) {
                  setConfigurationError(true);
                  setLoader(false);
                }
            });
        }
    },[payPalClientId]);

    useEffect(()=>{
        let totalAmount = 0;
        let tip = 0;
        let backCharges = 0;
        if(fields.tipPercent !== 'Other'){
            tip = fields.amount * parseInt(fields.tipPercent)/100
        }
        else if(fields.tipDoller){
            tip = parseInt(fields.tipDoller);
        }
        if(fields.amount){
            backCharges = (parseInt(fields.amount) * (bankChargePercentage/100));
            totalAmount = parseInt(fields.amount) + parseFloat(tip) + parseFloat(backCharges);
        }
        setCalculatedTip(parseFloat(tip).toFixed(2));
        setTotalBankCharges(backCharges.toFixed(2));
        setTotalAmount(totalAmount.toFixed(2));
    },[fields]);
    
    const loadPayPalForm = () => {
        let errors = {};
        if(parseInt(totalAmount) === 0){
            errors['amount'] = 'Please enter the amount';
            setErrors(errors);
            return false;
        }
        if(props.capturePhone === true){
            if(!fields.phone){
                errors['phone'] = 'Please enter phone.';
                setErrors(errors);
                return false;
            }
            if(fields.phone && !fields.phone.match(/^[+]?(\d{1,2})?[\s.-]?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/)){
                errors['phone'] = 'Please enter valid phone number.';
                setErrors(errors);
                return false;
            }
        }
        
        setRenderForm(true);
        
        if(props.subscription){
            /* loadScript({"client-id": payPalClientId,  "merchant-id": merchantId, "data-partner-attribution-id": BNCode, vault:true, intent:"subscription"}) */
            loadScript({"client-id": payPalClientId, "data-partner-attribution-id": BNCode, vault:true, intent:"subscription"})
            .then((paypal) => {
                paypal
                    .Buttons({
                        createSubscription: function(data, actions) {
                            return actions.subscription.create({
                                'plan_id': props.subscription
                            });
                        },
                        onApprove: function(data, actions) {
                            return actions.order.capture().then(function(details) {
                                if(details.status === 'COMPLETED'){
                                    toast.success("Subscription has been created successfully.", {
                                        position: toast.POSITION.TOP_RIGHT,
                                    });
                                    props.closeModal();
                                }
                                //window.location.href = '/success.html';
                            });
                        }    
                    })
                    .render("#paypal-form-container")
                    .catch((error) => {
                        console.error("failed to render the PayPal Buttons", error);
                    });
            })
            .catch((error) => {
                console.error("failed to load the PayPal JS SDK script", error);
            });
        }
        else{
            loadScript({"client-id": payPalClientId,  "merchant-id": merchantId, "data-partner-attribution-id": BNCode, currency: props.eventDetails ? common.currencySymbols[props.eventDetails.currency] : 'USD', "enable-funding":'venmo'})
            .then((paypal) => {
                paypal.Buttons({
                        createOrder: function(data, actions) {
                            let customId;
                            let description;
                            description = 'Profile Payments';
                            if(props.eventDetails){
                                customId = {event_id:props.eventDetails.id}
                                description = 'Event: ' + props.eventDetails.title;
                            }
                            if(props.booking_id){
                                customId = {...customId, booking_id:props.booking_id}
                            }
                            if(props.userDetails){
                                customId = {...customId, user_id:props.userDetails.id}
                            }
                            if(props.invoiceId && props.invoiceId !== null){
                                customId = {...customId, invoice_id:props.invoiceId}
                            }
                            if(props.programId && props.programId !== null){
                                customId = {...customId, program_id:props.programId}
                            }
                            if(props.capturePhone && props.capturePhone === true){
                                customId = {...customId, phone:fields.phone}
                            }
                            if(fields.display_name && fields.display_name !== ''){
                                customId = {...customId, display_name:fields.display_name}
                            }
                            return actions.order.create({
                                purchase_units: [{
                                    description: description,
                                    custom_id:JSON.stringify(customId),
                                    amount: {
                                        value: totalAmount,
                                        currency_code: props.eventDetails ? common.currencySymbols[props.eventDetails.currency]: 'USD'
                                    },
                                    payment_instruction:{
                                        platform_fees: [{
                                            amount: {
                                                currency_code: props.eventDetails ? common.currencySymbols[props.eventDetails.currency]: 'USD',
                                                value: calculatedTip
                                            }
                                        }]}
                                    }
                                ]
                            });
                        },
                        onApprove: function(data, actions) {
                            return actions.order.capture().then(function(details) {
                                if(details.status === 'COMPLETED'){
                                    toast.success("Payment made successfully.", {
                                        position: toast.POSITION.TOP_RIGHT,
                                    });
                                    props.closeModal();
                                }
                                //window.location.href = '/success.html';
                            });
                        }    
                }).render("#paypal-form-container").catch((error) => {
                    console.error("failed to render the PayPal Buttons", error);
                });
            })
            .catch((error) => {
                console.error("failed to load the PayPal JS SDK script", error);
            });
        }
        
    }

    const handleChange = (e, field) => {
        if(e.target.type === 'select-one'){
            if(e.target.value === 'Other'){
                setOtherTip(true);
            }
            else{
                setOtherTip(false);
            }
        }
        setFields({ ...fields, [field]: e.target.value });
    };
    return (
        <Modal
            show={props.showModal}
            onHide={props.closeModal}
            backdrop="static"
            centered
        >
            <Modal.Header closeButton><h4>Payment Details</h4></Modal.Header>
            <Modal.Body className="p-0">
                {loader ? <Row>
                    <Col className='d-flex align-items-center justify-content-center' style={{height:400}}><Spinner style={{height:50, width: 50}}/></Col>
                </Row>:<Row>
                    {renderForm ? <Col md={12} className="my-auto text-center" style={{minHeight:250}}>
                        <div id="paypal-form-container" className="p-5"></div>
                    </Col>:configurationError ? <Col md={12}><Alert variant="danger" className="mb-0">It seems Event Organizer has not linked Paypal account. Kindly contact administrator.</Alert></Col> : <Col md={12}>
                        <Row>
                            <Col md={12}>
                                <ListGroup>
                                    {props.eventDetails && <ListGroup.Item>
                                        <div className="float-start">
                                            <h6 className="my-0"><strong>Event</strong></h6>
                                            <small className="text-muted">{props.eventDetails.title}</small>
                                        </div>
                                    </ListGroup.Item>}

                                    <ListGroup.Item>
                                        {props.editAmount ? 
                                            <FloatingLabel
                                                controlId="floatingInput"
                                                label="Enter amount"
                                                className="mb-2"
                                            >
                                            <Form.Control
                                                type="number"
                                                name="amount"
                                                placeholder="Enter amount"
                                                onChange={(event) => handleChange(event, "amount")}
                                                isInvalid={errors.amount ? true : false}
                                                value={fields.amount > 0 && fields.amount} 
                                            />
                                            <Form.Control.Feedback type="invalid">Please enter the amount</Form.Control.Feedback>
                                            </FloatingLabel> : <div className="w-100 float-left mb-2">
                                            <div className="float-start">
                                                <h6 className="my-0"><strong>Amount</strong></h6>
                                            </div>
                                            <span className="text-muted float-end"><strong>{common.currencySymbolsArr[props.eventDetails.currency]}{fields.amount}</strong></span>
                                        </div>}
                                        {props.capturePhone && <>
                                            <PhoneInput
                                                country={'us'}
                                                value={fields.phone ? fields.phone : ""}
                                                onChange={(phone) => {
                                                    fields['phone'] = '+'+phone;
                                                    setFields({...fields});
                                                }}
                                                inputClass="w-100"
                                                enableSearch={true}
                                                countryCodeEditable={false}
                                                className="mb-2"
                                            />
                                            {errors.phone && <p className="text-danger">{errors.phone}</p>}
                                        </>}
                                        <FloatingLabel
                                            label="Display Name"
                                            className="mb-2"
                                        >
                                        <Form.Control
                                            type="text"
                                            name="display_name"
                                            placeholder="Enter amount"
                                            onChange={(event) => handleChange(event, "display_name")}
                                            isInvalid={!!errors.display_name}
                                        />
                                        <small><strong>Display Name: </strong> If you would like to credit a different name for your giving</small>
                                        </FloatingLabel>
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <div className="float-start">
                                            <h6 className="my-0"><strong>Bank Charges</strong></h6>
                                            <small className="text-muted">{bankChargePercentage}% of donation amount</small>
                                        </div>
                                        <span className="text-muted float-end"><strong>{props.eventDetails ? common.currencySymbolsArr[props.eventDetails.currency] : '$'}{totalBankCharges}</strong></span>
                                    </ListGroup.Item>
                                    <ListGroup.Item className="bg-warning text-dark">
                                        <Row>
                                            <Col md={12}>
                                                <h5><strong>Add a tip to support TipHub</strong></h5>
                                                <p>TihHub does not charge any platform fees and relies on your generosity to support this free service.</p>
                                            </Col>
                                            <Col md={6}>
                                                <Form.Select onChange={(event) => handleChange(event, "tipPercent")} value={fields.tipPercent}>
                                                    <option value="12">12%</option>
                                                    <option value="15">15%</option>
                                                    <option value="7">7%</option>
                                                    <option value="Other">Other</option>
                                                </Form.Select>
                                            </Col>
                                            <Col md={6}>
                                                <Fade in={showOtherTip} tag="div">
                                                    <InputGroup>
                                                        <InputGroup.Text>$</InputGroup.Text>
                                                        <Form.Control type="number" name="tipDoller" onChange={(event)=>handleChange(event,'tipDoller')} onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()} min={0}/>
                                                    </InputGroup>
                                                </Fade>
                                            </Col>
                                        </Row>
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <div className="float-start">
                                            <h5 className="my-0"><strong style={{fontSize:30}}>Total</strong></h5>
                                        </div>
                                        <strong className="float-end" style={{fontSize:30, lineHeight:'normal'}}>{props.eventDetails ? common.currencySymbolsArr[props.eventDetails.currency] : '$'}{totalAmount}</strong>
                                    </ListGroup.Item>
                                </ListGroup>
                            </Col>
                            
                        </Row>
                    </Col>}
                </Row>}
            </Modal.Body>
            {!renderForm && !configurationError && !loader && <Modal.Footer className="text-center d-block"><Button type="button" onClick={()=>loadPayPalForm()} color="success" size="lg" >Click Here To Proceed</Button></Modal.Footer>}
        </Modal>
    );
};

export default PaypalPaymentForm;

/* eslint-disable react/prop-types */
import React, { useEffect, useState, useRef } from "react";
import {
  Row,
  Col,
  Card,
  Container, Button
} from "react-bootstrap";
import { toast } from "react-toastify";
import events from "../../../services/events";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { FaUndo } from "react-icons/fa";

import MemberPaymentsStatus from "./MemberPaymentsStatus";
import TicketBookings from "./TicketBookings";
import GroupPayments from "./GroupPayments";
import ReligiousPayments from "./ReligiousPayments";
import { Helmet } from "react-helmet";
import SalePhysicalTickets from "./SalePhysicalTickets";
import { useParams } from "react-router-dom";

const EventReports = (props) => {
  const queryString = useParams();
  const [eventData, setEventData] = useState({});
  const userId = useSelector((state) => state.userId);
  const apiUrl = useSelector((state) => state.apiUrl);
  const [loader, setLoader] = useState(true);

  const fetchEventDetails = () => {
    let eventId = queryString.id;
    
    let params = { id: eventId };
    events
      .getOne(params, userId)
      .then((res) => {
        if (res.data.success) {
          setEventData(res.data.event);
          setLoader(false);
        } else if (res.data.error) {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(() => {
        toast.error("Unexpected Error !", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  useEffect(() => {
    fetchEventDetails();
    if(document.URL.includes('success')){
      toast.success("Payment app has been added successfully.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }, []);

  return (
    <div className="profile-overview event-details">
      <Helmet>
        <title>Event Report</title>
      </Helmet>
    <Container>
    
      <Row>
        <Col md="6">
          <h3>Event Reports</h3>
        </Col>
        <Col md="6" className="text-end">
          {userId && !loader && <SalePhysicalTickets eventDetails={eventData} marginBottom={true}/>}
          <Link
            className="btn btn-dark btn-sm mt-1 text-light" to={`/${eventData.url}/${eventData.id}`}
          >
            <FaUndo
              className="me-2"
            />
            Back To Event
          </Link>
        </Col>
        </Row>
        <Row>
        <Col md={12}>
          {userId && parseInt(userId) === parseInt(eventData.user_id) && parseInt(eventData.category) === 57 && (
              <div>
                <MemberPaymentsStatus
                  eventDetails={eventData}
                />
              </div>
            )}
              {userId && (parseInt(eventData.category) === 59 || parseInt(eventData.category) === 71) && <Card>
                <Card.Body>
                    <TicketBookings eventData={eventData} />
                </Card.Body>
              </Card>
            }
            {userId && parseInt(userId) === parseInt(eventData.user_id) && parseInt(eventData.category) === 70 && 
              <Card>
                <Card.Body>
                  <GroupPayments eventData={eventData} />
                </Card.Body>
            </Card>
            }
            {userId && parseInt(userId) === parseInt(eventData.user_id) && parseInt(eventData.category) === 75 && 
              <Card>
                <Card.Body>
                  <ReligiousPayments eventData={eventData} />
                </Card.Body>
            </Card>
            }
        </Col>
      </Row>
    </Container>
    </div>
  );
};

export default EventReports;

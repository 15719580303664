import React, { Component } from "react";
import events from "../../../services/events";
import {
  Row,
  Col,
  Button,
  Spinner,
  Form,
  Modal,
  ModalHeader,
  ModalBody,
  FormFeedback,
  FormGroup,
} from "react-bootstrap";
import { toast } from "react-toastify";
import common from "../../../services/common";

class AddEditPermission extends Component {
  constructor(props) {
    super(props);
    this.state = {
      permissionLoading: false,
      users: [],
      fields: { event_id: this.props.selectedEventId },
      errors: {},
      permissionList: [],
      submitted: false,
      userDetails: {},
    };
  }

  componentDidMount = () => {
    if (this.props.eventId !== null) {
      events.getTeamDetails({ id: this.props.eventId }).then((res) => {
        this.setState({ fields: res.data.team });
      });
    } else {
      events.getUsers().then((res) => {
        if (res.data.success) {
          this.setState({ users: res.data.team });
        }
      });
    }
    this.setState({ permissionLoading: true });
    /* events.permissionList({ module_name: "Event" }).then((res) => {
      if (res.data.success) {
        this.setState({
          permissionList: res.data.actions,
          permissionLoading: false,
        });
      }
    }); */
  };

  handleChange = (e, field) => {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
  };

  submitHandler = (e) => {
    if (this.props.eventId) {
      let fields = this.state.fields;
      fields["id"] = this.props.eventId;
    }
    e.preventDefault();
    this.setState({ submitted: true });
    events.addPermission({ fields: this.state.fields }).then((res) => {
      if (res.data.success) {
        let fields = this.state.fields;
        fields["permissions"] = [];
        fields["team_id"] = null;
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        this.setState({ fields });
        this.props.addPermissionModal();
        this.props.fetchTeamMemberList();
      } else if (res.data.error) {
        this.setState({ errors: res.data.message });
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      this.setState({ submitted: false });
    });
  };

  /* choosePermission = (e, ele) => {
    let fields = this.state.fields;
    if (e.target.className.includes("selected-permission")) {
      let permissions = fields["permissions"].filter(
        (p) => p.action_name !== ele.action_name
      );
      fields["permissions"] = permissions;
      this.setState({ fields });
    } else {
      fields["permissions"].push(ele);
      this.setState({ fields });
    }
  }; */

  /* applyClassName = (ele) => {
    for (let i = 0; i < this.state.fields.permissions.length; i++) {
      if (ele.action_name === this.state.fields.permissions[i].action_name) {
        return "mx-2 my-1 btn-sm selected-permission";
      }
    }
    return "mx-2 my-1 btn-sm";
  }; */
  render() {
    return (
      <Modal size="md" show={this.props.showModal} onHide={() => this.props.addPermissionModal()} 
      backdrop="static"
      keyboard={false}
      centered>
        <Modal.Header closeButton>
          <h4>{this.props.eventId ? "Update" : "Assign"} Permission</h4>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={this.submitHandler}>
            <Row>
              <Col>
                {this.props.eventId ? (
                  <Row>
                    <Col sm={6}>
                      <Form.Label>Username:</Form.Label>
                      <Form.Control
                        type="text"
                        value={
                          this.state.fields.team &&
                          this.state.fields.team.username
                        }
                        // value={"User Name"}
                        disabled
                      />
                    </Col>
                    <Col sm={6}>
                      <Form.Label>Email:</Form.Label>
                      <Form.Control
                        type="text"
                        value={
                          this.state.fields.team && this.state.fields.team.email
                        }
                        disabled={true}
                      />
                    </Col>
                    <Col md={12} className="mt-2">
                      <Form.Group className="mb-1">
                        <Form.Label
                          htmlFor="role"
                          className="mb-0 font-weight-bold"
                        >
                          Choose Role
                        </Form.Label>
                      </Form.Group>
                      {common.eventRoleArr.map((role, index) => (
                        <Form.Group check inline>
                          <Form.Label>
                            <Form.Control
                              type="radio"
                              value={index + 1}
                              name="role"
                              onClick={(event) =>
                                this.handleChange(event, "role")
                              }
                              checked={
                                parseInt(this.state.fields.role) === index + 1
                              }
                            />{" "}
                            {role.label}
                          </Form.Label>
                        </Form.Group>
                      ))}
                      {this.state.errors["role"] && (
                        <p style={{ color: "red" }}>
                          {this.state.errors["role"]}
                        </p>
                      )}
                    </Col>
                  </Row>
                ) : (
                  <>
                    <Form.Group>
                      <Form.Select
                        name="team_id"
                        id="team_id"
                        onChange={(e) => this.handleChange(e, "team_id")}
                        invalid={this.state.errors.team_id ? true : false}
                      >
                        <option value="">Select Team Member</option>
                        {this.state.users.map((t, index) => (
                          <option value={t.id} key={`team-${index}`}>
                            {t.username}
                          </option>
                        ))}
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        {this.state.errors.team_id}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-1">
                      <Form.Label htmlFor="role" className="mb-0 font-weight-bold">
                        Choose Role
                      </Form.Label>
                    </Form.Group>
                    {common.eventRoleArr.map((role, index) => (
                      <Form.Check
                        type="radio"
                        value={index + 1}
                        name="role"
                        onClick={(event) =>
                          this.handleChange(event, "role")
                        }
                        checked={
                          parseInt(this.state.fields.role) === index + 1
                        }
                        label={role.label}
                        inline
                      />
                    ))}
                    {this.state.errors["role"] && (
                      <p style={{ color: "red" }}>
                        {this.state.errors["role"]}
                      </p>
                    )}
                  </>
                )}
              </Col>
            </Row>
            {/* <hr />
            <Row>
              <Col>
                  <h5 className="mb-4">Choose permissions</h5>
                  {this.state.permissionLoading ? (
                    <Spinner color="#887d7d" className="spinner-permission"/>
                  ) : (
                    this.state.permissionList.length > 0 &&
                    this.state.permissionList.map((ele, index) => {
                      return (
                        <Button
                          outline
                          key={index}
                          color="primary"
                          type="button"
                          className={this.applyClassName(ele)}
                          onClick={(e) => this.choosePermission(e, ele)}
                        >
                          {ele.action_label_name}
                        </Button>
                      );
                    })
                  )}
              </Col>
            </Row> */}
            <Row>
              <Col className="text-end mt-3" md={12}>
                <Button
                  variant="danger"
                  className="me-1"
                  type="button"
                  onClick={() => this.props.addPermissionModal()}
                  size="sm"
                >
                  Cancel
                </Button>
                <Button
                  variant="success"
                  className="me-1"
                  type="submit"
                  disabled={this.state.submitted}
                  size="sm"
                >
                  {this.state.submitted && (
                    <Spinner
                    variant="light"
                      size="sm"
                    />
                  )}
                  Submit
                </Button>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    );
  }
}

export default AddEditPermission;

/* eslint-disable react/prop-types */
import React, { Component, useState } from "react";
import {
  Button,
  Form,
  Spinner,
  Table,
  Row,
  Col,Modal, FloatingLabel, InputGroup
} from "react-bootstrap";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import LoadingOverlay from "react-loading-overlay";
import events from "../../../services/events";
import moment from "moment";
import Pagination from "react-js-pagination";
import { FaCheckCircle, FaFileExcel } from "react-icons/fa";
import PrintTickets from "./PrintTickets";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';


class TicketBookings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showMenu: false,
      showModal: false,
      pageNo: 1,
      bookings: [],
      pageDetails: {},
      loader: false,
      fields: {
        sms_content:this.props.eventData.title+`\n< Write your message here >`, 
        email_subject:this.props.eventData.title, 
        broadcast_sms:false, 
        broadcast_email: false
      },
      submitted: false,
      tickets:[],
      broadCastSmsModal:false,
      broadCastEmailModal:false,
      printTicketModal:false,
      bookingId:null //Need this to get tickets to be printed.
    };
  }
  handleChange(e, field) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
  }
  handleCheckboxChange = (e, field) => {
    let fields = this.state.fields;
    fields[field] = e.target.checked ? 1 : 0;
    this.setState({ fields });
  };

  handlePageChange = (pageNo) => {
    this.setState({ pageNo }, () => {
      this.getBookings();
    });
  };
  getBookings = (e) => {
    if (e) e.preventDefault();
    this.setState({ loader: true });
    events
      .getBookings({
        event_id: this.props.eventData.id,
        page: this.state.pageNo,
        fields: JSON.stringify(this.state.fields),
        export: false,
      })
      .then((res) => {
        if (res.data.success) {
          let fields = this.state.fields;
          fields['broadcast_sms'] = false;
          fields['broadcast_email'] = false;
          if(res.data.fields.broadcast_sms){
            toast.success('Sms has been sent successfully.', {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
          if(res.data.fields.broadcast_email){
            toast.success('Email has been sent successfully.', {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
          this.setState({
            fields,
            bookings: res.data.bookings,
            loader: false,
            pageDetails: res.data.pages,
          });
        } else if (res.data.error) {
          console.log(res.data.message);
        }
        this.setState({ loader: false });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  exportExcel = () => {
    events
      .getBookingsXls({
        event_id: this.props.eventData.id,
        page: this.state.pageNo,
        fields: this.state.fields,
        export: true,
      })
      .then((res) => {
        if (res) {
          const fileDetails = new Blob([res.data], {
            type: res.data.type,
            encoding: "UTF-8",
          });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(fileDetails);
          link.download = this.props.userName + "-" + this.props.eventData.url + "-" + moment().format("MM-DD-YYYY") + ".xlsx";
          link.click();
          link.remove();
        } else if (res.data.error) {
          toast.error(res.data.error.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(() => {
        toast.error("Unexpected error !", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  confirmBooking = (id) => {
    this.setState({ loader: true });
    events
      .confirmBooking({
        booking_id: id,
      })
      .then((res) => {
        if (res.data.success) {
          this.getBookings();
        } else if (res.data.error) {
          console.log(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  deleteRecord = (id) => {
    if (window.confirm("Are you sure to delete?")) {
      let params = { id: id, event_id: this.props.eventData.id };
      events
        .deleteBooking(params)
        .then((response) => {
          if (response.data.success) {
            let records = this.state.bookings.filter(
              (r) => parseInt(r.id) !== parseInt(id)
            );
            this.setState({ bookings: records }, () => {
              toast.success(response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
              });
            });
          } else {
            toast.error(response.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error("Unexpected error !", {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }
  };
  checkInBooking = (id) => {
    this.setState({ loader: true });
    events
      .checkInBooking({
        ticket_id: id,
      })
      .then((res) => {
        if (res.data.success) {
          this.getBookings();
        } else if (res.data.error) {
          console.log(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  checkOutBooking = (id) => {
    if(window.confirm('Are you sure you want to uncheck this ticket?')){
      this.setState({ loader: true });
      events
        .checkOutBooking({
          ticket_id: id,
        })
        .then((res) => {
          if (res.data.success) {
            this.getBookings();
          } else if (res.data.error) {
            console.log(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    
  };
  componentDidMount = () => {
    events
      .getTickets({
        event_id: this.props.eventData.id,
      })
      .then((res) => {
        if (res.data.success) {
          this.setState({
            tickets: res.data.tickets,
          });
        } else if (res.data.error) {
          console.log(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    this.getBookings();
  };
  followUpBuyer = (id) => {
    this.setState({ loader: true });
    events
      .followUpBuyer({
        booking_id: id,
      })
      .then((res) => {
        if (res.data.success) {
          this.setState({ loader: false }, () => {
            toast.success(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          });
        } else if (res.data.error) {
          console.log(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  updateFields = (fields) => {

  }
  render() {
    return (
      <div className="mt-3">
        <Form onSubmit={this.getBookings}>
          <Row>
            <Form.Group as={Col} md={3}>
              <FloatingLabel
                label="First Name"
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  name="first_name"
                  placeholder="first_name"
                  onChange={(event) => this.handleChange(event, "first_name")}
                />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md={3}>
              <FloatingLabel
                label="Last Name"
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  name="last_name"
                  placeholder="last_name"
                  onChange={(event) => this.handleChange(event, "last_name")}
                />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md={4}>
              <FloatingLabel
                label="Email"
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  name="email"
                  placeholder="email"
                  onChange={(event) => this.handleChange(event, "email")}
                />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md={2}>
              <FloatingLabel
                label="Ticket No"
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  name="ticket_number"
                  placeholder="ticket_number"
                  onChange={(event) => this.handleChange(event, "ticket_number")}
                />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md={4}>
              <FloatingLabel
                label="Ticket No"
                className="mb-3"
              >
                <Form.Select
                  type="select"
                  name="ticket_number"
                  onChange={(event) => this.handleChange(event, "ticket_number")}
                >
                  <option value="">Ticket Type</option>
                  {this.state.tickets.length > 0 &&
                    this.state.tickets.map((ticket, index) => (
                      <option
                        value={ticket.id}
                        key={`ticket-${index}`}
                      >
                        {ticket.name}
                      </option>
                    ))}
                </Form.Select>
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md={3}>
              <InputGroup>
                <InputGroup.Text className="p-3 w-100"><Form.Check type="checkbox" name="status" onChange={(e) => {this.handleCheckboxChange(e, "status");}} label="Confirmed"/></InputGroup.Text>
              </InputGroup>
            </Form.Group>  
            <Form.Group as={Col} md={3}>
              <InputGroup>
                <InputGroup.Text className="p-3 w-100"><Form.Check type="checkbox" name="status_unconfirmed" onChange={(e) => {this.handleCheckboxChange(e, "status_unconfirmed");}} label="Unconfirmed"/></InputGroup.Text>
              </InputGroup>
            </Form.Group>  
            <Form.Group as={Col} md={2}>
              <InputGroup>
                <InputGroup.Text className="p-3 w-100"><Form.Check type="checkbox" name="checked_in" onChange={(e) => {this.handleCheckboxChange(e, "checked_in");}} label="Checked In"/></InputGroup.Text>
              </InputGroup>
            </Form.Group>  
            <Form.Group as={Col} md={parseInt(this.props.eventData.user_id) !== parseInt(this.props.userId) ? 12 : 3} sm={12} className={parseInt(this.props.eventData.user_id) !== parseInt(this.props.userId) ? 'text-end d-grid' : 'text-start d-grid'}>
              <Button
                  type="submit"
                  variant="success"
                  disabled={this.state.submitted}
                  size="lg"
                >
                  {this.state.submitted && <Spinner size="sm" />} Search
                </Button>
            </Form.Group>  
            {parseInt(this.props.eventData.user_id) === parseInt(this.props.userId) && <>
              <Form.Group as={Col} md={3} className="d-grid">
                <Button
                    type="button"
                    variant="danger"
                    name="export"
                    disabled={this.state.bookings.length === 0}
                    onClick={() => this.setState({broadCastSmsModal:true})}
                    size="lg"
                  >Broadcast SMS
                </Button>
              </Form.Group>  
              <Form.Group as={Col} md={3} className="d-grid">
                <Button
                  type="button"
                  variant="info"
                  name="export"
                  disabled={this.state.bookings.length === 0}
                  onClick={() => this.setState({broadCastEmailModal:true})}
                  size="lg"
                >Broadcast Email
                </Button>
              </Form.Group>  
              <Form.Group as={Col} md={3} className="d-grid">
                <Button
                  type="button"
                  variant="warning"
                  name="export"
                  disabled={this.state.bookings.length === 0}
                  onClick={(e) => this.exportExcel(e)}
                  size="lg"
                >
                  {this.state.submitted && <Spinner size="sm" />}
                  <FaFileExcel size="1em" className="mb-1 mr-1" /> Export
                </Button>
              </Form.Group>  
            </>}
          </Row>
        </Form>
        <Row className="mt-2">
          <Col>
            <LoadingOverlay
              active={this.state.loader}
              spinner
              text="Loading. Please wait..."
            >
              <Table className="table table-responsive">
                <thead>
                  <tr>
                    <th className="border-top-0" style={{fontSize:20}}>#</th>
                    <th className="border-top-0" style={{fontSize:20}}>Name</th>
                    <th className="border-top-0" style={{fontSize:20}}>Email</th>
                    <th className="border-top-0" style={{fontSize:20}}>Phone</th>
                    <th className="border-top-0" style={{fontSize:20}}>Total</th>
                    <th className="border-top-0" style={{fontSize:20}}>Booked On</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.bookings.length > 0 ? (
                    this.state.bookings.map((ele, index) => (
                      <>
                        <tr key={`bookings-${index}`} className="bg-secondary bg-gradient">
                          <td className="border-bottom-0" style={{fontSize:18}}>
                            {this.state.pageNo === 1
                              ? index + 1
                              : (this.state.pageNo - 1) * 50 + index + 1}
                            .{" "}
                          </td>
                          <td className="border-bottom-0" style={{fontSize:18}}>
                            {ele.first_name} {ele.last_name}
                          </td>
                          <td className="border-bottom-0" style={{fontSize:18}}>{ele.email}</td>
                          <td className="border-bottom-0" style={{fontSize:18}}>{ele.phone}</td>
                          <td className="border-bottom-0" style={{fontSize:18}}>
                            ${ele.total_amount}
                          </td>
                          <td className="border-bottom-0" style={{fontSize:18}}>
                            {moment(ele.added_on).format("MMM Do, YYYY")}
                          </td>
                        </tr>
                        <tr className="bg-light bg-gradient">
                          <td className="border-top-0 pt-0" colSpan={6}>
                            <Table className="table mb-0">
                              <tr>
                                <td>
                                  <Table className="table mb-0">
                                    <thead>
                                      <tr>
                                        <th className="border-top-0">#</th>
                                        <th className="border-top-0">Ticket</th>
                                        <th className="border-top-0">Ticket Cateogry</th>
                                        <th className="border-top-0">Price</th>
                                        <th className="border-top-0">Total Tickets</th>
                                        <th className="border-top-0">Sub Total</th>
                                        <th className="border-top-0"></th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {ele.details.map((detail, dIndex) => (
                                        <tr key={`booking-details-${dIndex}`}>
                                          <td>{dIndex + 1}</td>
                                          <td>{detail.custom_ticket_id}</td>
                                          <td>{detail.ticket !== null && detail.ticket.name}</td>
                                          <td>
                                            $
                                            {detail.total_price /
                                              detail.total_tickets}
                                          </td>
                                          <td>{detail.total_tickets}</td>
                                          <td>${detail.total_price}</td>
                                          <td>
                                            {parseInt(detail.checked_in) === 1 ? (
                                                <Button
                                                  type="button"
                                                  size="sm"
                                                  className="m-1"
                                                  variant={parseInt(ele.is_physical_ticket) === 1 ? 'outline-danger' : 'outline-warning'}
                                                  onClick={() =>
                                                    this.checkOutBooking(detail.id)
                                                  }
                                                >
                                                  <FaCheckCircle /> Checked In
                                                </Button>
                                              ) : (
                                                <Button
                                                  type="button"
                                                  className="m-1"
                                                  size="sm"
                                                  variant={parseInt(ele.is_physical_ticket) === 1 ? 'outline-danger' : 'outline-warning'}
                                                  onClick={() =>
                                                    this.checkInBooking(detail.id)
                                                  }
                                                  outline={parseInt(ele.is_physical_ticket) === 1 ? true : false}
                                                >
                                                  Check In
                                                </Button>
                                              )}
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </Table>
                                </td>
                                <td style={{ verticalAlign: "middle" }} className="text-center">
                                
                                {parseInt(ele.is_physical_ticket) === 1 && parseInt(ele.team_id) !== parseInt(this.props.userId) && <h4 className="text-center"><strong>Sold By</strong> {ele.team.name}</h4>}
                                  {ele.service !== null && <Button type="button" variant="outline-secondary" size="sm" disabled><img src={`/assets/${ele.service.icon}`} width={20} /> {ele.app_username} </Button>}
                                  {parseInt(ele.status) === 1 ? (
                                    <Button
                                      type="button"
                                      className="m-1"
                                      variant="success"
                                      onClick={() =>
                                        this.confirmBooking(ele.id)
                                      }
                                    >
                                      <FaCheckCircle /> Confirmed
                                    </Button>
                                  ) : <>
                                    <Button
                                      type="button"
                                      className="m-1"
                                      variant="primary"
                                      onClick={() =>
                                        this.followUpBuyer(ele.id)
                                      }
                                      size="sm"
                                    >
                                      Send Follow Up
                                    </Button>
                                    <Button
                                      type="button"
                                      className="m-1"
                                      variant="success"
                                      onClick={() =>
                                        this.confirmBooking(ele.id)
                                      }
                                      size="sm"
                                    >
                                      Confirm
                                    </Button>
                                  </>
                                  }
                                  {parseInt(ele.is_physical_ticket) === 1 && <Button
                                    type="button"
                                    className="m-1"
                                    variant="warning"
                                    onClick={() => this.setState({bookingId:ele.id}, ()=> {
                                      this.setState({printTicketModal: true})
                                    })}
                                    size="sm"
                                  >
                                    Print
                                  </Button>}
                                  <Button
                                    type="button"
                                    className="m-1"
                                    variant="danger"
                                    onClick={() => this.deleteRecord(ele.id)}
                                    size="sm"
                                  >
                                    Delete
                                  </Button>
                                </td>
                              </tr>
                            </Table>
                          </td>
                        </tr>
                      </>
                    ))
                  ) : (
                    <tr>
                      <td
                        colSpan="11"
                        className="text-center"
                        style={{ height: 300 }}
                      >
                        Record not found.
                      </td>
                    </tr>
                  )}
                  <tr>
                    <td colSpan="11">
                      <Pagination
                        activePage={this.state.pageNo}
                        itemsCountPerPage={50}
                        totalItemsCount={
                          this.state.pageDetails.totalCount
                            ? parseInt(this.state.pageDetails.totalCount)
                            : 0
                        }
                        pageRangeDisplayed={5}
                        onChange={(e) => this.handlePageChange(e)}
                      />
                    </td>
                  </tr>
                </tbody>
              </Table>
            </LoadingOverlay>
          </Col>
        </Row>
        {this.state.broadCastSmsModal && <BroadCastSms 
          showModal = {this.state.broadCastSmsModal} 
          closeModal={()=>this.setState({broadCastSmsModal:false})}
          fields={this.state.fields}
          broadCastSms={(fields)=>this.setState({fields, broadCastSmsModal:false}, ()=>{
            this.getBookings();
          })}
        />}
        {this.state.broadCastEmailModal && <BroadCastEmail 
          showModal = {this.state.broadCastEmailModal} 
          closeModal={()=>this.setState({broadCastEmailModal:false})}
          fields={this.state.fields}
          broadCastEmail={(fields)=>this.setState({fields, broadCastEmailModal:false}, ()=>{
            this.getBookings();
          })}
        />}
        {this.state.printTicketModal && <PrintTickets 
          showModal={this.state.printTicketModal}
          bookingId={this.state.bookingId}
          closeModal={()=>this.setState({printTicketModal:false})}
        />}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    apiUrl: state.apiUrl,
    baseUrl: state.baseUrl,
    userId: state.userId,
    userName: state.userName,
  };
};
export default connect(mapStateToProps)(TicketBookings);


export function BroadCastSms(props) {
  const [submitted, setSubmitted] = useState(false)
  const [fields, setFields] = useState({...props.fields, broadcast_sms:true})
  const [errors, setErrors] = useState({})

  const handleChange = (e) => {
    setFields({...fields, [e.target.name]: e.target.value})
  }

  const handleSubmit = (e) => {
    const errors = {};
    e.preventDefault();
    if(!fields.sms_content){
      errors['sms_content'] = 'Sms content can not be blank.';
    }
    else{
      setSubmitted(true);
      props.broadCastSms(fields)
    }
    setErrors(errors);
  }
  return (
    <Modal show={props.showModal} onHide={()=>props.closeModal()} centered>
      <Modal.Header closeButton>Broadcast SMS</Modal.Header>
        <Form onSubmit={handleSubmit} method="post">
          <Modal.Body>
            <Form.Control
              as="textarea"
              id="sms_content"
              name="sms_content"
              rows={3}
              onChange={(event) => handleChange(event, "sms_content")}
              isInvalid={!!errors.sms_content}
            />
            <Form.Control.Feedback type="invalid">{errors.sms_content}</Form.Control.Feedback>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={()=>props.closeModal()}>Cancel</Button>
            <Button
              variant="success"
              type="submit"
              disabled={submitted}
            >
              {submitted && (
                <Spinner
                  color="light"
                  size="sm"
                />
              )}
              Send
            </Button>
          </Modal.Footer>
        </Form>
    </Modal>
  );
}

export function BroadCastEmail(props) {
  const [submitted, setSubmitted] = useState(false)
  const [fields, setFields] = useState({...props.fields, broadcast_email:true})
  const [errors, setErrors] = useState({})

  const handleChange = (e) => {
    setFields({...fields, [e.target.name]: e.target.value})
  }
  const handleEmailContent = (email_content) => {
    fields['email_content'] = email_content;
    setFields(fields)
  };
  const handleSubmit = (e) => {
    const errors = {};
    e.preventDefault();
    if(!fields.subject){
      errors['email_subject'] = 'Subject can not be blank.';
    }
    if(!fields.email_content){
      errors['email_content'] = 'Email content can not be blank.';
    }
    else{
      setSubmitted(true);
      props.broadCastEmail(fields)
    }
    setErrors(errors);
  }
  return (
    <Modal show={props.showModal} onHide={()=>props.closeModal()} centered>
      <Modal.Header closeButton><h4>Send Email</h4></Modal.Header>
        <Form onSubmit={handleSubmit} method="post">
          <Modal.Body>
            <FloatingLabel
                label="Subject"
                className="mb-3"
              >
              <Form.Control
                type="text"
                name="email_subject"
                placeholder="email_subject"
                onChange={handleChange}
                isInvalid={!!errors.email_subject}
              />
              <Form.Control.Feedback type="invalid">{errors.email_subject}</Form.Control.Feedback>
            </FloatingLabel>
            <Form.Group>
              <Form.Label>Email Content</Form.Label>
                <CKEditor
                    editor={ ClassicEditor }
                    onChange={ ( event, editor ) => {
                      handleEmailContent(editor.getData())
                    }}
                />
                {errors.email_content && (
                  <span style={{ color: "red" }}>
                    {errors.email_content}
                  </span>
                )}
              </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button color="danger" onClick={()=>props.closeModal()}>Cancel</Button>
            <Button
              variant="success"
              type="submit"
              disabled={submitted}
            >
              {submitted && (
                <Spinner
                  variant="light"
                  size="sm"
                />
              )}
              Send
            </Button>
          </Modal.Footer>
        </Form>
    </Modal>
  );
}

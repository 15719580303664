import React, { Component, useState } from "react";
import LoadingOverlay from "react-loading-overlay";
import {
  Button,
  Form,
  Spinner,
  Table,
  Row,
  Col,
  Modal, Badge, FloatingLabel
} from "react-bootstrap";
import events from "../../../services/events";
import moment from "moment";
import { toast } from "react-toastify";
import { FaFileExcel } from "react-icons/fa";
import Pagination from "react-js-pagination";
import { connect } from "react-redux";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export class ReligiousPayments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {},
      showModal: false,
      pageNo: 1,
      records: [],
      programs:[],
      pageDetails: {},
      loader: false,
      paymentId: null,
      selectedRecords:[],
      showEmailModal:false,
      showSmsModal:false
    };
  }
  handleChange = (e, field) => {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
  };
  listRecords = (e) => {
    if (e) e.preventDefault();
    this.setState({ loader: true });
    events
      .getReligiousPayments({
        event_id: this.props.eventData.id,
        page: this.state.pageNo,
        fields: this.state.fields,
        export: false,
      })
      .then((res) => {
        if (res.data.success) {
          this.setState({
            records: res.data.records,
            loader: false,
            pageDetails: res.data.pages,
          });
        } else if (res.data.error) {
          console.log(res.data.message);
        }
        this.setState({ loader: false });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getPrograms = () => {
    events
      .getPrograms({
        event_id: this.props.eventData.id
      })
      .then((res) => {
        if (res.data.success) {
          this.setState({
            programs: res.data.programs,
          });
        } else if (res.data.error) {
          console.log(res.data.message);
        }
        this.setState({ loader: false });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  deleteRecord = (id) => {
    if (window.confirm("Are you sure to delete?")) {
      let params = { id: id, event_id: this.props.eventData.id };
      events
        .deleteTransaction(params)
        .then((response) => {
          if (response.data.success) {
            let records = this.state.records.filter(
              (r) => parseInt(r.id) !== parseInt(id)
            );
            this.setState({ records }, () => {
              toast.success(response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
              });
            });
          } else {
            toast.error(response.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error("Unexpected error !", {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }
  };

  componentDidMount = () => {
    this.listRecords();
    this.getPrograms();
  };
  
  exportExcel = () => {
    events
      .exportReligiousPayments({
        event_id: this.props.eventData.id,
        page: this.state.pageNo,
        fields: this.state.fields,
        export: true,
      })
      .then((res) => {
        if (res) {
          const fileDetails = new Blob([res.data], {
            type: res.data.type,
            encoding: "UTF-8",
          });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(fileDetails);
          link.download = this.props.userName + "-" + this.props.eventData.url + "-" + moment().format("MM-DD-YYYY") + ".xlsx";
          link.click();
          link.remove();
        } else if (res.data.error) {
          toast.error(res.data.error.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(() => {
        toast.error("Unexpected error !", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  handlePageChange = (pageNo) => {
    this.setState({ pageNo }, () => {
      this.getGroupPaymentList();
    });
  };

  selectRecord = (e) => {
    let selRecords = [...this.state.selectedRecords];
    if (e.target.checked) {
      selRecords.push(e.target.value);
      selRecords = [...this.state.selectedRecords, e.target.value]
    } else {
      selRecords.splice(selRecords.indexOf(e.target.value), 1);
    }
    this.setState({selectedRecords: selRecords});
  };
  selectAll = (e) => {
    let checkboxes = document.querySelectorAll('input[name=members]');
    checkboxes.forEach(c=>{
      c.checked = e.target.checked;
    });
    let selRecords = [];
    if(e.target.checked){
      this.state.records.forEach((d,index)=>{
        selRecords[index] = d.id;
      });
    }
    this.setState({selectedRecords:selRecords});
  }
  render() {
    return (
      <>
        <Form onSubmit={this.listRecords}>
          <Row>
            <Form.Group as={Col} md={4}>
              <FloatingLabel
                label="Name"
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  name="name"
                  placeholder="name"
                  onChange={(event) => this.handleChange(event, "name")}
                />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md={4}>
              <FloatingLabel
                label="Email"
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  name="email"
                  placeholder="email"
                  onChange={(event) => this.handleChange(event, "email")}
                />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md={4}>
              <FloatingLabel
                label="Program"
                className="mb-3"
              >
                <Form.Select
                  type="select"
                  name="program_id"
                  onChange={(event) => this.handleChange(event, "program_id")}
                >
                  <option value="">Choose program</option>
                  {this.state.programs.map((program, index) => 
                      <option
                        value={program.id}
                        key={`program-${index}`}
                      >
                        {program.name}
                      </option>
                    )}
                </Form.Select>
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md={6} className="d-grid">
                <Button
                  type="submit"
                  className="btn-block"
                  variant="success"
                  disabled={this.state.submitted}
                  size="lg"
              >
                  {this.state.submitted && <Spinner size="sm" />} Search
              </Button>
            </Form.Group>  
            <Form.Group as={Col} md={6} className="d-grid">
                <Button
                  type="button"
                  variant="warning"
                  name="export"
                  disabled={this.state.submitted}
                  onClick={(e) => this.exportExcel(e)}
                  size="lg"
                  >
                  {this.state.submitted && <Spinner size="sm" />}
                  <FaFileExcel size="1em" className="mb-1 mr-1" /> Export
                </Button>
            </Form.Group>    
          </Row>
        </Form>
        <Row className="mt-3">
          <Col md={12}>
            <LoadingOverlay
              active={this.state.loader}
              spinner
              text="Loading. Please wait..."
            >
              <Table className="table-responsive">
                <thead>
                  <tr>
                    <th className="border-top-0"><input type="checkbox" onChange={this.selectAll} value={0}/></th>
                    <th className="border-top-0">#</th>
                    <th className="border-top-0">Name</th>
                    <th className="border-top-0">Email</th>
                    <th className="border-top-0">Phone</th>
                    <th className="border-top-0">Program</th>
                    <th className="border-top-0">Amount</th>
                    <th className="border-top-0">Added On</th>
                    <th className="border-top-0"></th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.records.length > 0 ? (
                  <>
                    {this.state.records.map((ele, index) => (
                      <>
                        <tr key={`records-${index}`}>
                          <td className="border-bottom-0"><input type="checkbox" onChange={this.selectRecord} value={ele.email} name="members"/></td>
                          <td className="border-bottom-0">
                            {this.state.pageNo === 1
                              ? index + 1
                              : (this.state.pageNo - 1) * 50 + index + 1}
                            .
                          </td>
                          <td className="border-bottom-0">{ele.name}</td>
                          <td className="border-bottom-0">{ele.email}</td>
                          <td className="border-bottom-0">{ele.phone !== null ? ele.phone : '--'}</td>
                          <td className="border-bottom-0">{ele.transaction.program.name}</td>
                          <td className="border-bottom-0">${ele.txn_amount}</td>
                          <td className="border-bottom-0">
                            {moment(ele.received_at).format("MMM Do, YYYY")}
                          </td>
                          <td><Button
                              type="button"
                              className="btn-sm m-1"
                              color="danger"
                              onClick={() => this.deleteRecord(ele.id)}
                            >
                              Delete
                            </Button></td>
                        </tr>
                      </>
                    ))
                    }
                    <tr>
                      <td colSpan={3} className="border-top-0">
                        <Button type="button" color="success" disabled={this.state.selectedRecords.length === 0} className="mr-2" onClick={()=>this.setState({showEmailModal:true})}><Badge color="primary" style={{width:'auto',overflow:'auto'}}>{this.state.selectedRecords.length}</Badge> Send Email</Button> 
                        <Button type="button" className="mr-2" color="warning" disabled={this.state.selectedRecords.length === 0} onClick={()=>this.setState({showSmsModal:true})}><Badge color="primary" style={{width:'auto',overflow:'auto'}}>{this.state.selectedRecords.length}</Badge> Send SMS</Button>
                      </td>
                      <td colSpan={5} className="border-top-0">
                        <div className="float-right">
                          <Pagination
                            activePage={this.state.pageNo}
                            itemsCountPerPage={50}
                            totalItemsCount={
                                this.state.pageDetails.totalCount ? parseInt(this.state.pageDetails.totalCount) : 0
                            }
                            pageRangeDisplayed={5}
                            onChange={(e) => this.handlePageChange(e)}
                            innerClass="pagination float-left"
                        />
                        </div>
                      </td>
                    </tr>
                    </>) : (
                    <tr>
                      <td
                        colSpan="11"
                        className="text-center"
                        style={{ height: 200 }}
                      >
                        Record not found.
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </LoadingOverlay>
          </Col>
        </Row>
        {this.state.showEmailModal && <SendEmailModal 
          showModal = {this.state.showEmailModal} 
          closeModal={()=>this.setState({showEmailModal:false})}
          eventId={this.props.eventData.id}
          selectedRecords={this.state.selectedRecords}
        />}
        {this.state.showSmsModal && <SendSmsModal 
          showModal = {this.state.showSmsModal} 
          closeModal={()=>this.setState({showSmsModal:false})}
          eventId={this.props.eventData.id}
          selectedRecords={this.state.selectedRecords}
        />}
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    baseUrl: state.baseUrl,
    apiUrl: state.apiUrl,
    userId: state.userId,
    userName: state.userName,
  };
};
export default connect(mapStateToProps)(ReligiousPayments);


export function SendEmailModal(props) {
  const [submitted, setSubmitted] = useState(false)
  const [fields, setFields] = useState({emails:props.selectedRecords})
  const [errors, setErrors] = useState({})
  const [email_content, setEmailContent] = useState('')

  const handleChange = (e) => {
    setFields({...fields, [e.target.name]: e.target.value})
  }

  const handleSubmit = (e) => {
    const errors = {};
    e.preventDefault();
    if(!fields.email_subject){
      errors['email_subject'] = 'Subject can not be blank.';
    }
    if(email_content === ''){
      errors['email_content'] = 'Email content can not be blank.';
    }
    else{
      setSubmitted(true);
      events.sendEmailPaymentReminder({...fields, email_content})
      .then((res) => {
        if (res.data.success) {
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          props.closeModal();
        } else if (res.data.error) {
          console.log(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    }
    setErrors(errors);
  }
  return (
    <Modal show={props.showModal} onHide={()=>props.closeModal()} centered>
      <Modal.Header closeButton><h4>Send Email</h4></Modal.Header>
        <Form onSubmit={handleSubmit} method="post">
          <Modal.Body>
            <FloatingLabel
                label="Subject"
                className="mb-3"
              >
              <Form.Control
                type="text"
                name="email_subject"
                placeholder="email_subject"
                onChange={handleChange}
                isInvalid={!!errors.email_subject}
              />
              <Form.Control.Feedback type="invalid">{errors.email_subject}</Form.Control.Feedback>
            </FloatingLabel>
            <Form.Group>
              <Form.Label>Email Content</Form.Label>
                <CKEditor
                    editor={ ClassicEditor }
                    onChange={ ( event, editor ) => {
                      setEmailContent(editor.getData())
                    }}
                />
                {errors.email_content && (
                  <span style={{ color: "red" }}>
                    {errors.email_content}
                  </span>
                )}
              </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button color="danger" onClick={()=>props.closeModal()}>Cancel</Button>
            <Button
              variant="success"
              type="submit"
              disabled={submitted}
            >
              {submitted && (
                <Spinner
                  variant="light"
                  size="sm"
                />
              )}
              Send
            </Button>
          </Modal.Footer>
        </Form>
    </Modal>
  );
}

export function SendSmsModal(props) {
  const [submitted, setSubmitted] = useState(false)
  const [fields, setFields] = useState({eventId: props.eventId, selectedRecords:props.selectedRecords})
  const [errors, setErrors] = useState({})

  const handleChange = (e) => {
    setFields({...fields, [e.target.name]: e.target.value})
  }

  const handleSubmit = (e) => {
    const errors = {};
    e.preventDefault();
    if(!fields.sms_content){
      errors['sms_content'] = 'Sms content can not be blank.';
    }
    else{
      setSubmitted(true);
      events.sendPaymentReminder(fields).then((res) => {
          if (res.data.success) {
            setSubmitted(false);
            toast.success(res.data.message, {
                position: toast.POSITION.TOP_RIGHT,
            });
            props.closeModal();  
          } else if (res.data.error) {
            toast.error(res.data.message, {
                position: toast.POSITION.TOP_RIGHT,
            });
            setSubmitted(false);
          }
      }).catch((err) => {
          toast.error(err.message, {
              position: toast.POSITION.TOP_RIGHT,
          });
          setSubmitted(false);
      });
    }
    setErrors(errors);
  }
  return (
    <Modal show={props.showModal} onHide={()=>props.closeModal()} centered>
      <Modal.Header closeButton>Send Payment Reminder</Modal.Header>
        <Form onSubmit={handleSubmit} method="post">
          <Modal.Body>
            <Form.Control
              as="textarea"
              id="sms_content"
              name="sms_content"
              rows={3}
              onChange={(event) => handleChange(event, "sms_content")}
              isInvalid={!!errors.sms_content}
            />
            <Form.Control.Feedback type="invalid">{errors.sms_content}</Form.Control.Feedback>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={()=>props.closeModal()}>Cancel</Button>
            <Button
              variant="success"
              type="submit"
              disabled={submitted}
            >
              {submitted && (
                <Spinner
                  color="light"
                  size="sm"
                />
              )}
              Send
            </Button>
          </Modal.Footer>
        </Form>
    </Modal>
  );
}

import React, { useEffect, useState } from "react";
import {
  Card,
  Row,
  Col,
  Container,
  Spinner, Alert
} from "react-bootstrap";
import { Helmet } from "react-helmet";
import events from "../../../services/events";
import { useSelector } from "react-redux";
import { FaCheckCircle } from "react-icons/fa";

const TicketDetails = (props) => {
  const [ticketDetails, setTicketDetails] = useState({});
  const [loader, setLoader] = useState(true);
  const userId = useSelector((state) => state.userId);
  const apiUrl = useSelector((state) => state.apiUrl);
  const [alertBox, setAlertBox] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);

  useEffect(() => {
    let params = {
      id: props.match.params.id,
      user_id: userId ? userId : 0
    }
    events.viewTicketDetails(params).then((res) => {
      if (res.data.success) {
        setTicketDetails(res.data.ticket);
        setAlertBox(res.data.alertBox);
        setAlertMessage(res.data.message)
      } else {
        setAlertBox(true);
        if(res.data.ticket){
          setTicketDetails(res.data.ticket);
        }
        setAlertMessage(res.data.message)
      }
      setLoader(false);
    });
  }, []);
  return (
    <>
      <Helmet>
        <title>My Ticket - Tiphub</title>
      </Helmet>
      <Container>
        <Row>
          <Col className="col-md-8 offset-md-2">
            {loader ? (
              <div style={{ textAlign: "center" }} className="mt-5">
                <Spinner />
              </div>
            ) : <>{alertBox && <Alert className="text-center"> <h4><FaCheckCircle style={{fontSize:25}}/> {alertMessage}</h4></Alert>} {ticketDetails && ticketDetails.booking && <Card>
              <Card.Body>
                <Row>
                      <Col md={4} sm={12}>
                        {ticketDetails.booking.event.image ? (
                          <img
                            className="img-fluid"
                            src={`${apiUrl}/web/events/${ticketDetails.booking.event.image}`}
                            alt="First slide"
                          />
                        ) : (
                          <img
                            className="img-fluid"
                            src={`/assets/no-image.jpeg`}
                            alt="First slide"
                          />
                        )}
                      </Col>
                      <Col md={8} sm={12}>
                        <h2>{ticketDetails.booking.event.title}</h2>
                        <h4>Location</h4>
                        <p>
                          {ticketDetails.booking.event.venue_name && (
                            <>
                              {ticketDetails.booking.event.venue_name}
                              <br />
                            </>
                          )}
                          {ticketDetails.booking.event.location}
                        </p>
                        <h4>
                          {`${ticketDetails.booking.first_name} ${ticketDetails.booking.last_name}`}
                        </h4>
                        <p>
                          <strong>Ticket Type: </strong> {ticketDetails.ticket.name}
                        </p>
                        <p>
                          <strong>Ticket</strong>
                          {` - ${ticketDetails.custom_ticket_id}`}
                          <strong className="ml-3">Price</strong> - $
                          {`${(
                            parseFloat(ticketDetails.total_price) /
                            parseInt(ticketDetails.total_tickets)
                          ).toFixed(2)}`}
                        </p>
                        <p>
                          <strong>Price</strong>
                          {` - $${ticketDetails.total_price}`}
                          <strong className="ml-3">No of Tickets</strong> -
                          {` ${parseInt(ticketDetails.total_tickets)}`}
                        </p>
                      </Col>
                </Row>
              </Card.Body>
            </Card>}
            </>
            }
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default TicketDetails;
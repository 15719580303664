import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  Table,
  Button,
  Form,
  InputGroup,
} from "react-bootstrap";
import LoadingOverlay from "react-loading-overlay";
import user from "../../../services/user";
import { toast } from "react-toastify";
import common from "../../../services/common";
import Pagination from "react-js-pagination";
import { FaCalendarAlt } from "react-icons/fa";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment";
import { connect } from "react-redux";
import Carousel from "react-elastic-carousel";

class Profile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fields: {
        date_range:
          moment().format("MM-DD-YYYY") +
          " to " +
          moment().format("MM-DD-YYYY"),
        activeIndex: 0,
      },
      loading: false,
      showReport: false,
      activeTab: "1",
      totalVisitors: 0,
      traffic: [],
      fromDevice: [],
      tippingApps: [],
      ranges: {},
      currentSelectedOption: "profile-today",
      trafficPageDetails: {},
      trafficPageNo: 1,
      nonEventPayments: 0,
      paymentsThroughApps: [],
      breakPoints: [
        { width: 1, itemsToShow: 1 },
        { width: 550, itemsToShow: 2, itemsToScroll: 2 },
        { width: 768, itemsToShow: 3 },
        { width: 1200, itemsToShow: 4 },
      ],
    };
  }
  handleChange = (field, e) => {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
  };

  handleCustomChange = (e) => {
    let date_range =
      moment().format("MM-DD-YYYY") + " to " + moment().format("MM-DD-YYYY");
    let fields = this.state.fields;
    fields["date_range"] = date_range;
    this.setState({ fields }, () => {
      this.handleSubmit();
    });
  };
  toggle = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({ tab });
    }
  };
  handleSubmit = (e = null) => {
    if (e) {
      e.preventDefault();
    }
    this.setState({ loading: true });
    user
      .getReports({ fields: this.state.fields })
      .then((res) => {
        if (res.data.success) {
          this.setState({
            totalVisitors: res.data.totalVisitors,
            traffic: res.data.traffic.records,
            trafficPageDetails: res.data.traffic.pages,
            fromDevice: res.data.fromDevice,
            tippingApps: res.data.tippingApps,
            showReport: true,
            loading: false,
            nonEventPayments:
              res.data.non_event_payments !== null
                ? res.data.non_event_payments
                : "0",
            paymentsThroughApps: res.data.payments_through_app,
          });
        } else if (res.data.error) {
          toast.error(res.data.error.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          this.setState({ loading: false });
        }
      })
      .catch(() => {
        toast.error("Unexpected error !", {
          position: toast.POSITION.TOP_RIGHT,
        });
        this.setState({ loading: false });
      });
  };
  chooseButton = (e) => {
    e.target.className = "mb-2 btn btn-primary w-100";
    if (this.state.currentSelectedOption !== null) {
      document.getElementById(this.state.currentSelectedOption).className =
        "mb-2 btn btn-outline-primary w-100";
    }
    let fields = this.state.fields;
    fields["date_range"] = e.target.dataset.value;
    this.setState({ fields, currentSelectedOption: e.target.id }, () => {
      this.handleSubmit();
    });
  };

  handleCallback = (startDate, endDate, label) => {
    let date_range =
      startDate.format("MM-DD-YYYY") + " to " + endDate.format("MM-DD-YYYY");
    let fields = this.state.fields;
    fields["date_range"] = date_range;
    this.setState({ fields }, () => {
      this.handleSubmit();
    });
  };

  componentDidMount = () => {
    this.handleSubmit();
  };

  getTraffic = () => {
    user
      .getTraffic({
        pageSize: 30,
        page: this.state.trafficPageNo,
      })
      .then((res) => {
        if (res.data.success) {
          this.setState({
            trafficPageDetails: res.data.traffic.pages,
            traffic: res.data.traffic.records,
          });
        } else if (res.data.error) {
          toast.error(res.data.error.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(() => {
        toast.error("Unexpected error !", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  handleTrafficPageChange = (pageNo) => {
    this.setState({ trafficPageNo: pageNo }, () => {
      this.getTraffic();
    });
  };

  render() {
    return (
      <>
        <Form onSubmit={this.handleSubmit} className="mt-4">
          <Row className="mb-0">
            <Col md="4" sm="12">
              <InputGroup className="mb-1">
                {/* <InputGroupAddon addonType="prepend">
                  <Button variant="outline-light">
                    <FaCalendarAlt />
                  </Button>
                </InputGroupAddon> */}
                <DateRangePicker
                  initialSettings={{
                    locale: { format: "MM-DD-YYYY", cancelLabel: "Clear" },
                    ranges: this.state.ranges,
                    autoUpdateInput: false,
                    startDate: moment().format("MM-DD-YYYY"),
                    endDate: moment().format("MM-DD-YYYY"),
                  }}
                  onCallback={this.handleCallback}
                  onCancel={(e) => {
                    this.handleCustomChange(e);
                  }}
                >
                  <input
                    className="form-control"
                    name="date_range"
                    value={
                      this.state.fields["date_range"] &&
                      this.state.fields["date_range"]
                    }
                  />
                </DateRangePicker>
              </InputGroup>
            </Col>
            <Col md="2" sm="12">
              <Button
                className="w-100"
                variant="outline-primary"
                type="button"
                onClick={(event) => this.chooseButton(event)}
                id="profile-today"
                data-value={`${moment().format(
                  "MM-DD-YYYY"
                )} to ${moment().format("MM-DD-YYYY")}`}
              >
                Today
              </Button>
            </Col>
            <Col md="2" sm="12">
              <Button
                variant="outline-primary"
                type="button"
                className="w-100"
                onClick={(event) => this.chooseButton(event)}
                id="profile-yesterday"
                data-value={`${moment()
                  .subtract(1, "days")
                  .format("MM-DD-YYYY")} to ${moment()
                  .subtract(1, "days")
                  .format("MM-DD-YYYY")}`}
              >
                Yesterday
              </Button>
            </Col>
            <Col md="2" sm="12">
              <Button
                variant="outline-primary"
                type="button"
                className="w-100"
                onClick={(event) => this.chooseButton(event)}
                id="profile-last_7_days"
                data-value={`${moment()
                  .subtract(7, "days")
                  .format("MM-DD-YYYY")} to ${moment().format("MM-DD-YYYY")}`}
              >
                Last 7 Days
              </Button>
            </Col>
            <Col md="2" sm="12">
              <Button
                variant="outline-primary"
                type="button"
                className="w-100"
                onClick={(event) => this.chooseButton(event)}
                id="profile-last_30_days"
                data-value={`${moment()
                  .subtract(29, "days")
                  .format("MM-DD-YYYY")} to ${moment().format("MM-DD-YYYY")}`}
              >
                Last 30 Days
              </Button>
            </Col>
            <Col md="2" sm="12">
              <Button
                variant="outline-primary"
                type="button"
                className="w-100"
                onClick={(event) => this.chooseButton(event)}
                id="profile-this_month"
                data-value={`${moment()
                  .startOf("month")
                  .format("MM-DD-YYYY")} to ${moment()
                  .endOf("month")
                  .format("MM-DD-YYYY")}`}
              >
                This Month
              </Button>
            </Col>
            <Col md="2" sm="12">
              <Button
                className="w-100"
                variant="outline-primary"
                type="button"
                block
                onClick={(event) => this.chooseButton(event)}
                id="profile-last_year"
                data-value={`${moment()
                  .startOf("year")
                  .subtract(1, "year")
                  .format("MM-DD-YYYY")} to ${moment()
                  .endOf("year")
                  .subtract(1, "year")
                  .format("MM-DD-YYYY")}`}
              >
                Last year
              </Button>
            </Col>
            <Col md="2" sm="12">
              <Button
                variant="outline-primary"
                type="button"
                className="w-100"
                block
                onClick={(event) => this.chooseButton(event)}
                id="profile-this_year"
                data-value={`${moment()
                  .startOf("year")
                  .format("MM-DD-YYYY")} to ${moment()
                  .endOf("year")
                  .format("MM-DD-YYYY")}`}
              >
                This Year
              </Button>
            </Col>
          </Row>
        </Form>
        {this.state.showReport && (
          <LoadingOverlay
            active={this.state.loading}
            spinner
            text="Loading..."
            className="mt-4"
          >
            <div className="reports-card">
              <Row>
                <Col lg={3} md={6}>
                  <Card body inverse variant="outline-primary">
                    <Row>
                      <Col sm={6} xs={6}>
                        <Card.Title tag="h2">
                          <b>{this.state.totalVisitors}</b>
                        </Card.Title>
                        <Card.Text>Profile Visited</Card.Text>
                      </Col>
                      <Col sm={6} xs={6} className="d-flex">
                        <div className="card-img">
                          <img src="/assets/reports/reports-card-icon1.png" />
                        </div>
                      </Col>
                    </Row>
                  </Card>
                </Col>
                {this.state.fromDevice.length > 0 &&
                  this.state.fromDevice.map((fd, index) => (
                    <Col key={index} lg={3} md={6}>
                      <Card
                        body
                        inverse
                        variant={index === 0 ? "warning" : "danger"}
                      >
                        <Row>
                          <Col sm={6} xs={6}>
                            <Card.Title tag="h2">
                              <b>{fd.totalVisitors}</b>
                            </Card.Title>
                            <Card.Text>
                              Visitors from{" "}
                              {fd.device === "Mobile"
                                ? "MOB"
                                : fd.device.toUpperCase()}
                            </Card.Text>
                          </Col>
                          <Col sm={6} xs={6} className="d-flex">
                            <div className="card-img">
                              {fd.device === "Pc" ? (
                                <img src="/assets/reports/reports-card-icon2.png" />
                              ) : (
                                <img src="/assets/reports/reports-card-icon3.png" />
                              )}
                            </div>
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                  ))}
                <Col lg={3} md={6}>
                  <Card body inverse variant="outline-success">
                    <Row>
                      <Col sm={7} xs={7}>
                        <Card.Title tag="h2">
                          <b>
                            <sup>$</sup>
                            {this.state.nonEventPayments}
                          </b>
                        </Card.Title>
                        <Card.Text>Payments received</Card.Text>
                      </Col>
                      <Col sm={5} xs={5} className="d-flex">
                        <div className="card-img">
                          <img src="/assets/reports/reports-card-icon4.png" />
                        </div>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
            </div>

            {this.state.paymentsThroughApps.length > 0 && (
              <Row className="mt-4">
                <Col sm={12}>
                  <h4 className="text-center mb-4">
                    <b>Payment through Apps</b>
                  </h4>
                </Col>
                <Col sm={12}>
                  <div className="payment-apps">
                    <ul>
                      {this.state.paymentsThroughApps.map((tp, index) => (
                        <li>
                          <Row>
                            <Col sm={4} className="d-flex">
                              <div className="card-img">
                                <img src={`/assets/${tp.service.icon}`} />
                              </div>
                            </Col>
                            <Col sm={8}>
                              <h3>
                                <b>${tp.total_amount}</b>
                              </h3>
                              <p>Received from {tp.service.name} App</p>
                            </Col>
                          </Row>
                        </li>
                      ))}
                    </ul>
                  </div>
                </Col>
              </Row>
            )}
            {this.state.tippingApps.length > 0 && (
              <Row className="mt-4">
                <Col sm={12}>
                  <h4 className="text-center mb-4">
                    <b>Tipping App Clicked</b>
                  </h4>
                </Col>
                <Col sm={12}>
                  <div className="payment-apps-slider">
                    <Carousel breakPoints={this.state.breakPoints}>
                      {this.state.tippingApps.map((tp, index) => (
                        <div className="item" key={`tpc-${index}`}>
                          <Row>
                            <Col sm={4}>
                              {tp.servicedetails !== null ? (
                                <img
                                  src={`/assets/${tp.servicedetails.icon}`}
                                />
                              ) : (
                                "--"
                              )}
                            </Col>
                            <Col sm={8}>
                              <h4>
                                <b>{tp.totalVisitors}</b>
                              </h4>
                              <p>
                                {tp.servicedetails !== null
                                  ? tp.servicedetails.name
                                  : "--"}
                              </p>
                            </Col>
                          </Row>
                        </div>
                      ))}
                    </Carousel>
                  </div>
                </Col>
              </Row>
            )}

            <Row className="mt-4">
              <Col sm={12}>
                <h4 className="text-center mb-4">
                  <b>Traffic Report</b>
                </h4>
              </Col>
              <Col sm={8} className="mx-auto">
                <Card>
                  <Card.Body>
                    <div className="table_minimise">
                      <Table
                        className="table-responsive customise_table"
                        style={{
                          minHeight: 300,
                          backgroundColor: "#ffffffb5",
                          borderRadius: "6px",
                        }}
                      >
                        <thead>
                          <tr>
                            <th scope="col">#</th>
                            <th scope="col">Date</th>
                            <th scope="col">Device</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.traffic.length > 0 ? (
                            this.state.traffic.map((t, index) => (
                              <tr key={index}>
                                <th scope="row">
                                  {(this.state.trafficPageNo - 1) * 30 +
                                    index +
                                    1}
                                </th>
                                <td>
                                  {common.customeFormat(
                                    t.added_on,
                                    "MM dd, yyyy"
                                  )}
                                </td>
                                <td>
                                  {t.device === "Pc"
                                    ? t.device.toUpperCase()
                                    : t.device}
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td className="text-center" colSpan={4}>
                                No record found.
                              </td>
                            </tr>
                          )}
                          {this.state.trafficPageDetails.totalCount > 30 && (
                            <tr>
                              <td colSpan={4} className="border-top-0">
                                <Pagination
                                  activePage={this.state.trafficPageNo}
                                  itemsCountPerPage={30}
                                  totalItemsCount={
                                    this.state.trafficPageDetails.totalCount
                                  }
                                  pageRangeDisplayed={5}
                                  onChange={(e) =>
                                    this.handleTrafficPageChange(e)
                                  }
                                />
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </LoadingOverlay>
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    baseUrl: state.baseUrl,
  };
};
export default connect(mapStateToProps)(Profile);

/* eslint-disable react/prop-types */
import { Button, Row, Col, Form } from "react-bootstrap";
import React, { Component } from "react";
import classnames from "classnames";
import { connect } from "react-redux";
import { FaQuestionCircle } from "react-icons/fa";


class NonProfitQuesion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      
    };
  }
  shouldComponentUpdate = (nextProps) => {
    if (nextProps.timeStamp !== this.props.timeStamp) {
        return true;
     } else {
        return false;
     }
  }
  render() {
    return (
        <Row>
            <Col className="mb-3" md={12}>
                <h5>
                    <b><FaQuestionCircle/></b> Who are you fundraising for?
                </h5>
                <Row>
                    <Col sm="12" md="6">
                        <div className="ticket_module">
                            <Button
                                variant="outline-primary"
                                className={classnames({
                                selected:
                                    parseInt(this.props.fields.fundraising_for) === 0,
                                })}
                                value="0"
                                onClick={(event) =>
                                this.props.handleChange(event, "fundraising_for")
                                }
                            >
                                <h5>Yourself or another person</h5>
                                These donations go to a person instead of an organization
                            </Button>
                        </div>
                    </Col>
                    <Col sm="12" md="6">
                        <div className="ticket_module">
                            <Button
                                variant="outline-primary"
                                className={classnames({
                                selected:
                                    parseInt(this.props.fields.fundraising_for) === 1,
                                })}
                                value="1"
                                onClick={(event) => this.props.handleChange(event, "fundraising_for")}
                            >
                                <h5>A nonprofit or charity</h5>
                                Donations will go to an organization that has an EIN number.
                            </Button>
                        </div>
                    </Col>
                </Row>
                {parseInt(this.props.fields.fundraising_for) === 1 && (
                    <Row className="mt-3">
                        <Col sm="12" md="6">
                            <div className="mb-3">
                                <Form.Label id="title">Non Profit Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    id="non_profit_name"
                                    name="non_profit_name"
                                    value={
                                    this.props.fields.non_profit_name !== undefined
                                        ? this.props.fields.non_profit_name
                                        : ""
                                    }
                                    isInvalid={!!this.props.errors.non_profit_name}
                                    onChange={(event) => this.props.handleChange(event, "non_profit_name")}
                                />
                                <Form.Control.Feedback type="invalid"> {this.props.errors.non_profit_name}</Form.Control.Feedback>
                            </div>
                        </Col>
                        <Col sm="12" md="6">
                            <div className="mb-3">
                                <Form.Label id="city">City</Form.Label>
                                <Form.Control
                                    type="text"
                                    id="city"
                                    name="city"
                                    value={
                                    this.props.fields.city !== undefined
                                        ? this.props.fields.city
                                        : ""
                                    }
                                    isInvalid={this.props.errors.city ? true : false}
                                    onChange={(event) => this.props.handleChange(event, "city")}
                                />
                                <Form.Control.Feedback type="invalid"> {this.props.errors.city}</Form.Control.Feedback>
                            </div>
                        </Col>
                        <Col sm="12" md="6">
                            <div className="mb-3">
                                <Form.Label id="state">State</Form.Label>
                                <Form.Control
                                    type="text"
                                    id="state"
                                    name="state"
                                    value={
                                    this.props.fields.state !== undefined
                                        ? this.props.fields.state
                                        : ""
                                    }
                                    isInvalid={this.props.errors.state ? true : false}
                                    onChange={(event) =>
                                    this.props.handleChange(event, "state")
                                    }
                                />
                                <Form.Control.Feedback type="invalid"> {this.props.errors.state}</Form.Control.Feedback>
                            </div>
                        </Col>
                        <Col sm="12" md="6">
                            <div className="mb-3">
                            <Form.Label id="ein_number">EIN Number</Form.Label>
                            <Form.Control
                                type="text"
                                id="ein_number"
                                name="ein_number"
                                value={
                                this.props.fields.ein_number !== undefined
                                    ? this.props.fields.ein_number
                                    : ""
                                }
                                isInvalid={
                                this.props.errors.ein_number ? true : false
                                }
                                onChange={(event) =>
                                this.props.handleChange(event, "ein_number")
                                }
                            />
                            <Form.Control.Feedback type="invalid"> {this.props.errors.ein_number}</Form.Control.Feedback>
                           
                            </div>
                        </Col>
                    </Row>
                )}
            </Col>
        </Row>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    apiUrl: state.apiUrl,
    baseUrl: state.baseUrl,
    userId: state.userId,
    userName: state.userName,
  };
};
export default connect(mapStateToProps)(NonProfitQuesion);

import React, { Fragment, useEffect, useState } from "react";
import { Tab, ListGroup, Row, Col, Container } from "react-bootstrap";
import { Route, Routes, Navigate } from "react-router-dom";
import BasicProfile from "./BasicProfile";
import ChangePassword from "./ChangePassword";
import LinkEmails from "./LinkEmails";
import WidgetCode from "./WidgetCode";
import Teams from "./Teams";
import Dwolla from "./Dwolla";
import DwollaAccount from "./DwollaAccount";
import StripeConnect from "./StripeConnect";
import Membership from "./Membership";
import PayPal from "./PayPal";
import DwollaPersonal from "./DwollaPersonal";


import { BsPeople } from "react-icons/bs";
import { AiOutlineCodepen } from "react-icons/ai";
import { CgProfile } from "react-icons/cg";
import { RiLockPasswordLine,RiTeamLine } from "react-icons/ri";
import { MdAlternateEmail } from "react-icons/md";
import { TbBrandPaypal } from "react-icons/tb";





const Settings = (props) => {
  const activeTab = window.location.href.split("#")[1] || "membership";
  console.log(activeTab);
  return (
    <div className="defalut-overview tiphub-banner pb-5">
      <div className="tiphub-banner-bg h-100"></div>
      <Container>
        <Tab.Container
          id="list-group-tabs-example"
          defaultActiveKey={`#${activeTab}`}
        >
          <Row>
            <Col sm={3}>
              <ListGroup className="settings-sidebar">
                <ListGroup.Item className="pt-0">
                  <h4 className="m-0">Seetings</h4>
                </ListGroup.Item>
                <ListGroup.Item action href="#membership">
                  <BsPeople />
                  Membership
                </ListGroup.Item>
                <ListGroup.Item action href="#widgetCode">
                  <AiOutlineCodepen />
                  Widget Code
                </ListGroup.Item>
                <ListGroup.Item action href="#basicProfile">
                  <CgProfile />
                  Basic Profile
                </ListGroup.Item>
                <ListGroup.Item action href="#changePassword">
                  <RiLockPasswordLine />
                  Change Password
                </ListGroup.Item>
                <ListGroup.Item action href="#linkEmails">
                  <MdAlternateEmail />
                  Link Emails
                </ListGroup.Item>
                <ListGroup.Item action href="#teams">
                  <RiTeamLine />
                  Teams
                </ListGroup.Item>
                {/* <ListGroup.Item action href="#dwollaProfile">
                  Dwolla Profile
                </ListGroup.Item>
                <ListGroup.Item action href="#dwollaAccount">
                  Dwolla Account
                </ListGroup.Item> */}
                <ListGroup.Item action href="#paypal">
                  <TbBrandPaypal />
                  PayPal
                </ListGroup.Item>
              </ListGroup>
            </Col>
            <Col sm={9}>
              <Tab.Content>
                <Tab.Pane eventKey="#membership">
                  <Membership />
                </Tab.Pane>
                <Tab.Pane eventKey="#widgetCode">
                  <WidgetCode />
                </Tab.Pane>
                <Tab.Pane eventKey="#basicProfile">
                  <BasicProfile />
                </Tab.Pane>
                <Tab.Pane eventKey="#changePassword">
                  <ChangePassword />
                </Tab.Pane>
                <Tab.Pane eventKey="#linkEmails">
                  <LinkEmails />
                </Tab.Pane>
                <Tab.Pane eventKey="#teams">
                  <Teams />
                </Tab.Pane>
                {/* <Tab.Pane eventKey="#dwollaProfile">
                  <DwollaPersonal />
                </Tab.Pane>
                <Tab.Pane eventKey="#dwollaAccount">
                  <DwollaAccount />
                </Tab.Pane> */}
                <Tab.Pane eventKey="#paypal">
                  <PayPal />
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </Container>
    </div>
  );
};

export default Settings;

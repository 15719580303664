/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import {
  Row,
  Col,
  Button,
} from 'react-bootstrap'
import ManageLinks from './ManageLinks'
import { IoClose, IoCopyOutline, IoAdd } from "react-icons/io5"
import { toast } from 'react-toastify'
import user from '../../../services/user'

function SocialLink(props) {
  const [showModal, setShowModal] = useState(false)

  const toggleModal = () => {
    setShowModal(!showModal)
  }
  const deleteLink = (id) => {
    if (window.confirm('Are you sure to delete?')) {
      let params = { id: id }
      user.deleteLink(params).then((response) => {
        if (response.data.success) {
          let socialLinks = props.socialLinks.filter((link) => parseInt(link.id) !== parseInt(id))
          props.updateSocialLinks(socialLinks)
          toast.success(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          })
        } else {
          toast.error(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          })
        }
      }).catch((err) => {
        console.log(err)
        toast.error('Unexpected error !', {
        position: toast.POSITION.TOP_RIGHT,
        })
      })
    }
  }
  return (
    <>
      <Row>
        <Col sm={12}>
          <ul className="tipping_app_icon">
            {props.socialLinks &&
              props.socialLinks.length > 0 &&
              props.socialLinks.map((ele, index) => (
                <li key={index}>
                  <a target="_blank" rel="noopener noreferrer">
                    <IoClose className="close" onClick={() => deleteLink(ele.id)}/>
                    <img src={`assets/${ele.plateform}.png`} />
                    <span>{ele.plateform === 'other' ? ele.name : ele.plateform}</span>
                    <IoCopyOutline className="copy" />
                  </a>
                </li>
              ))}
          </ul>
        </Col>
        <Col sm={12} className="text-center">
          <Button variant="primary" onClick={(e) => toggleModal(e)}>
            <IoAdd/>Add New
          </Button>
        </Col>
      </Row>
      {showModal && (
        <ManageLinks
          showModal={showModal}
          toggleModal={toggleModal}
          socialLinks={props.socialLinks}
          updateSocialLinks={props.updateSocialLinks}
        />
      )}
    </>
  )
}

export default SocialLink

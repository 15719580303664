import React, { Component } from "react";

import { connect } from "react-redux";
import { Container, Row, Col, Spinner, Button, Card } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { AiOutlineEllipsis } from "react-icons/ai"
import { toast } from "react-toastify"
import events from "../../../services/events"
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"
import {Link} from "react-router-dom"

class DiscoverEvent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      eventLoader: true,
      loadMoreLoader: false,
      showLoadMore: false,
      totalCount: 0,
      error: {},
      fields: {},
      page: 1,
      events: [],
      eventCategories: [],
      recentEvents:[]
    };
  }
  searchEvent = (e = null, form = false) => {
    if (e !== null) {
      e.preventDefault();
    }
    events
      .searchEvent({
        fields: this.state.fields,
        page: this.state.page,
      })
      .then((res) => {
        this.setState({
          events: form === true ? res.data.events : [...this.state.events, ...res.data.events],
          totalCount: res.data.pages.totalCount,
          eventLoader: false,
          showLoadMore: this.state.page < Math.ceil(res.data.pages.totalCount / 6),
          loadMoreLoader:false
        });
      });
  }
  componentDidMount = () => {
    events.getEventCategory().then((res) => {
      if (res.data.success) {
        this.setState({ eventCategories: res.data.categories }, () => {
          events
          .featuredEvent({pageSize:3})
          .then((res) => {
            if (res.data.success) {
              this.setState({ recentEvents: res.data.events })
              this.searchEvent();
            } else {
              toast.error(res.data.message, {
                position: toast.POSITION.TOP_RIGHT,
              });
            }
          })
          .catch((err) => {
            toast.error("Unexpected Error!", {
              position: toast.POSITION.TOP_RIGHT,
            });
          });
        });
      }
    });
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (prevState.page !== this.state.page) {
      this.searchEvent();
    }
  };

  handleChange = (e) => {
    let type = e.target.type;
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    this.setState({ fields }, () => {
      if (type == "select-one") {
        this.searchEvent(null, true);
      }
    });
  };

  loadMore = (e) => {
    e.preventDefault();
    let checkMoreData = Math.ceil(this.state.totalCount / 6);
    if (this.state.page < checkMoreData) {
      this.setState({ page: this.state.page + 1, loadMoreLoader: true });
    } else {
      this.setState({ showLoadMore: false });
    }
  };

  render() {
    return (
      <>
        <Helmet>
          <title>Home - Discover Event</title>
        </Helmet>
        <div className="discover_events">
          <div className="tiphub-banner">
            <div className="tiphub-banner-bg"></div>
            <Container>
              <Row>
                <Col sm={12}>
                  <div className="tiphub-banner-intro text-center" data-aos="zoom-in">
                    <h1>
                      Discover and Capture your Moments with us!
                    </h1>
                    <h5 className="mb-5">Make it as easy as possible for your supporters to send you money.</h5>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <div className="latest_events" data-aos="zoom-in">
                    <Row>
                      {this.state.recentEvents.map((re, index)=>{
                        if(index === 0){
                          return <Col md={4}>
                            <div className="blogs-box first_box" data-aos="zoom-in">
                              <Link to={`/${re.url}/${re.id}`} target="_blank">
                                <div className="blogs-box-bg">
                                  <img className="img-fluid" src={`${this.props.apiUrl}${re.image !== null ? `/web/events/${re.image}` : `/web/images/no-image.jpeg`}`} alt={re.title} />
                                  <div className="blog-box-content">
                                    <span className="ads-tag">{re.categoryDetails && `#${re.categoryDetails.name}`}</span>
                                    <h4 className="mb-2">{re.title}</h4>
                                    <a>
                                      <AiOutlineEllipsis />
                                    </a>
                                  </div>
                                </div>
                              </Link>
                            </div>
                          </Col>
                        }
                        else if(index === 1){
                          return <Col md={4}>
                            <div className="blogs-box-wrapper">
                              <h3 className="text-center">
                                <b>Latest Events</b>
                              </h3>
                              <div className="blogs-box second_box" data-aos="zoom-in">
                                <Link to={`/${re.url}/${re.id}`} target="_blank">
                                  <div className="blogs-box-bg">
                                    <img className="img-fluid w-100" src={`${this.props.apiUrl}${re.image !== null ? `/web/events/${re.image}` : `/web/images/no-image.jpeg`}`} alt={re.title} />
                                    <div className="blog-box-content">
                                      <span className="ads-tag">{re.categoryDetails && `#${re.categoryDetails.name}`}</span>
                                      <h4 className="mb-2">{re.title}</h4>
                                      <a>
                                        <AiOutlineEllipsis />
                                      </a>
                                    </div>
                                  </div>
                                </Link>
                              </div>
                            </div>
                          </Col>
                        }
                        else{
                          if(index === 2){
                            return <Col md={4}>
                            <div className="blogs-box third_box" data-aos="zoom-in">
                              <Link to={`/${re.url}/${re.id}`} target="_blank">
                                <div className="blogs-box-bg">
                                  <img className="img-fluid" src={`${this.props.apiUrl}${re.image !== null ? `/web/events/${re.image}` : `/web/images/no-image.jpeg`}`} alt={re.title} />
                                  <div className="blog-box-content">
                                    <span className="ads-tag">{re.categoryDetails && `#${re.categoryDetails.name}`}</span>
                                    <h4 className="mb-2">{re.title}</h4>
                                    <a>
                                      <AiOutlineEllipsis />
                                    </a>
                                  </div>
                                </div>
                              </Link>
                            </div>
                          </Col>
                          }
                        }
                      })}
                      
                    </Row>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          <div className="feature-events">
            <Container>
              <Row>
                <Col md={8} data-aos="fade-right">
                  <h1 className="mb-4">
                    <b>Events</b>
                  </h1>
                </Col>
                <Col md={4}>
                  <Button className="ms-auto d-block" color="primary">Search</Button>
                </Col>
              </Row>
              {this.state.eventLoader ? <Row>
                <Col md="12" sm="12" className="text-center mt-5">
                  <Spinner size="lg" style={{height:70, width:70}}/>
                </Col>
              </Row>:<Row>
                <Col>
                  <ResponsiveMasonry
                    columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}
                  >
                    <Masonry>
                      {this.state.events.map((ele, index) => <Link to={`/${ele.url}/${ele.id}`} target="_blank">
                        <Card key={`key-featured-${index}`} className="feature-slider border-0 pe-3">
                            <div className="blogs-box">
                              <div className="blogs-box-bg">
                                <img className="img-fluid" src={`${this.props.apiUrl}${ele.image !== null ? `/web/events/${ele.image}` : `/web/images/no-image.jpeg`}`} alt={ele.title} />
                                <div className="blog-box-content">
                                  <span className="ads-tag">{ele.categoryDetails && `#${ele.categoryDetails.name}`}</span>
                                  <h4 className="mb-2">{ele.title}</h4>
                                  <a>
                                    <AiOutlineEllipsis />
                                  </a>
                                </div>
                              </div>
                            </div>
                        </Card>
                      </Link>)}
                    </Masonry>
                  </ResponsiveMasonry>
                </Col>
                {this.state.showLoadMore && (
                  <Col md="12" className="mt-4 text-center">
                    <Button
                      variant="primary"
                      size="lg"
                      type="button"
                      onClick={(e) => this.loadMore(e)}
                      disabled={this.state.loadMoreLoader}
                    >
                      {this.state.loadMoreLoader && (
                        <Spinner variant="light" size="sm" className="mt-2 me-1" />
                      )}
                      Load More
                    </Button>
                  </Col>
                )}
              </Row>}
            </Container>
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    apiUrl: state.apiUrl,
  };
};

export default connect(mapStateToProps)(DiscoverEvent);

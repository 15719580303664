/* eslint-disable react/prop-types */

import {
  Modal,
  ModalBody,
  ModalHeader,
  Button,
  Spinner, ModalFooter
} from "react-bootstrap";
import React, { useState, useEffect } from "react";
import events from "../../../services/events";
import { Link } from "react-router-dom"

function AddDcCc(props){
    const [loader, setLoader] = useState(false);
    const [checkingSetting, setCheckingSetting] = useState(true);
    const [isPaypalLinked, setIsPayPalLinked] = useState(false);
   
    useEffect(() => {
      events
      .isPaypalLinked().then((res) => {
        if (res.data.success) {
            setIsPayPalLinked(true)
        } else if (res.data.error) {
            setIsPayPalLinked(false)
        }
        setCheckingSetting(false);
      })
      .catch((err) => {
        console.log(err);
      });
    }, []);
    
    
    return <Modal isOpen={props.isOpen} className="modal-dialog-centered">
      <ModalHeader color="danger" toggle={() => props.resetPaymentService()} className="bg-danger">Have you configured your PayPal Merchant account under Settings?</ModalHeader>
      {checkingSetting && <ModalBody className="text-center p-3"><Spinner style={{width:50, height:50}}/></ModalBody>}
      {!isPaypalLinked && !checkingSetting && <ModalBody className="p-3"><strong>Error:</strong> It seems you have not configured your PayPal Merchant account to receive payments. Please <Link to="/settings/paypal"><strong>Click here</strong></Link> to configure.</ModalBody>}
  
      {isPaypalLinked && <ModalFooter>
          <Button
            color="success"
            type="button"
            onClick={() => props.handleSubmit()}
          >
            {loader && (
              <Spinner
                color="light"
                component="span"
                size="sm"
              />
            )}
            Yes
          </Button>
          <Link className="btn btn-danger ml-2" to="/settings/paypal">No</Link>
        </ModalFooter>}
    </Modal>
  }
  export default AddDcCc;
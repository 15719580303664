/* eslint-disable react/prop-types */
import React, { useState, useRef, useEffect } from "react";
import { Modal, Button, Form, InputGroup, Spinner } from "react-bootstrap";
import axios from "axios";
import { useSelector } from "react-redux";

const TwoFactorValidator = (props) => {
    const [errors, setErrors] = useState({});
    const [fields, setFields] = useState({});
    const [submitted, setSubmitted] = useState(false);
    const focusInput = useRef(null);
    const apiUrl = useSelector((state) => state.apiUrl);

    const handleSubmit = (e) => {
        e.preventDefault();
        setSubmitted(true);
        axios.post(apiUrl + "/event/verify-otp", {...fields}, {
            headers: {
                'X-Api-Key': 'Bearer ' + props.token
            }
            }).then(function (res) {
            if (res.data.success) {
                if(props.dispatchLogin){
                setSubmitted(false)
                props.dispatchLogin();
                }
            } 
            else{
                let errors = {};
                errors['otp'] = res.data.message;
                setErrors(errors)
                setSubmitted(false)
            }
            }).catch(function (error) {
                setSubmitted(false)
                let errors = {};
                errors['otp'] = true;
                setErrors(errors)
            });
    }
    const handleChange = (e, field) => {
        setFields({ ...fields, [field]: e.target.value });
    };
    useEffect(() => {
        if(focusInput.current){
            focusInput.current.focus();
        }
      }, []);
      
    return (
        <div className="passwordValidator">
            <Modal
                show={props.showModal}
                onHide={props.closeModal}
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Header>Enter the OTP which we have sent to your email.</Modal.Header>
                <Modal.Body>
                    <Form noValidate onSubmit={handleSubmit}>
                        <InputGroup className="mb-3" size="lg" hasValidation>
                            <Form.Control
                                name="otp" 
                                type="text" 
                                onChange={(event) => handleChange(event, 'otp')} 
                                ref={focusInput}
                                isInvalid={!!errors.otp}
                            />
                            <Button type="submit" variant="success" disabled={submitted}>{submitted && <Spinner animation="border" variant="light" className="me-1" style={{width:20, height:20}}/>}Validate</Button>
                            <Form.Control.Feedback type="invalid">{errors.otp}</Form.Control.Feedback>
                        </InputGroup>
                    </Form>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default TwoFactorValidator;

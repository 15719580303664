import React, { useState } from "react";
import { useSelector } from "react-redux";
import {Row, Col, Button, Container} from "react-bootstrap";
import { BsPlus, BsFillArrowDownRightCircleFill, BsCheckSquareFill, BsShare, BsCurrencyDollar } from "react-icons/bs";
import {Link, Navigate} from 'react-router-dom'
import Slider from "react-slick";
import FeaturedEvents from "./FeaturedEvents";
import Blogs from "./Blogs";
import { useOutletContext } from "react-router-dom";



const Home = (props) => {
    const [showLoginModal, setShowLoginModal] = useOutletContext();

    const giversSlider = {
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      responsive: [
          {
              breakpoint: 1024,
              settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true
              }
          },
          {
              breakpoint: 767,
              settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1
              }
          }
      ]
  };
  const testimonialSlider = {
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    focusOnSelect: true,
    responsive: [
        {
            breakpoint: 576,
            settings: {
                slidesToShow: 1,
            }
        }
    ]

};
  const apiUrl = useSelector((state) => state.apiUrl);
  const userId = useSelector((state) => state.userId);
  const userName = useSelector((state) => state.userName);
  
  return (
    <div>
      {userId ? <Navigate to={`/${userName}`}/> : <><div className="tiphub-banner">
          <div className="tiphub-banner-bg"></div>
          <Container>
              <Row>
                  <Col sm={12}>
                      <div className="tiphub-banner-intro text-center" data-aos="zoom-in">
                          <h1>
                              Radical Generosity Unlocked
                          </h1>
                          <h5 className="mb-5">Make it as easy as possible for your supporters to send you money.</h5>
                          <Button color="primary" onClick={()=>setShowLoginModal(true)}>Sign up - it's free</Button>
                      </div>
                  </Col>

                  <Col sm={12}>
                      <div className="tiphub-banner-bottom text-center" data-aos="fade-up">
                          <span className="trangle"></span>
                          <img className="img-fluid" src="/assets/Group 1222.png" alt="image" />
                      </div>
                  </Col>
              </Row>
          </Container>
          <Row>
              <Col sm={12}>
                  <div className="tiphub-banner-middle">
                      <Container className="d-flex">
                          <div className="left-align">
                              <span className="animation"></span>
                              <div className="content">
                                  <h4><b>Do something life changing donate</b></h4>
                                  <hr />
                                  <p>All your payment apps in one place.</p>
                              </div>
                          </div>
                          <div className="right-align ms-auto" data-aos="flip-up">
                              <span className="circle loader"></span>
                              <ul>
                                  <li>
                                      <img src="/assets/Image 7.png" alt="image" />
                                  </li>
                                  <li>
                                      <img src="/assets/Mask Group 2.png" alt="image" />
                                  </li>
                                  <li>
                                      <h6><b>Connect More People</b></h6>
                                  </li>
                              </ul>
                          </div>
                      </Container>
                  </div>
              </Col>
          </Row>
          <div className="share">
              <Link to=''>S H A R E <BsPlus /></Link>
          </div>
      </div>
      <div className="console-your-givers">
            <Container>
                <h1 className="text-center mb-4" data-aos="zoom-in">
                    <b>Console Your Givers</b>
                </h1>
                <h5 className="text-center mb-5" data-aos="zoom-in">Their blessing doesn't go to fees</h5>
                <div className="console-slider">
                    <Slider {...giversSlider} className="feature-slider">
                        <div className="givers-box" data-aos="zoom-in">
                            <div className="givers-box-align">
                                <h4>with Zelle</h4>
                                <h2>They give $100,<br /> you get $100</h2>
                            </div>
                        </div>
                        <div className="givers-box" data-aos="zoom-in">
                            <div className="givers-box-align">
                                <h4>with every other platform</h4>
                                <h2>They give $100,<br /> you get $96.80 or less</h2>
                            </div>
                        </div>
                        <div className="givers-box" data-aos="zoom-in">
                            <div className="givers-box-align">
                                <h4>with every other platform</h4>
                                <h2>They give $100,<br /> you get $96.80 or less</h2>
                            </div>
                        </div>
                        <div className="givers-box" data-aos="zoom-in">
                            <div className="givers-box-align">
                                <h4>with every other platform</h4>
                                <h2>They give $100,<br /> you get $96.80 or less</h2>
                            </div>
                        </div>
                        <div className="givers-box" data-aos="zoom-in">
                            <div className="givers-box-align">
                                <h4>with every other platform</h4>
                                <h2>They give $100,<br /> you get $96.80 or less</h2>
                            </div>
                        </div>
                    </Slider>
                    
                </div>
                <a to='/' className="text-center mt-5 d-block">
                    How we do it?
                </a>
            </Container>
        </div>
        <div className="supports-system">
            <Container>
                <h1 className="text-center mb-4" data-aos="zoom-in">
                    <b>Supporting your raising money endeavours from begin to wrap up!</b>
                </h1>
                <h5 className="text-center mb-5" data-aos="zoom-in">Our Arrangements</h5>
                <Row>
                    <Col lg={3} md={6}>
                        <div className="supportSystemBox" data-aos="zoom-in">
                            <img className="mb-4" src="/assets/memberShipDues.png" />
                            <h5><b>Membership Dues</b></h5>
                            <p className="mb-4">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                            <a to='/'>Read More</a>
                        </div>
                    </Col>
                    <Col lg={3} md={6}>
                        <div className="supportSystemBox" data-aos="zoom-in">
                            <img className="mb-4" src="/assets/mutualAid.png" />
                            <h5><b>Mutual Aid</b></h5>
                            <p className="mb-4">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                            <a to='/'>Read More</a>
                        </div>
                    </Col>
                    <Col lg={3} md={6}>
                        <div className="supportSystemBox" data-aos="zoom-in">
                            <img className="mb-4" src="/assets/spriritualCenters.png" />
                            <h5><b>Spriritual Centers</b></h5>
                            <p className="mb-4">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                            <a to='/'>Read More</a>
                        </div>
                    </Col>
                    <Col lg={3} md={6}>
                        <div className="supportSystemBox" data-aos="zoom-in">
                            <img className="mb-4" src="/assets/ticketedEvent.png" />
                            <h5><b>Ticketed Event</b></h5>
                            <p className="mb-4">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                            <a to='/'>Read More</a>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
        <div className="how-it-works">
            <Container>
                <Row className="mb-4 mt-2">
                    <Col md={{ span: 5 }}>
                        <div className="tiphub-banner-intro" data-aos="fade-left">
                            <h5>How it Works?</h5>
                            <h1>
                                Raise funds superior, quicker, and for free
                            </h1>
                            <h5 className="mt-4 mb-5 pb-3">Publishing software like Aldus PageMaker including versions of Lorem Ipsum. Publishing software like Aldus PageMaker including versions of Lorem Ipsum.</h5>
                            <Button as={Link} color="primary" to="/discover-events" className="text-light">Get Inspired</Button>
                        </div>
                    </Col>
                    <Col md="7">
                        <aside data-aos="zoom-out">
                            <span className="rotate-down-left">
                                <BsFillArrowDownRightCircleFill />
                            </span>
                            <img className="img-fluid" src="/assets/h1-banner05.png" />
                        </aside>
                    </Col>
                </Row>
                <Container>
                    <div className="supports-system">
                        <Row>
                            <Col lg={4} md={6}>
                                <div className="supportSystemBox" data-aos="zoom-in">
                                    <img className="mb-4" src="/assets/signInIcon.png" />
                                    <h4>Sign In</h4>
                                    <h5 className="mb-4">Using gmail, twitter and facebook you can sign in easily.</h5>
                                </div>
                            </Col>
                            <Col lg={4} md={6}>
                                <div className="supportSystemBox" data-aos="zoom-in">
                                    <img className="mb-4" src="/assets/createProfile.png" />
                                    <h4>Create Profile</h4>
                                    <h5 className="mb-4">Add an image, your cashapp, venmo, paypal and send your profile out into the world.</h5>
                                </div>
                            </Col>
                            <Col lg={4} md={12}>
                                <div className="supportSystemBox" data-aos="zoom-in">
                                    <img className="mb-4" src="/assets/createAnEvent.png" />
                                    <h4>Create An Event</h4>
                                    <h5 className="mb-4">Create a fundraiser, team event or an activity that helps you raise money for a specific cause.</h5>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </Container>
        </div>
        <Blogs />
        <FeaturedEvents />
        <div className="testimonial">
            <div className="testimonial-right-align">
                <div className="bg">
                    <span className="animation">
                        <img className="img-fluid" src="/assets/Group 1224.png" alt="Image" />
                    </span>
                </div>
            </div>
            <Row>
                <Col sm={12}>
                    <div className="testimonial-top">
                        <div className="content-align">
                            <span className="rotate-down-left">
                                <BsFillArrowDownRightCircleFill />
                            </span>
                            <img className="img-fluid" src="/assets/Image-5.png" alt="Image" />
                            <div className="box" data-aos="fade-left">
                                <div className="content">
                                    <Row>
                                        <Col xs={4}>
                                            <img className="img-fluid" src="/assets/Ellipse 19.png" alt="Image" />
                                        </Col>
                                        <Col xs={6}>
                                            <div className="titleWrapper">
                                                <div className="title">
                                                    <h5><b>Michalle</b> <BsShare className="share" /></h5>
                                                    <p>2 week ago</p>
                                                    <h6><BsCurrencyDollar className="dollar" /> $ 600.00</h6>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xs={2}>
                                            <BsCheckSquareFill className="checkbox" />
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
            <Row className="testimonial-bottom-content">
                <Col md={4} data-aos="fade-right" style={{position: 'relative'}}>
                    <div className="testimonial-headline">
                        <h1 className="mb-4">
                            <b>Our customers love what we do!</b>
                        </h1>
                        <h6 className="mb-4">Testimonials</h6>
                        <h6>Aldus PageMaker including versions of Lorem Ipsum.Publishing software like Aldus PageMaker including. Publishing software like Aldus PageMaker including versions of Lorem Ipsum.Publishing software like Aldus PageMaker including. Publishing software like</h6>
                    </div>
                </Col>
                <Col md={8}>
                    <Slider {...testimonialSlider} className="testimonial-slider">
                        <div className="testimonial_box" data-aos="zoom-in">
                            <div className="content">
                                <Row>
                                    <Col xs={3}>
                                        <img className="img-fluid" src="/assets/Ellipse 19.png" />
                                    </Col>
                                    <Col xs={9}>
                                        <div className="titleWrapper">
                                            <div className="title">
                                                <h4><b>Anne Doe</b></h4>
                                                <h6>Chief Executive Offier</h6>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <h6 className="mt-3">Aldus PageMaker including versions of Lorem Ipsum.Publishing software like Aldus PageMaker including. Publishing software like Aldus PageMaker including versions of Lorem Ipsum.Publishing software like Aldus PageMaker including. Publishing software like</h6>
                            </div>
                        </div>
                        <div className="testimonial_box" data-aos="zoom-in">
                            <div className="content">
                                <Row>
                                    <Col xs={3}>
                                        <img className="img-fluid" src="/assets/Ellipse 35.png" />
                                    </Col>
                                    <Col xs={9}>
                                        <div className="titleWrapper">
                                            <div className="title">
                                                <h4><b>Anne Doe</b></h4>
                                                <h6>Chief Executive Offier</h6>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <h6 className="mt-3">Publishing software like Aldus PageMaker including versions of Lorem Ipsum.Publishing software like Aldus PageMaker including. Publishing software like Aldus PageMaker including versions of Lorem Ipsum.Publishing software like Aldus PageMaker including.</h6>
                            </div>
                        </div>
                        <div className="testimonial_box" data-aos="zoom-in">
                            <div className="content">
                                <Row>
                                    <Col xs={3}>
                                        <img className="img-fluid" src="/asssets/Ellipse 35.png" alt="Image" />
                                    </Col>
                                    <Col xs={9}>
                                        <div className="titleWrapper">
                                            <div className="title">
                                                <h4><b>Anne Doe</b></h4>
                                                <h6>Chief Executive Offier</h6>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <h6 className="mt-3">Publishing software like Aldus PageMaker including versions of Lorem Ipsum.Publishing software like Aldus PageMaker including. Publishing software like Aldus PageMaker including versions of Lorem Ipsum.Publishing software like Aldus PageMaker including.</h6>
                            </div>
                        </div>
                    </Slider>
                </Col>
            </Row>
        </div>
      </>}
      
    </div>
  );
};

export default Home;

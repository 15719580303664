import React, { Component } from "react";
import { Link } from "react-router-dom";
import user from "../../../services/user";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
import {
  Col,
  Row,
  Spinner,Button,Form
} from "react-bootstrap";
import Login from "./Login";

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {},
      errors: {},
      submitted: false,
    };
  }

  handleChange = (field, e) => {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ submitted: true });
    const params = {
      new_password: this.state.fields["new_password"]
        ? this.state.fields["new_password"]
        : "",
      confirm_password: this.state.fields["confirm_password"]
        ? this.state.fields["confirm_password"]
        : "",
      token: this.props.match.params.token ? this.props.match.params.token : "",
    };
    user.resetPassword(params).then((response) => {
      this.setState({ submitted: false }, () => {
        let fields = {};
        if (response.data.success) {
          toast.success(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          this.props.history.push("/home");
          this.setState({ fields });
        } else if (response.data.error) {
          if (typeof response.data.message === "object") {
            this.setState({ errors: response.data.message });
          } else {
            toast.error(response.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        }
      });
    });
  };
  render() {
    const { loader } = this.state;
    return (
      <React.Fragment>
        <Helmet>
          <title>Reset Password : Tiphub.co</title>
        </Helmet>
        <section className="login-enter-section tiphub-banner pb-5">
          <div className="tiphub-banner-bg h-100"></div>
          <div id="lp-register">
            <div className="container wrapper">
              <Row>
                <Col sm={5} className="mx-auto custom-label">
                  <div className="card log-reg-area shadow py-4">
                    <div className="log-reg">
                      <h4 className="login-title text-center">
                        Reset your password
                      </h4>
                      <form
                        className="px-5 login-form"
                        method="post"
                        onSubmit={this.handleSubmit}
                      >
                        <Form.Group>
                          <Form.Label for="new_password">
                            New Password
                          </Form.Label>
                          <Form.Control
                            type="password"
                            name="new_password"
                            id="new_password"
                            value={
                              this.state.fields["new_password"]
                                ? this.state.fields["new_password"]
                                : ""
                            }
                            onChange={(event) =>
                              this.handleChange("new_password", event)
                            }
                            invalid={
                              this.state.errors["new_password"] ? true : false
                            }
                          />
                          {this.state.errors["new_password"] && (
                            <Form.Control.Feedback>
                              {this.state.errors["new_password"]}
                            </Form.Control.Feedback>
                          )}
                        </Form.Group>
                        <Form.Group>
                            <Form.Label for="confirm_password">Confirm Password</Form.Label>
                            <Form.Control
                            type="password"
                            name="confirm_password"
                            id="confirm_password"
                            value={
                              this.state.fields["confirm_password"]
                                ? this.state.fields["confirm_password"]
                                : ""
                            }
                            onChange={(event) =>
                              this.handleChange("confirm_password", event)
                            }
                            invalid={
                              this.state.errors["confirm_password"]
                                ? true
                                : false
                            }
                          />
                          {this.state.errors["confirm_password"] && (
                            <Form.Control.Feedback>
                              {this.state.errors["confirm_password"]}
                            </Form.Control.Feedback>
                          )}
                        </Form.Group>                     
                        <div className="mt-3 d-flex justify-content-between">
                          <Button
                            variant="success"
                            type="submit"
                            disabled={this.state.submitted}
                          >
                            {this.state.submitted && (
                              <Spinner
                                size="sm"
                                color="#887d7d"
                                className="mr-1"
                              />)} Submit
                          </Button>
                          <span className="pt-2">Or</span>
                          <span className="pt-2">
                            Return to{" "}
                            <Link to="/">
                              <Button
                                variant="link"
                                className="p-0"
                                style={{ marginTop: "-2px" }}
                              >
                                Login
                              </Button>
                            </Link>
                          </span>
                        </div>
                      </form>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </section>
        {this.state.showModal && (
          <Login
            showModal={this.state.showModal}
            closeModal={this.toggleLoginModal}
          />
        )}
      </React.Fragment>
    );
  }
}

export default ResetPassword;

import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
    Card,    
    Col,
    Row,
    Spinner, Button
} from "react-bootstrap";
import user from "../../../services/user";
import { FaCheckCircle } from "react-icons/fa";
import { toast } from "react-toastify";

class StripeConnect extends Component {

    
    constructor(props) {
        super(props);
        this.myInterval = 0;
        this.state = {
            checkClientLoader: true,
            onBoardProcessLoader: true,
            getUrlLoader: false,
            clientNotFound: false,
            activateStripteConnect:false
        };
    }

    componentDidMount() {
        user.checkStripeConnectStatus().then((res) => {
            if(res.data.success){
                this.setState({checkClientLoader:false, clientNotFound:false});
            }
            else{
                this.setState({checkClientLoader:false, clientNotFound:true})
            }
        });
    }
    getConnectUrl = () => {
        this.setState({ getUrlLoader: true });
        user.connectStripe().then((res) => {
            if (res.data.success) {
                this.setState({ getUrlLoader: false }, () => {
                    let width = 500;
                    let height = 650;
                    let left = (window.screen.width / 2) - (width / 2);
                    let top = (window.screen.height / 2) - (height / 2);
                    let stripeConnectWindow = window.open(res.data.url, 'targetWindow', `toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=${width},height=${height}, top=${top}, left=${left}`);
                    this.myInterval = setInterval(()=>{
                        user.checkStripeConnectRequest().then((res) => {
                            if (res.data.success) {
                                if(res.data.account.payouts_enabled === true){
                                    this.setState({clientNotFound:false}, ()=>{
                                        clearInterval(this.myInterval);
                                        stripeConnectWindow.close();
                                    })
                                }
                            }
                        });
                    }, 3000)
                });
            }
            else if (res.data.error) {
                this.setState({ clientNotFound: true, getUrlLoader: false })
            }
        });
    }
    removeAccount = () => {
        if(window.confirm('Are you sure to remove this account?')){
            this.setState({checkClientLoader:true});
            user.removeStripeAccount().then((res) => {
                if(res.data.success){
                    this.setState({checkClientLoader:false, clientNotFound:true}, ()=>{
                        this.getConnectUrl();
                    });
                }
                else{
                    this.setState({checkClientLoader:false, clientNotFound:true})
                    toast.error(res.data.message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                }
            });
        }
    }
    render() {
        return (
            <>
                <Card>
                    <Card.Header>
                        <Row>
                            <Col><h5>Connect Your Stripe Account</h5></Col>
                            <Col className="text-right">

                            </Col>
                        </Row>
                    </Card.Header>
                    <Card.Body style={{ minHeight: 350 }} className="modal-body">
                        {this.state.checkClientLoader && <p className="text-center p-5"><Spinner size="lg" /></p>}
                        {this.state.clientNotFound && <p className="text-center p-5">
                            <Button type="button" color="success" onClick={this.getConnectUrl} size="lg" disabled={this.state.getUrlLoader}>
                                {this.state.getUrlLoader && (
                                    <Spinner
                                        size="sm"
                                        color="#887d7d"
                                        className="mr-1 mb-1"
                                    />
                                )} Please link your stripe account
                            </Button>
                            <br /><strong>Note: </strong> Please enable poppup blocker if it is disabled.
                        </p>}
                        {!this.state.checkClientLoader && !this.state.clientNotFound && <><div className="text-center p-5 text-success"><h3><FaCheckCircle/> Stripe connect is enabled.</h3><p><Button color="danger" type="button" onClick={this.removeAccount}>Remove this account and add new</Button></p></div></>}
                    </Card.Body>
                </Card>
            </>
        );
    }
}

export default StripeConnect;

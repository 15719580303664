/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { Modal, ModalHeader, ModalBody, Spinner, FormGroup, Label, Form,Button, ModalFooter, Input } from "react-bootstrap";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import events from "../../../services/events";

/* import ReactSummernote from 'react-summernote';
import 'react-summernote/dist/react-summernote.css'; */
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import LoadingOverlay from 'react-loading-overlay';

const PostUpdateModal = (props) => {
  const apiUrl = useSelector((state) => state.apiUrl);

  const [errors, setErrors] = useState({})
  const [fields, setFields] = useState({added_on:new Date,added_on_format:moment(new Date).format("YYYY-MM-DD h:mm:ss a")})
  const [loader, setLoader] = useState(false);
  const [description, setDescription] = useState(null)
  const [loadingOverlay, setLoadingOverlay] = useState(false)

  useEffect(() => {
    
  },[]);
  
  const handleSubmit = (e) => {
    e.preventDefault()
    if (validateForm()) {
        setLoader(true)
      fields['description'] = description;
      fields['event_id'] = props.eventId;
      let params = { fields: fields }
      events
        .postUpdate(params)
        .then((res) => {
          if (res.data.success) {
            toast.success(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            })
            props.closeModal(false);
          } else if (res.data.error) {
            toast.error(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            })
            setErrors(res.data.message)
          }
          setLoader(false)
        })
        .catch((err) => {
          console.log(err)
          toast.error('Unexpected error !', {
            position: toast.POSITION.TOP_RIGHT,
          })
        })
    }
  }

  const validateForm = () => {
    let formIsValid = true
    const errors = {}
    if (description === null) {
      errors['description'] = 'Description can not be empty'
      formIsValid = false
    }
    setErrors(errors)
    return formIsValid
  }

  const handleDescription=(description)=>{
    setDescription(description)
  }

  const handleAddedOn = (date) => {
    fields["added_on"] = date;
    fields["added_on_format"] = moment(date).format("YYYY-MM-DD h:mm:ss a");
    setFields({ ...fields })
  };

  const uploadFile = (e) => {
    setLoadingOverlay(true)
    const formData = new FormData()
    formData.append('image', e.target.files[0])
    events
      .uploadEventImage(formData)
      .then((res) => {
        if (res.data.success) {
          setFields({ ...fields, image: res.data.file })
        } else if (res.data.error) {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          })
        }
        setLoadingOverlay(false)
      })
      .catch((err) => {
        setLoadingOverlay(false);
        toast.error('Unexpected Error!', {
          position: toast.POSITION.TOP_RIGHT,
        })
      })
  }
  return (
    <Modal
      show={props.showModal}
      onHide={props.closeModal}
      backdrop="static"
      keyboard={false}
      centered
    >
      <ModalHeader
        closeButton
      >
        Post Update
      </ModalHeader>
      <LoadingOverlay active={loadingOverlay} spinner text="Uploading image. Please wait...">
          <Form onSubmit={handleSubmit} method="post">
        
          <ModalBody>
          
            <FormGroup>
                <Form.Label id="description">Event Description</Form.Label>
                {errors.description && <span style={{color:'red'}}>{errors.description}</span>}
                
            </FormGroup>
            <FormGroup>
                <Form.Label htmlFor="file">Image</Form.Label>
                  <Form.Control
                    type="file"
                    id="file"
                    name="file"
                    onChange={(event) => uploadFile(event)}
                    style={{ border: '1px solid #e4e7ea' }}
                  />
            </FormGroup>
            <FormGroup>
                <Form.Label for="added_on">Choose Date</Form.Label>
                <DatePicker
                    className="form-control"
                    selected={
                        fields["added_on"] ? new Date(fields["added_on"]) : ""
                    }
                    onChange={handleAddedOn}
                    dateFormat="MM-dd-yyyy h:mm aa"
                    showTimeSelect
                />     
            </FormGroup>
      </ModalBody>
        
          <ModalFooter>
            <Button color="success" disabled={loader} type="submit">
                {loader && (
                <Spinner
                    color="light"
                    component="div"
                    size="sm"
                    aria-hidden="true"
                    style={{ marginRight: 3 }}
                />
                )}
                Submit
            </Button>
          </ModalFooter>
        </Form>
      </LoadingOverlay>
    </Modal>
  );
};

export default PostUpdateModal;

/* eslint-disable react/prop-types */
import React, { Component } from "react";
import { Button, Alert, Card } from "react-bootstrap";
import LoadingOverlay from "react-loading-overlay";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { toast } from "react-toastify";
import events from "../../../services/events";
import moment from "moment";
import { connect } from "react-redux";
import PassedEventModal from "../Common/PassedEventModal";
import ZelleModal from "../Common/ZelleModal";
import SaveDonnerDetails from "./SaveDonnerDetails";
import { IoIosAdd } from "react-icons/io";
import SaveDonorDetailsFamily from "./SaveDonorDetailsFamily";
import DwollaPaymentForm from "./DwollaPaymentForm";
import TransferFund from "./TransferFund";
import VerifyBank from "./VerifyBank";
//import StripePaymentModel from "./StripePaymentModel";
import PaypalPaymentForm from "../Common/PaypalPaymentForm";

class ListTippingApps extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPassedEventModal: false,
      zelleEmailModal: false,
      zelleEmail: null,
      saveDonnerDetailsModal: false,
      familyDonnerModal: false,
      dwollaPaymentFormModal:false,
      dwollaCustomerUrl:null,
      dwollaFundingSource:null,
      verifyBankModal:false,
      transferDwollaFundModal:false,
      transferStripeFundModal:false,
      appDetails: {},
      appList: [],
      loader: false,
      appId:null,
      paypalPaymentFormModal:false
    };
  }

  getEventApps = () => {
    events
      .getEventApps({ event_id: this.props.eventDetails.id })
      .then((res) => {
        if (res.data.success) {
          this.setState({ appList: res.data.apps });
        }
        this.props.setAppListUpdateStatus(false);
      })
      .catch(() => {
        toast.error("Unexpected Error !", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  componentDidMount = () => {
    //this.setState({appList:this.props.eventDetails.apps})
    this.getEventApps();
  };

  componentDidUpdate = (preP, preS) => {
    if (this.props.appListUpdateStatus !== preP.appListUpdateStatus) {
      this.getEventApps();
    }
  };

  toggleZelleModel = (appDetails) => {
    if (
      moment(
        moment(this.props.eventDetails.end_date).format("YYYY-MM-DD")
      ).isBefore(moment().format("YYYY-MM-DD"))
    ) {
      this.setState({ showPassedEventModal: true });
    } else {
      let zelleEmail = null;
      if (appDetails.url !== "" && appDetails.url.search("cash.app")) {
        zelleEmail = appDetails.url.split("/")[3];
      }
      else if (appDetails.phone && appDetails.phone !== "") {
        zelleEmail = appDetails.phone;
      } else {
        zelleEmail = appDetails.email;
      }
      this.setState({appId: appDetails.service_id, zelleEmail, zelleEmailModal: true }, () => {
        events
          .trackEventApp({
            event_id: appDetails.event_id,
            service_id: appDetails.service_id,
          })
          .then((res) => {
            if (res.data.success) {
            } else if (res.data.error) {
              toast.error(res.data.message, {
                position: toast.POSITION.TOP_RIGHT,
              });
            }
          })
          .catch(() => {
            toast.error("Unexpected Error !", {
              position: toast.POSITION.TOP_RIGHT,
            });
          });
      });
    }
  };
  trackEventApp = (appDetails) => {
    if (
      moment(
        moment(this.props.eventDetails.end_date).format("YYYY-MM-DD")
      ).isBefore(moment().format("YYYY-MM-DD"))
    ) {
      this.setState({ showPassedEventModal: true });
    } else if (
      parseInt(appDetails.service_id) === 7 ||
      parseInt(appDetails.service_id) === 17 ||
      parseInt(appDetails.service_id) === 15 ||
      parseInt(appDetails.service_id) === 22 ||
      parseInt(appDetails.service_id) === 23 ||
      parseInt(appDetails.service_id) === 1 || 
      parseInt(appDetails.service_id) === 24
    ) {
      this.toggleZelleModel(appDetails);
    } else {
      events
        .trackEventApp({
          event_id: appDetails.event_id,
          service_id: appDetails.service_id,
        })
        .then((res) => {
          if (res.data.success) {
          } else if (res.data.error) {
            toast.error(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch(() => {
          toast.error("Unexpected Error !", {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
      let url = appDetails.url;
      //url = appDetails.default_amount === null ? url:url+"/"+appDetails.default_amount
      let aTag = document.createElement("a");
      aTag.setAttribute("href", url);
      aTag.setAttribute("target", "_blank");
      document.body.appendChild(aTag);
      setTimeout(function () {
        aTag.click();
        document.body.removeChild(aTag);
      }, 300);
    }
  };
  openDonnerModal = (appDetails) => {
    if (parseInt(appDetails.service_id) === 9) {
      this.trackEventApp(appDetails);
    } 
    else if (parseInt(appDetails.service_id) === 64) {
      this.setState({dwollaPaymentFormModal:true})
    }
    else if (parseInt(appDetails.service_id) === 65) {
      this.setState({transferStripeFundModal:true})
    }
    else if (parseInt(this.props.eventDetails.fundraising_for) === 1) {
      events
        .checkDonor({
          event_id: appDetails.event_id,
          service_id: appDetails.service_id,
        })
        .then((res) => {
          if (res.data.success) {
            this.setState({ appDetails }, () => {
              this.trackEventApp(appDetails);
            });
          } else if (res.data.error) {
            if (parseInt(this.props.eventDetails.category) === 18) {
              this.setState({ appDetails, familyDonnerModal: true });
            } else {
              this.setState({ appDetails, saveDonnerDetailsModal: true });
            }
          }
        })
        .catch(() => {
          toast.error("Unexpected Error !", {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    } 
    else if (
      parseInt(appDetails.service_id) === 7 ||
      parseInt(appDetails.service_id) === 17 ||
      parseInt(appDetails.service_id) === 15 ||
      parseInt(appDetails.service_id) === 22 ||
      parseInt(appDetails.service_id) === 23 ||
      parseInt(appDetails.service_id) === 24
    ) {
      this.toggleZelleModel(appDetails);
    } else if (parseInt(appDetails.service_id) === 73) {
      this.setState({paypalPaymentFormModal:true});
    }else {
      this.trackEventApp(appDetails);
    }
  };

  onDragEnd = (result) => {
    const { source, destination, draggableId } = result;
    if (!destination) return;
    if (destination.index == source.index) return;
    const t = this.state.appList.filter((ele) => ele.id == draggableId)[0];
    this.setState({ loader: true });
    const newList = [...this.state.appList];
    newList.splice(source.index, 1);
    newList.splice(destination.index, 0, t);
    // setPayList(newList);
    this.setState({ appList: newList });
    // props.updatePaymentList(newList);
    events
      .updateSequence({ apps: newList })
      .then((res) => {
        if (res.data.success) {
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else if (res.data.error) {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
        this.setState({ loader: false });
      })
      .catch((err) => {
        toast.error("Unexpected error !", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  render() {
    return (
      <Card className="mb-3">
        <Card.Header className="bg-success text-light"><h4 className="p-0 m-0 mt-1">{parseInt(this.props.eventDetails.category) === 57 ? 'Donation apps' : parseInt(this.props.eventDetails.category) === 59 ? 'DONATIONS' : 'Tipping apps'}</h4></Card.Header>
        <Card.Body>
        {this.props.userId === this.props.eventDetails.user_id ? (
          <>
            <p className="text-center text-small">
              <strong>Note: </strong>Drag & drop to change the order.
            </p>
            <LoadingOverlay
              active={this.state.loader}
              spinner
              text="Loading..."
            >
              <DragDropContext onDragEnd={this.onDragEnd}>
                <Droppable droppableId="pymentList">
                  {(provided) => (
                    <ul
                      className="tipping-apps"
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      {this.state.appList.length > 0 &&
                        this.state.appList.map((ele, index) => (
                          <Draggable
                            draggableId={ele.id}
                            index={index}
                            key={ele.id}
                          >
                            {(provided) => (
                              <li
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                ref={provided.innerRef}
                              >
                                <a
                                  onClick={() => this.openDonnerModal(ele)}
                                  style={{ cursor: "inherit" }}
                                >
                                  <div className="tipping-app-payment">
                                    <img
                                      src={`/assets/${ele.appname.icon}`}
                                      style={{ cursor: "pointer" }}
                                      width={60}
                                    />
                                  </div>
                                </a>
                              </li>
                            )}
                          </Draggable>
                        ))}
                      {provided.placeholder}
                    </ul>
                  )}
                </Droppable>
              </DragDropContext>
            </LoadingOverlay>
          </>
        ) : (
          <>
            <ul className="tipping_app_icon d-flex justify-content-center">
              {this.state.appList.length > 0 &&
                this.state.appList.map((ele, index) => {
                  if(parseInt(ele.for_tipping) === 1){
                    return <li key={index}>
                      <a
                        onClick={() => this.openDonnerModal(ele)}
                        style={{cursor:'pointer'}}
                      >
                        <div className="tipping-app-payment">
                          <img src={`/assets/${ele.appname.icon}`} width={60}/>
                        </div>
                      </a>
                    </li>
                  }
                })}
            </ul>
          </>
        )}
        </Card.Body>
        
        {this.state.showPassedEventModal && (
          <PassedEventModal
            showModal={this.state.showPassedEventModal}
            closeModal={() => {
              this.setState({ showPassedEventModal: false });
            }}
          />
        )}
        {this.state.zelleEmailModal && (
          <ZelleModal
            size="md"
            zelleEmailModal={this.state.zelleEmailModal}
            closeModal={() => {
              this.setState({ zelleEmailModal: false, appId:null });
            }}
            zelleEmail={this.state.zelleEmail}
            appId={this.state.appId}
          />
        )}
        {this.state.saveDonnerDetailsModal && (
          <SaveDonnerDetails
            showModal={this.state.saveDonnerDetailsModal}
            closeModal={() => {
              this.setState({ saveDonnerDetailsModal: false });
            }}
            appDetails={this.state.appDetails}
            trackEventApp={this.trackEventApp}
          />
        )}
        {this.state.familyDonnerModal && (
          <SaveDonorDetailsFamily
            showModal={this.state.familyDonnerModal}
            closeModal={() => {
              this.setState({ familyDonnerModal: false });
            }}
            appDetails={this.state.appDetails}
            trackEventApp={this.trackEventApp}
          />
        )}
        {this.state.dwollaPaymentFormModal && (
          <DwollaPaymentForm
            showModal={this.state.dwollaPaymentFormModal}
            closeModal={() => {
              this.setState({ dwollaPaymentFormModal: false });
            }}
            initiateVefification={(dwollaCustomerUrl)=>this.setState({verifyBankModal:true, dwollaPaymentFormModal:false, dwollaCustomerUrl})}
            
          />
        )}
        {this.state.verifyBankModal && (
          <VerifyBank
            showModal={this.state.verifyBankModal}
            closeModal={()=>this.setState({verifyBankModal:false})}
            initiateTranser={(dwollaFundingSource)=>this.setState({verifyBankModal:false, transferDwollaFundModal:true, dwollaFundingSource})}
            dwollaCustomerUrl={this.state.dwollaCustomerUrl}
            //memberId={this.state.memberId}
            eventId={this.props.eventDetails.id}
          />
        )}
        {this.state.transferDwollaFundModal && (
          <TransferFund
            showModal={this.state.transferDwollaFundModal}
            closeModal={()=>this.setState({transferDwollaFundModal:false})}
            eventId={this.props.eventDetails.id}
            dwollaFundingSource={this.state.dwollaFundingSource}
          />
        )}
        {/* {this.state.transferStripeFundModal && (
          <StripePaymentModel
            showModal={this.state.transferStripeFundModal}
            closeModal={()=>this.setState({transferStripeFundModal:false})}
            eventId={this.props.eventDetails.id}
          />
        )} */}
        {this.state.paypalPaymentFormModal && (
          <PaypalPaymentForm
            showModal={this.state.paypalPaymentFormModal}
            closeModal={()=>this.setState({paypalPaymentFormModal:false})}
            eventDetails={this.props.eventDetails}
            editAmount={true}
            capturePhone={true}
          />
        )}
      </Card>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    apiUrl: state.apiUrl,
    baseUrl: state.baseUrl,
    userId: state.userId,
    userName: state.userName,
  };
};
export default connect(mapStateToProps)(ListTippingApps);

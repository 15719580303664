import React, { Component } from "react";
import { connect } from "react-redux";
import {Row, Col, Button, Container, Spinner} from "react-bootstrap";
import { toast } from "react-toastify";
import blogs from "../../../services/blogs";
import { AiOutlineEllipsis } from "react-icons/ai"
import Slider from "react-slick";
import { Link } from "react-router-dom";

const blogSlider = {
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    focusOnSelect: true,
    responsive: [
        // {
        //     breakpoint: 767,
        //     settings: {
        //       slidesToShow: 2,
        //     }
        // },
        {
            breakpoint: 767,
            settings: {
                focusOnSelect: true,
                slidesToShow: 1,
            }
        }
    ]
  };

class Blogs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      blogs: [],
      loader: true,
    };
    
  }

  componentDidMount = () => {
    blogs
      .getFreatured({pageSize:5})
      .then((res) => {
        if (res.data.success) {
          this.setState({ loader: false, blogs: res.data.blogs });
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((err) => {
        toast.error("Unexpected Error!", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };
  render() {
    return (
        <div className="small-efforts">
            <Container>
                <h1 className="text-center mb-4" data-aos="zoom-in">
                    <b>Small efforts, make big change!</b>
                </h1>
                    <Link to="/blogs"><Button className="m-auto d-block" color="primary">See All Blogs</Button></Link>
                
                    {this.state.loader ? <Spinner /> : <Slider {...blogSlider} className="blog-slider">
                    {this.state.blogs.map((ele, index) => <div className="blogs-box" data-aos="zoom-in" key={`key-featured-${index}`}>
                        <div className="blogs-box-bg">
                            <img className="img-fluid" src={`${this.props.apiUrl}${ele.image !== null ? `/web/blogs/${ele.id}_${ele.image}` : `/web/images/no-image.jpeg`}`} alt={ele.title} />
                            <div className="blog-box-content">
                                <h1 className="mb-4">{ele.title}</h1>
                                <a>
                                    <AiOutlineEllipsis />
                                </a>
                            </div>
                        </div>
                    </div>)}
                </Slider>}
            </Container>
        </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    apiUrl: state.apiUrl,
  };
};
export default connect(mapStateToProps)(Blogs);

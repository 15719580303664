/* eslint-disable react/prop-types */

import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  Table,
  Modal,
  Col,
  Row,
  Button,
  Collapse,
  Spinner,
  Form,
  FloatingLabel,
  Card,
} from "react-bootstrap";
import events from "../../../services/events";
import service from "../../../services/service";
import LoadingOverlay from "react-loading-overlay";
import { useSelector } from "react-redux";
import { IoIosAdd } from "react-icons/io";
import { BsFillImageFill } from "react-icons/bs";
import Pagination from "react-js-pagination";
import common from "../../../services/common";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const ManageTransaction = (props) => {
  const [fields, setFields] = useState({});
  const [errors, setErrors] = useState({});
  const [openAddTran, setOpenAddTran] = useState(false);
  const [transactionList, setTransactionList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [listLoading, setListLoading] = useState(false);
  const [serviceNames, setServiceNames] = useState([]);
  const [openOthers, setOpenOthers] = useState(false);
  const [overLayLoading, setOverLayLoading] = useState(false);
  const [viewSshotModal, setViewSshotModel] = useState(false);
  const [screenshot, setScreenshot] = useState(null);
  const [pageNo, setPageNo] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [groups, setGroups] = useState([]);
  const [members, setMembers] = useState([]);

  const apiUrl = useSelector((state) => state.apiUrl);

  const viewSshot = (img) => {
    setScreenshot(img);
    setViewSshotModel(!viewSshotModal);
  };

  const handlePaymentDate = (date) => {
    setFields({...fields, ['added_on']: date});
  };

  const uploadFile = (e) => {
    setOverLayLoading(true);
    const formData = new FormData();
    formData.append("image", e.target.files[0]);
    events
      .uploadEventImage(formData)
      .then((res) => {
        if (res.data.success) {
          setFields({ ...fields, screenshot: res.data.file });
        } else if (res.data.error) {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
        setOverLayLoading(false);
      })
      .catch((err) => console.log(err));
  };

  const handleChange = (e, field) => {
    setFields({ ...fields, [field]: e.target.value });
    setFields((prevState) => ({
      ...prevState,
      event_id: props.selectedEventId,
    }));
    if (field == "service_id" && e.target.value === "other") {
      setOpenOthers(true);
    } else if (field == "service_id" && e.target.value !== "other") {
      setOpenOthers(false);
    }
  };

  const fetchTranscationList = (pageNo) => {
    setListLoading(true);
    events
      .listTransaction({ event_id: props.selectedEventId, page: pageNo })
      .then((res) => {
        if (res.data.success) {
          setTotalCount(res.data.pages.totalCount);
          setListLoading(false);
          setTransactionList(res.data.transactions);
        } else if (res.data.error) {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setListLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if(!props.transactionId){
      fetchTranscationList(pageNo);
    } 
    getPaymentServiceNames();
    getAllEventMembers();
    events.getGroupList({ event_id: props.selectedEventId }).then((res) => {
      if (res.data.success) {
        setGroups(res.data.groups);
      }
    });
  }, [pageNo]);

  const getPaymentServiceNames = () => {
    service
      .getPaymentServiceNames()
      .then((res) => {
        if (res.data.success) {
          setServiceNames(res.data.names);
          if (props.transactionId !== null) {
            getTransactionDetails(props.transactionId);
          }
        }
      })
      .catch((err) => {
        toast.error("Unexpected error !", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const getAllEventMembers = () => {
    events
      .getAllEventMembers({ event_id: props.selectedEventId })
      .then((res) => {
        if (res.data.success) {
          setMembers(res.data.members);
        }
      })
      .catch((err) => {
        toast.error("Unexpected error !", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const formValidation = () => {
    console.log(props.selectedEventId);
    let formIsValid = true;
    const errors = {};
    if (!fields.name) {
      errors["name"] = "Name can not be empty";
      formIsValid = false;
    }
    if (!fields.service_id) {
      errors["service_id"] = "Please choose any payment method";
      formIsValid = false;
    }
    /* if (!fields.currency) {
      errors['currency'] = 'Please choose any currency'
      formIsValid = false
    } */
    if (!fields.amount) {
      errors["amount"] = "Amount can not be empty";
      formIsValid = false;
    }
    if (fields.amount <= 0) {
      errors["amount"] = "Amount can never negative or zero ";
      formIsValid = false;
    }
    if (fields.service_id && fields.service_id === "other") {
      if (!fields.payment_service_name) {
        errors["payment_service_name"] = "Please enter payment service name.";
        formIsValid = false;
      }
    }
    setErrors(errors);
    return formIsValid;
  };

  const closeAndReset = () => {
    setOpenAddTran(!openAddTran);
    setFields({});
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (formValidation()) {
      setLoading(true);
      events
        .addTransaction(fields)
        .then((res) => {
          if (res.data.success) {
            toast.success(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            setFields({});
            fetchTranscationList(1);
            if (props.fetchEventDetails) {
              props.fetchEventDetails();
            }
            if (props.getSupporters) {
              props.getSupporters();
            }
            setOpenAddTran(!openAddTran);
            if (props.transactionId === null) {
              fetchTranscationList();
            }
            if (props.reLoadEvents) {
              props.reLoadEvents();
            }
            if (props.transactionId && props.transactionId !== null) {
              props.resetEventId();
            }
          } else if (res.data.error) {
            toast.error(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
          setLoading(false);
          setErrors(res.data.message);
        })
        .catch((err) => {
          toast.error("Unexpected error !", {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }
  };

  const handlePageChange = (pageNo) => {
    setPageNo(pageNo);
  };

  const deleteHandler = (id) => {
    if (window.confirm("Are you sure to delete?")) {
      events
        .deleteTransaction({ id })
        .then((res) => {
          if (res.data.success) {
            let arr = transactionList;
            arr = transactionList.filter((ele) => ele.id !== id);
            setTransactionList(arr);
            toast.success(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            fetchTranscationList(1);
            if (props.fetchEventDetails) {
              props.fetchEventDetails();
            }
            if (props.getSupporters) {
              props.getSupporters();
            }
            if (props.reLoadEvents) {
              props.reLoadEvents();
            }
          } else if (res.data.error) {
            toast.error(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error("Unexpected error !", {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }
  };

  const getTransactionDetails = (transctionId) => {
    events
      .getTransactionDetails({ id: transctionId })
      .then((res) => {
        if (res.data.success) {
          let transactionDetails = Object.assign({}, res.data.transaction);
          transactionDetails["added_on"] = new Date(transactionDetails.added_on);
          setFields(transactionDetails);
          setOpenAddTran(true);
        }
      })
      .catch((err) => {
        toast.error("Unexpected error !", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };



  return (
      <Modal show={props.openTransactionModal}
          onHide={props.resetEventId}
          backdrop="static"
          keyboard={false}
          centered
          size="lg"
      >
      <Modal.Header closeButton><h3>Manage Transactions</h3></Modal.Header>
      <Modal.Body>
        <Row className="mb-3">
          <Col sm="6">
            <h5>Supporters</h5>
          </Col>
          <Col sm="6" className="text-end">
            {props.transactionId === null && (
              <Button
                variant="primary"
                size="sm"
                onClick={() => closeAndReset()}
              >
                <IoIosAdd />
                Add New Transactions
              </Button>
            )}
          </Col>
        </Row>
        <Collapse in={openAddTran}>
          <LoadingOverlay
            active={overLayLoading}
            spinner
            text="Loading. Please wait..."
          >
            <div className="mb-3">
              <Form onSubmit={(e) => submitHandler(e)}>
                <Row>
                  <Col sm="6">
                    <FloatingLabel
                        label="Name"
                        className="mb-3"
                      >
                        <Form.Control
                          type="text"
                          name="name"
                          placeholder="name"
                          onChange={(event) => handleChange(event, "name")}
                          isInvalid={errors.name}
                          value={fields.name !== undefined ? fields.name : ""}
                        />
                        <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
                      </FloatingLabel>
                  </Col>
                  <Col sm="6">
                  <FloatingLabel controlId="floatingSelect" label="Payment Type">
                    <Form.Select onChange={(event) => handleChange(event, "service_id")} isInvalid={errors.service_id} value={fields.service_id !== undefined ? fields.service_id : ""}>
                      <option value="">Select Payment Type</option>
                      {serviceNames.map((sname, indx) => (
                          <option value={sname.id} key={`sname${indx}`}>
                            {sname.name}
                          </option>
                        ))}
                        <option value="other">Others</option>
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">{errors.service_id}</Form.Control.Feedback>
                  </FloatingLabel>
                  </Col>
                  {openOthers && (
                    <Col sm="6">
                      <FloatingLabel
                        label="Payment Service name"
                        className="mb-3"
                      >
                        <Form.Control
                          type="text"
                          name="payment_service_name"
                          placeholder="payment_service_name"
                          onChange={(event) => handleChange(event, "payment_service_name")}
                          isInvalid={errors.payment_service_name}
                          value={fields.payment_service_name !== undefined ? fields.payment_service_name : ""}
                        />
                        <Form.Control.Feedback type="invalid">{errors.username}</Form.Control.Feedback>
                      </FloatingLabel>
                    </Col>
                  )}
                  <Col md={props.enableAddedOn ? 4 : 6} sm="12">
                    <FloatingLabel
                        label="Amount"
                        className="mb-3"
                      >
                        <Form.Control
                          type="number"
                          name="amount"
                          placeholder="amount"
                          onChange={(event) => handleChange(event, "amount")}
                          isInvalid={errors.amount}
                          value={fields.amount !== undefined ? fields.amount : ""}
                        />
                        <Form.Control.Feedback type="invalid">{errors.amount}</Form.Control.Feedback>
                      </FloatingLabel>
                  </Col>
                  <Col md={props.enableAddedOn ? 4 : 6} sm="12">
                    <Form.Group controlId="formFile" className="mb-3">
                      <Form.Control 
                        type="file"
                        id="file"
                        name="file"
                        onChange={(event) => uploadFile(event)}
                      />
                    </Form.Group>
                  </Col>
                  {groups.length > 0 && <Col sm="6">
                    <FloatingLabel label="Group">
                      <Form.Select onChange={(event) => handleChange(event, "group_id")} isInvalid={errors.group_id} value={fields.group_id !== undefined ? fields.group_id : ""}>
                        <option value="">Choose a group</option>
                        {groups.map((group, index) => (
                          <option value={group.id} key={`group-${index}`}>
                            {group.name}
                          </option>
                        ))}
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">{errors.group_id}</Form.Control.Feedback>
                    </FloatingLabel>
                  </Col>}
                  {props.eventDetails && parseInt(props.eventDetails.category) === 57 && <Col sm="6">
                    <FloatingLabel label="Member">
                      <Form.Select onChange={(event) => handleChange(event, "member_id")} isInvalid={errors.member_id} value={fields.member_id !== undefined ? fields.member_id : ""}>
                        <option value="">Choose a group</option>
                        {members.map((member, index) => (
                          <option value={member.id} key={`member-${index}`}>
                            {member.first_name} {member.last_name}
                          </option>
                        ))}
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">{errors.member_id}</Form.Control.Feedback>
                    </FloatingLabel>
                  </Col>}
                  {props.enableAddedOn && <Col sm="4">
                    <div className="mb-3">
                      <DatePicker
                        className="form-control"
                        selected={
                          fields["added_on"]
                            ? fields["added_on"]
                            : null
                        }
                        onChange={handlePaymentDate}
                        dateFormat="MM-dd-yyyy"
                      />
                    </div>
                  </Col>}
                  <Col md="12">
                    <div className="mb-3">
                      <Form.Control
                        placeholder="Enter note"
                        type="textarea"
                        onChange={(event) => handleChange(event, "notes")}
                        value={fields.notes !== undefined ? fields.notes : ""}
                      />
                    </div>
                  </Col>
                </Row>
                
                {/* <Row>
                
                <Col sm="6">
                  <Input
                    onChange={(event) => handleChange(event, 'currency')}
                    value={fields.currency !== undefined ? fields.currency : ''}
                    invalid={errors.currency ? true : false}
                  >
                    <option value="">- Select Currency - </option>
                    <option value="1">US Dollar</option>
                    <option value="2">Pound</option>
                    <option value="3">Euro</option>
                  </Input>
                  <FormFeedback invalid>{errors.currency}</FormFeedback>
                </Col> 
              </Row>*/}
              <Row>
              <Col className="text-end" md={12}>
                <Button
                    className="me-0 ml-auto d-block"
                    variant="success"
                    disabled={loading}
                    type="submit"
                  >
                    {loading && (
                      <Spinner
                        variant="light"
                        size="sm"
                        className="me-1"
                      />
                    )}
                    Submit
                  </Button>
              </Col>
              </Row>
                
              </Form>
            </div>
          </LoadingOverlay>
        </Collapse>
        {props.transactionId === null && (
          <Card>
            <LoadingOverlay active={listLoading} spinner text="Loading...">
              <Card.Body>
                {transactionList && transactionList.length > 0 ? (
                  <>
                    <Table className="table table-responsive customise_table">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Name</th>
                          <th>Amount</th>
                          <th>Payment Service</th>
                          <th>Received On</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {transactionList &&
                          transactionList.length > 0 &&
                          transactionList.map((ele, index) => (
                            <tr key={index}>
                              <th>
                                {pageNo === 1
                                  ? index + 1
                                  : (pageNo - 1) * 50 + index + 1}
                              </th>
                              <td>{ele.name}</td>
                              <td>${ele.amount}</td>
                              <td> {ele.appname !== null ? ele.appname.name : '--'}</td>
                              <td>
                                {" "}
                                {common.customeFormat(
                                  ele.added_on,
                                  "MM dd, yyyy"
                                )}
                              </td>
                              <td>
                                {ele.screenshot !== null && (
                                  <Button
                                    variant="primary"
                                    className="btn-sm"
                                    onClick={() => viewSshot(ele.screenshot)}
                                    style={{ marginRight: 4 }}
                                  >
                                    <BsFillImageFill
                                      style={{ cursor: "pointer" }}
                                    />
                                  </Button>
                                )}
                                <Button
                                  variant="danger"
                                  className="btn-sm"
                                  onClick={() => deleteHandler(ele.id)}
                                >
                                  Delete
                                </Button>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                    {totalCount > 50 && (
                      <Pagination
                        activePage={pageNo}
                        itemsCountPerPage={50}
                        totalItemsCount={totalCount}
                        pageRangeDisplayed={5}
                        onChange={(e) => handlePageChange(e)}
                      />
                    )}
                  </>
                ) : (
                  <div style={{ height: "100px" }}>
                    <h5 className="text-center mt-5">No records found</h5>
                  </div>
                )}
                {viewSshotModal && (
                  <Modal show={viewSshotModal}
                      backdrop="static"
                      keyboard={false}
                      size="lg"
                  >
                    <Modal.Body>
                      <img
                        src={`${apiUrl}/web/events/${screenshot}`}
                        width="100%"
                      />
                    </Modal.Body>
                    <Modal.Footer>
                      <Button
                        variant="danger"
                        className="btn-sm"
                        onClick={() => setViewSshotModel(false)}
                      >
                        Close
                      </Button>
                    </Modal.Footer>
                  </Modal>
                )}
              </Card.Body>
            </LoadingOverlay>
          </Card>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default ManageTransaction;

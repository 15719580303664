/* eslint-disable react/prop-types */

import {
  Modal,
  Row,
  Col,
  Button,
  FormFeedback,
  Spinner,
  Form,
  Card,
  Table,Badge, Collapse, FloatingLabel, 
} from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import events from "../../../services/events";
import service from "../../../services/service";
import user from "../../../services/user";
import LoadingOverlay from "react-loading-overlay";
import { Link } from "react-router-dom"
import AddDcCc from "../Common/AddDcCc";

function TippingApp(props) {
  const userId = useSelector((state) => state.userId);
  const [loading, setLoading] = useState(false);
  const [editLoading, setEditLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [fields, setFields] = useState({recurring_cycle:3, for_tipping:1, for_booking:0, event_id: props.selectedEventId});
  const [eventAppList, setEventAppList] = useState([]);
  const [listLoading, setListLoading] = useState(false);
  const [openOthers, setOpenOthers] = useState(false);
  const [serviceNames, setServiceNames] = useState([]);
  const [openZelle, setOpenZelle] = useState(false);
  const [openGoFund, setOpenGofund] = useState(false);
  const [openApple, setOpenApple] = useState(false);
  const [leadingChar, setLeadingChar] = useState("");
  const [confirmDwolla, setConfirmDwolla] = useState(false);
  const [confirmStripe, setConfirmStripe] = useState(false);
  const [confirmCreditDebit, setConfirmCreditDebit] = useState(false);
  const [showDwollaErrorText, setShowDwollaErrorText] = useState(false);
  const [showStripeErrorText, setShowStripeErrorText] = useState(false);
  const [showRecurringCycle, setRecurringCycle] = useState(false);
  
  const [inverlArr, setIntervalArr] = useState([
    {label:'Daily', value: 1},
    {label:'Weekly', value: 2},
    {label:'Monthly', value: 3},
    {label:'Yearly', value: 4}
  ]);

  const handleChange = (e, field) => {
    if(e.target.type === 'checkbox'){
      if(e.target.checked){
        setFields({ ...fields, [field]: 1 });
      }
      else{
        setFields({ ...fields, [field]: 0 });
      }
    }
    else if (field === "recurring") {
      if(e.target.checked){
        setFields({ ...fields, ['recurring']: e.target.value });
        setRecurringCycle(true)
      }
      else{
        setFields({ ...fields, ['recurring']: 0 });
        setRecurringCycle(false);
      }
    }
    else{
      setFields({ ...fields, [field]: e.target.value });
    }
    /* setFields((prevState) => ({
      ...prevState,
      event_id: props.selectedEventId,
    })); */
    if (field === "service_id" && e.target.value === "other") {
      setOpenOthers(true);
      setOpenZelle(false);
      setOpenGofund(false);
      setOpenApple(false);
    } else if (field == "service_id" && e.target.value === "22") {
      setOpenZelle(false);
      setOpenOthers(false);
      setOpenGofund(false);
      setOpenApple(true);
    } else if (
      field === "service_id" &&
      (e.target.value === "7" ||
        e.target.value === "15" ||
        e.target.value === "17" ||
        // e.target.value === "22" ||
        e.target.value === "23" ||
        e.target.value === "24")
    ) {
      setOpenZelle(true);
      setOpenOthers(false);
      setOpenGofund(false);
      setOpenApple(false);
    } else if (field == "service_id" && e.target.value === "9") {
      setOpenZelle(false);
      setOpenOthers(false);
      setOpenGofund(true);
      setOpenApple(false);
    } else if (field === "service_id" && e.target.value !== "other") {
      setOpenOthers(false);
      setOpenZelle(false);
      setOpenGofund(false);
      setOpenApple(false);
      if (field == "service_id" && e.target.value === "1") {
        setLeadingChar("$");
      } else if (field == "service_id" && e.target.value === "2") {
        setLeadingChar("@");
      }
      else if (field == "service_id" && e.target.value === "64") {
        setConfirmDwolla(true);
        setOpenZelle(false);
        setOpenOthers(false);
        setOpenGofund(false);
        setOpenApple(false);
      }
      else if (field == "service_id" && e.target.value === "65") {
        setConfirmStripe(true);
        setOpenZelle(false);
        setOpenOthers(false);
        setOpenGofund(false);
        setOpenApple(false);
      }
      else if (field == "service_id" && e.target.value === "73") {
        setConfirmCreditDebit(true);
        setOpenZelle(false);
        setOpenOthers(false);
        setOpenGofund(false);
        setOpenApple(false);
      }
      else {
        setLeadingChar("");
      }
    }
  };

  const getPaymentServiceNames = () => {
    service
      .getPaymentServiceNames()
      .then((res) => {
        if (res.data.success) {
          setServiceNames(res.data.names);
        }
      })
      .catch((err) => {
        toast.error("Unexpected error !", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const formValidation = () => {
    const errors = {};
    let formIsValid = true;
    if (!fields.service_id) {
      errors["service_id"] = "Please choose any payment method";
      formIsValid = false;
    }
    if (fields.service_id && parseInt(fields.service_id) !== 64 && parseInt(fields.service_id) !== 65 && parseInt(fields.service_id) !== 73) {
      if (fields.username && fields.username.search("$") === 0) {
        errors["username"] = "Please enter username without $ symbol.";
        formIsValid = false;
      }
      if (fields.username && fields.username.search("@") === 0) {
        errors["username"] = "Please enter username without $ symbol.";
        formIsValid = false;
      }
      if (fields.service_id && fields.service_id === "other") {
        if (!fields.payment_service_name) {
          errors["payment_service_name"] = "Please enter payment service name.";
          formIsValid = false;
        }
        if (!fields.url) {
          errors["url"] = "Please enter url.";
          formIsValid = false;
        }
      }
      if (fields.service_id && parseInt(fields.service_id) === 9) {
        if (!fields.url) {
          errors["url"] = "Please enter url.";
          formIsValid = false;
        }
      }

      /* if (fields.service_id && parseInt(fields.service_id) === 22) {
        if (!fields.phone) {
          errors["phone"] = "Please enter phone number.";
          formIsValid = false;
        }
      } */

      if (
        fields.service_id &&
        parseInt(fields.service_id) !== 7 &&
        parseInt(fields.service_id) !== 9 &&
        parseInt(fields.service_id) !== 17 &&
        parseInt(fields.service_id) !== 15 &&
        parseInt(fields.service_id) !== 23 &&
        parseInt(fields.service_id) !== 22 &&
        parseInt(fields.service_id) !== 24 &&
        parseInt(fields.service_id) !== 13 &&
        fields.service_id !== "other" &&
        !fields.username
      ) {
        errors["username"] = "Please enter username.";
        formIsValid = false;
      }
      if (
        fields.service_id &&
        (parseInt(fields.service_id) === 7 ||
          parseInt(fields.service_id) === 17 ||
          parseInt(fields.service_id) === 15 ||
          parseInt(fields.service_id) === 23 ||
          parseInt(fields.service_id) === 24) &&
        !fields.email
      ) {
        errors["email"] = "Please enter your preferred email.";
        formIsValid = false;
      }
    }
    setErrors(errors);
    return formIsValid;
  };

  const submitHandler = (e) => {
    if (e) {
      e.preventDefault();
    }
    if (formValidation()) {
      setLoading(true);
      events
        .addEventApp(fields)
        .then((res) => {
          if (res.data.success) {
            toast.success(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            if (confirmDwolla) {
              setConfirmDwolla(false);
            }
            if (confirmStripe) {
              setConfirmStripe(false);
            }
            if (confirmCreditDebit) {
              setConfirmCreditDebit(false);
            }
            setFields({recurring_cycle:3, for_tipping: 1, for_booking:0, event_id: props.selectedEventId});
            setErrors({});
            setLoading(false);
            fetchAppList();
            if (props.setAppListUpdateStatus) {
              props.setAppListUpdateStatus(true);
            }
          } else if (res.data.error) {
            toast.error(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            setLoading(false);
            setErrors(res.data.message);
          }
        })
        .catch((err) => {
          resetForm();
          toast.error("Unexpected error !", {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }
  };

  const resetForm = () => {
    props.resetEventId();
    setFields({});
    setErrors({});
  };

  const fetchAppList = () => {
    setListLoading(true);
    events
      .listEventApps({ event_id: props.selectedEventId })
      .then((res) => {
        if (res.data.success) {
          setListLoading(false);
          setEventAppList(res.data.apps);
        } else if (res.data.error) {
          console.log(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("Unexpected error !", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const deleteApp = (id) => {
    if (window.confirm("Are you sure to delete?")) {
      let params = { id: id };
      events
        .deleteEventApp(params)
        .then((res) => {
          let arr = eventAppList;
          arr = eventAppList.filter((ele) => ele.id !== id);
          setEventAppList(arr);
          setFields({recurring_cycle:3, for_tipping: 1, for_booking:0, event_id: props.selectedEventId});
          if (props.setAppListUpdateStatus) {
            props.setAppListUpdateStatus(true);
          }
          if (res.data.success) {
            toast.success(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else if (res.data.error) {
            toast.error(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error("Unexpected error !", {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }
  };

  useEffect(() => {
    fetchAppList();
    getPaymentServiceNames();
  }, []);

  const resetPaymentService = () => {
    setFields({ ...fields, ['service_id']: '', ['recurring']: 0 });
    setConfirmDwolla(false);
    setConfirmStripe(false);
    setRecurringCycle(false);
    setConfirmCreditDebit(false);
  }
  const checkDwollaSetting = () => {
    setLoading(true);
    setShowDwollaErrorText(false);
    service
      .checkDwollaSetting()
      .then((res) => {
        if (res.data.error) {
          setShowDwollaErrorText(true);
        }
        else {
          submitHandler();
        }
      })
      .catch((err) => {
        toast.error("Unexpected error !", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  }
  const checkStripeSetting = () => {
    setLoading(true);
    setShowStripeErrorText(false);
    user
      .checkStripeConnectStatus()
      .then((res) => {
        if (res.data.error) {
          setShowStripeErrorText(true);
        }
        else {
          submitHandler();
        }
      })
      .catch((err) => {
        toast.error("Unexpected error !", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  }
  
  return (
    <Modal size="lg" show={props.openTippingModal} onHide={resetForm} backdrop="static" keyboard={false} centered>
      <Modal.Header closeButton><h4 className="mb-0">Add Tipping URLs</h4></Modal.Header>
      <Modal.Body>
        <LoadingOverlay active={editLoading} spinner text="Loading...">
          <Modal.Body>
            <Form className="login-form" method="post" onSubmit={submitHandler}>
              <Row>
                <Col md={6}>
                  <Form.Group>
                    <Form.Select
                      onChange={(event) => handleChange(event, "service_id")}
                      value={
                        fields.service_id !== undefined ? fields.service_id : ""
                      }
                      isInvalid={!!errors.service_id}
                    >
                      <option value="">- Select Payment Type - </option>
                      {serviceNames.map((sname, indx) => {
                        /* if(sname.id === '73'){
                          if(parseInt(userId) === 88 || parseInt(userId) === 4){
                            return <option value={sname.id} key={`sname${indx}`}>{sname.name}</option>
                          }
                        }
                        else{
                          return <option value={sname.id} key={`sname${indx}`}>{sname.name}</option>
                        } */
                        return <option value={sname.id} key={`sname${indx}`}>{sname.name}</option>
                      })}
                      <option value="other">Others</option>
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">{errors.service_id}</Form.Control.Feedback>
                  </Form.Group>
                </Col>
                {openOthers ? (
                  <>
                    <Col md={6}>
                      <Form.Group>
                        <Form.Control
                          placeholder="Payment Service name"
                          isInvalid={!!errors.payment_service_name}
                          onChange={(event) =>
                            handleChange(event, "payment_service_name")
                          }
                        />
                        <Form.Control.Feedback type="invalid">{errors.payment_service_name}</Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                    <FloatingLabel
                        controlId="floatingInput"
                        label="Email"
                        className="mb-3"
                      >
                        <Form.Control
                          type="text"
                          name="url"
                          placeholder="url"
                          onChange={(event) => handleChange(event, "url")}
                          isInvalid={!!errors.email}
                        />
                        <Form.Control.Feedback type="invalid">{errors.url}</Form.Control.Feedback>
                      </FloatingLabel>
                    </Col>
                  </>
                ) : openZelle ? (
                  <Col md={6}>
                      <FloatingLabel
                        controlId="floatingInput"
                        label="Your preferred email"
                        className="mb-3"
                      >
                        <Form.Control
                          type="text"
                          name="email"
                          placeholder="email"
                          onChange={(event) => handleChange(event, "email")}
                          isInvalid={!!errors.email}
                        />
                        <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                      </FloatingLabel>
                  </Col>
                ) : openGoFund ? (
                  <Col md={6}>
                    <FloatingLabel
                        controlId="floatingInput"
                        label="URL"
                        className="mb-3"
                      >
                        <Form.Control
                          type="text"
                          name="url"
                          placeholder="url"
                          onChange={(event) => handleChange(event, "url")}
                          isInvalid={!!errors.url}
                        />
                        <Form.Control.Feedback type="invalid">{errors.url}</Form.Control.Feedback>
                      </FloatingLabel>
                  </Col>
                ) : openApple ? (
                  <Col md={6}>
                    <FloatingLabel
                        controlId="floatingInput"
                        label="Your Phone Number"
                        className="mb-3"
                      >
                        <Form.Control
                          type="text"
                          name="phone"
                          placeholder="phone"
                          onChange={(event) => handleChange(event, "url")}
                          isInvalid={!!errors.phone}
                        />
                        <Form.Control.Feedback type="invalid">{errors.phone}</Form.Control.Feedback>
                      </FloatingLabel>
                  </Col>
                ) : (
                  <>
                    {parseInt(fields.service_id) !== 13 && <Col md={6}>
                      <Form.Group style={{ position: "relative" }}>
                        {leadingChar !== "" && (
                          <Form.Label
                            style={{ position: "absolute", left: 10, top: 7 }}
                          >
                            {leadingChar}
                          </Form.Label>
                        )}
                        <Form.Control
                          placeholder="Username"
                          type="text"
                          value={
                            fields.username !== undefined ? fields.username : ""
                          }
                          onChange={(event) => handleChange(event, "username")}
                          isInvalid={!!errors.username}
                          style={{ paddingLeft: 25 }}
                        />
                        <Form.Control.Feedback type="invalid">{errors.username}</Form.Control.Feedback>
                        <small>
                          <strong>Note: </strong>Do not add @ or $ before your
                          username.
                        </small>
                      </Form.Group>
                    </Col>}
                    {fields.service_id &&
                      (parseInt(fields.service_id) === 1 ||
                        parseInt(fields.service_id) === 4 ||
                        parseInt(fields.service_id) === 2) && (
                        <Col md={6}>
                          <FloatingLabel
                            controlId="floatingInput"
                            label="Default Amount"
                            className="mb-3"
                          >
                            <Form.Control
                              type="number"
                              name="default_amount"
                              placeholder="default_amount"
                              onChange={(event) => handleChange(event, "default_amount")}
                              isInvalid={!!errors.default_amount}
                              min={1}
                            />
                            <Form.Control.Feedback type="invalid">{errors.default_amount}</Form.Control.Feedback>
                          </FloatingLabel>
                        </Col>
                      )}
                  </>
                )}
                <Col md={12}>
                    <Form.Check type="checkbox" name="for_tipping" checked={parseInt(fields.for_tipping) === 1} onChange={(event) => handleChange(event, "for_tipping")} label="For Tipping" inline/> 
                    <Form.Check type="checkbox" name="for_booking" checked={parseInt(fields.for_booking) === 1} onChange={(event) => handleChange(event, "for_booking")} label="For Booking" inline/> 
                </Col>
              </Row>
              <Row>
                <Col md={12} className="text-end">
                  <Button
                    variant="danger"
                    className="me-2"
                    onClick={() => {
                      resetForm();
                    }}
                    size="sm"
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="success"
                    type="submit"
                    disabled={loading}
                    size="sm"
                  >
                    {loading && (
                      <Spinner
                        variant="light"
                        size="sm"
                      />
                    )}
                    Submit
                  </Button>
                </Col>
              </Row>
            </Form>
          </Modal.Body>
        </LoadingOverlay>
        <Card>
          <Card.Header>Tipping App List</Card.Header>
          <Card.Body>
            <LoadingOverlay active={listLoading} spinner text="Loading...">
              {eventAppList && eventAppList.length > 0 ? (
                <Table className="table-responsive customise_table">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Name</th>
                      <th>url</th>
                      <th>For Tip</th>
                      <th>For Booking</th>
                      <th width="10%">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {eventAppList &&
                      eventAppList.length > 0 &&
                      eventAppList.map((ele, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{ele.appname.name}</td>
                          <td>
                            {ele.phone ? ele.phone : ele.email ? ele.email : ele.url ? ele.url : `N/A`} {parseInt(ele.recurring) === 1 && <Badge bg="success">Recurring</Badge>}
                          </td>
                          <td>{parseInt(ele.for_tipping) === 1 ? <Badge bg="success">Yes</Badge> : <Badge bg="danger">No</Badge>}</td>
                          <td>{parseInt(ele.for_booking) === 1 ? <Badge bg="success">Yes</Badge> : <Badge bg="danger">No</Badge>}</td>
                          <td>
                            {/* <Button
                                color="primary"
                                className="btn-sm"
                                onClick={() => editApp(ele.id)}
                              >
                                Edit
                              </Button> */}
                            <Button
                              variant="danger"
                              size="sm"
                              style={{ marginLeft: 4 }}
                              onClick={() => deleteApp(ele.id)}
                            >
                              Delete
                            </Button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              ) : (
                <div style={{ height: "130px" }}>
                  <h5 className="text-center">No records found</h5>
                </div>
              )}
            </LoadingOverlay>
          </Card.Body>
        </Card>
        <Modal show={confirmDwolla} onHide={() => resetPaymentService()}>
          <Modal.Header color="danger" className="bg-danger text-light">Alert</Modal.Header>
          <Modal.Body>
            <h5>Have you configured your Dwolla account under Settings?</h5>
            {showDwollaErrorText && <p><strong>Error:</strong> It seems you have not configured your Dwolla account to receive payments. Please <Link to="/settings/dwolla-profile">Click here</Link> to configure.</p>}
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="success"
              type="button"
              onClick={() => checkDwollaSetting()}
            >
              {loading && (
                <Spinner
                  variant="light"
                  size="sm"
                />
              )}
              Yes
            </Button>
            <Link className="btn btn-danger ml-2" to="/settings/dwolla-profile">No</Link>
          </Modal.Footer>
        </Modal>
        <Modal show={confirmStripe} onHide={() => resetPaymentService()}>
          <Modal.Header color="danger" className="bg-danger">Alert</Modal.Header>
          <Modal.Body>
            <h5>Have you configured your Stripe account under Settings?</h5>
            {showStripeErrorText && <p><strong>Error:</strong> It seems you have not configured your Stripe account to receive payments. Please <Link to="/settings/stripe-connect">Click here</Link> to configure.</p>}
            <Row>
              <Col md={12}>
                <Form.Group>
                  <Form.Label>
                    <Form.Check type="checkbox" id="recurring" onChange={(event) => handleChange(event, "recurring")} value={1} checked={parseInt(fields.recurring) === 1}/> Request recurring payment?
                  </Form.Label>
                </Form.Group>
              </Col>
              <Collapse className="col-md-12 mt-2" isOpen={showRecurringCycle}>
                {inverlArr.map(intvl => <Form.Group check inline>
                  <Form.Label>
                    <Form.Check type="radio" name="recurring_cycle" onChange={(event) => handleChange(event, "recurring_cycle")} value={intvl.value} checked={parseInt(fields.recurring_cycle) === intvl.value}/> {intvl.label}
                  </Form.Label>
                </Form.Group>)}
              </Collapse>
            </Row>
          </Modal.Body>
          <Modal.Footer>
              <Button
                  variant="success"
                  type="button"
                  onClick={() => checkStripeSetting()}
                  disabled={loading}
                >
                  {loading && (
                    <Spinner
                      variant="light"
                      size="sm"
                    />
                  )}
                  Yes
                </Button>
                <Link className="btn btn-danger ml-2" to="/settings/stripe-connect">No</Link>
          </Modal.Footer>
        </Modal>
        {confirmCreditDebit && <AddDcCc 
          isOpen={confirmCreditDebit}
          closeModal={resetPaymentService}
          resetPaymentService={resetPaymentService}
          eventId={props.selectedEventId}
          handleSubmit={submitHandler}
          selectedAppId={fields.service_id}
        />}
      </Modal.Body>
    </Modal>
  );
}

export default TippingApp;
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";

import {
  Spinner,
  Button,
  Collapse,
  Form,Modal,FloatingLabel, Offcanvas
} from "react-bootstrap";
import { toast } from "react-toastify";
import { IoIosAdd } from "react-icons/io";
import { IoClose, IoCopyOutline } from "react-icons/io5"
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import service from "../../../services/service";
import ZelleModal from "../Common/ZelleModal";
import {Link} from "react-router-dom"
import AddDcCc from "../Common/AddDcCc";

function PaymentServices(props) {
  const { services } = props;
  const [fields, setFields] = useState({});
  const [errors, setErrors] = useState({});
  const [serviceNames, setServiceNames] = useState([]);
  const [payList, setPayList] = useState([]);
  const [showServiceForm, setShowServiceForm] = useState(false);
  const [serviceSubmitted, setServiceSubmitted] = useState(false);
  const [openOthers, setOpenOthers] = useState(false);
  const [openGoFund, setOpenGofund] = useState(false);
  const [openZelle, setOpenZelle] = useState(false);
  const [openApple, setOpenApple] = useState(false);
  const [zelleEmailModal, setZelleEmailModal] = useState(false);
  const [zelleEmail, setZelleEmail] = useState(null);
  const [leadingChar, setLeadingChar] = useState("");
  const [confirmDwolla, setConfirmDwolla] = useState(false);
  const [confirmStripe, setConfirmStripe] = useState(false);
  const [showDwollaErrorText, setShowDwollaErrorText] = useState(false);
  const [loading, setLoading] = useState(false);
  const [confirmCreditDebit, setConfirmCreditDebit] = useState(false);
  const layout = [
    { i: "a", x: 0, y: 0, w: 1, h: 2, static: true },
    { i: "b", x: 1, y: 0, w: 3, h: 2, minW: 2, maxW: 4 },
    { i: "c", x: 4, y: 0, w: 1, h: 2 }
  ];

  const toggleServiceFrom = () => {
    setShowServiceForm(!showServiceForm);
  };

  useEffect(() => {
    setPayList(services);
  }, [services]);

  useEffect(() => {
    getPaymentServiceNames();
  }, []);

  const getPaymentServiceNames = () => {
    service
      .getPaymentServiceNames()
      .then((res) => {
        if (res.data.success) {
          setServiceNames(res.data.names);
        }
      })
      .catch((err) => {
        toast.error("Unexpected error !", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const handleChange = (e, field) => {
    setFields({ ...fields, [field]: e.target.value });
    if (field == "service_id" && e.target.value === "other") {
      setOpenOthers(true);
      setOpenZelle(false);
      setOpenGofund(false);
      setOpenApple(false);
    } else if (field == "service_id" && e.target.value === "22") {
      setOpenZelle(false);
      setOpenOthers(false);
      setOpenGofund(false);
      setOpenApple(true);
    } else if (
      field == "service_id" &&
      (e.target.value === "7" ||
        e.target.value === "15" ||
        e.target.value === "17" ||
        e.target.value === "22" ||
        e.target.value === "23" ||
        e.target.value === "24")
    ) {
      setOpenZelle(true);
      setOpenOthers(false);
      setOpenGofund(false);
      setOpenApple(false);
    } else if (field == "service_id" && e.target.value === "9") {
      setOpenZelle(false);
      setOpenOthers(false);
      setOpenGofund(true);
      setOpenApple(false);
    } else if (field == "service_id" && e.target.value !== "other") {
      setOpenOthers(false);
      setOpenZelle(false);
      setOpenGofund(false);
      setOpenApple(false);
      if (field == "service_id" && e.target.value === "1") {
        setLeadingChar("$");
      } else if (field == "service_id" && e.target.value === "2") {
        setLeadingChar("@");
      } 
      else if (field == "service_id" && e.target.value === "65") {
        setConfirmStripe(true);
        setOpenZelle(false);
        setOpenOthers(false);
        setOpenGofund(false);
        setOpenApple(false);
      }
      else if (field == "service_id" && e.target.value === "64") {
        setConfirmDwolla(true);
        setOpenZelle(false);
        setOpenOthers(false);
        setOpenGofund(false);
        setOpenApple(false);
      }
      else if (field == "service_id" && e.target.value === "73") {
        setConfirmCreditDebit(true);
        setOpenZelle(false);
        setOpenOthers(false);
        setOpenGofund(false);
        setOpenApple(false);
      }
      else {
        setLeadingChar("");
      }
    }
  };

  const copyToClipBoard = (e, content) => {
    e.preventDefault();
    navigator.clipboard.writeText(content).then(
      () => {
        toast.success("Link copied to clipboard.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      },
      function () {
        toast.success("Sorry! Your browser does not support this feature.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    );
  };

  const validateServiceForm = () => {
    let formIsValid = true;
    let errors = {};
    if (!fields.service_id) {
      errors["service_id"] = "Please choose service type from the list.";
      formIsValid = false;
    }

    if (fields.service_id && parseInt(fields.service_id) === 22) {
      if (!fields.phone) {
        errors["phone"] = "Please enter phone number.";
        formIsValid = false;
      }
    }

    if (fields.service_id && fields.service_id === "other") {
      if (!fields.payment_service_name) {
        errors["payment_service_name"] = "Please enter payment service name.";
        formIsValid = false;
      }
      if (!fields.url) {
        errors["url"] = "Please enter url.";
        formIsValid = false;
      }
    }
    if (fields.service_id && parseInt(fields.service_id) <= 4 && parseInt(fields.service_id) !== 73) {
      if (!fields.username) {
        errors["username"] = "Please enter username.";
        formIsValid = false;
      }
    }
    if (fields.service_id && parseInt(fields.service_id) === 7) {
      if (!fields.email) {
        errors["email"] = "Please enter email address.";
        formIsValid = false;
      }
    }
    if (fields.service_id && parseInt(fields.service_id) === 9) {
      if (!fields.url) {
        errors["url"] = "Please enter url.";
        formIsValid = false;
      }
    }
    setErrors(errors);
    return formIsValid;
  };

  const submitServiceForm = (e) => {
    if(e){
      e.preventDefault();
    }
    if (validateServiceForm()) {
      setServiceSubmitted(true);
      service
        .add({ fields })
        .then((res) => {
          if (res.data.success) {
            getPaymentServiceNames();
            toast.success(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            props.updatePaymentList(res.data.services);
            toggleServiceFrom();
            setFields({});
            setErrors({});
            if(confirmDwolla){
              setConfirmDwolla(false);
            }
            if(confirmStripe){
              setConfirmStripe(false);
            }
            if (confirmCreditDebit) {
              setConfirmCreditDebit(false);
            }
          } else if (res.data.error) {
            toast.error(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            setErrors(res.data.message);
          }
          setServiceSubmitted(false);
        })
        .catch((err) => {
          setServiceSubmitted(false);
          toast.error("Unexpected error !", {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }
  };

  const deleteService = (e, id) => {
    e.preventDefault();
    let params = { id: id };
    if (window.confirm("Are you sure to delete this service?")) {
      service
        .delete(params)
        .then((response) => {
          if (response.data.success) {
            let services = props.services;
            services = props.services.filter((service) => service.id !== id);
            props.updatePaymentList(services);
            toast.success(response.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            toast.error(response.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error("Unexpected error !", {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }
  };

  const onDragEnd = (result) => {
    const { source, destination, draggableId } = result;
    if (!destination) return;
    if (destination.index == source.index) return;
    const t = payList.filter((ele) => ele.id == draggableId)[0];

    const newList = [...payList];
    newList.splice(source.index, 1);
    newList.splice(destination.index, 0, t);
    setPayList(newList);
    props.updatePaymentList(newList);
    service
      .updateSequence({ services: newList })
      .then((res) => {
        if (res.data.success) {
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else if (res.data.error) {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((err) => {
        toast.error("Unexpected error !", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const toggleZelleModel = (email) => {
    setZelleEmailModal(!zelleEmailModal);
    if (zelleEmailModal === false) {
      setZelleEmail(email);
    }
  };
  const resetPaymentService = () => {
    setFields({ ...fields, ['service_id']: '' });
    setConfirmDwolla(false);
    setConfirmStripe(false);
    setConfirmCreditDebit(false);
  }
  
  const checkDwollaSetting = () => {
    setLoading(true);
    setShowDwollaErrorText(false);
    service
      .checkDwollaSetting()
      .then((res) => {
        if (res.data.error) {
          setShowDwollaErrorText(true);
          setLoading(false);
        }
        else{
          submitServiceForm();
        }
      })
      .catch((err) => {
        toast.error("Unexpected error !", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  }
  
  return (
    <div>
      <div>
        <Modal 
          show={showServiceForm}
          onHide={()=>setShowServiceForm(false)}
          backdrop="static"
          centered
        ><Modal.Header><h4 className="mb-0">Add Payment Service</h4></Modal.Header>
          <Form onSubmit={submitServiceForm} method="post" className="p-3">
            <FloatingLabel controlId="floatingSelect" label="Select Payment App" className='mb-3'>
              <Form.Select onChange={(event) => handleChange(event, "service_id")} isInvalid={errors.service_id} value={fields.service_id ? fields.service_id : ''}>
                <option value="">Select</option>
                {serviceNames.map((sname, indx) => (
                  <option value={sname.id} key={`sname${indx}`}>
                    {sname.name}
                  </option>
                ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">{errors.service_id}</Form.Control.Feedback>
            </FloatingLabel>
              {openOthers ? (
                <Collapse isOpen={openOthers}>
                  <FloatingLabel controlId="floatingInput" label="User's profile link" className="mb-3">
                    <Form.Control
                      type="text"
                      name="url"
                      placeholder="url"
                      onChange={(event) => handleChange(event, "url")}
                      isInvalid={errors.url}
                    />
                    <Form.Control.Feedback type="invalid">{errors.url}</Form.Control.Feedback>
                  </FloatingLabel>
                </Collapse>
              ) : openZelle ? (
                <FloatingLabel controlId="floatingInput" label="Your preferred email address or phone number" className="mb-3">
                  <Form.Control
                    type="text"
                    name="email"
                    placeholder="email"
                    onChange={(event) => handleChange(event, "email")}
                    isInvalid={errors.email}
                  />
                  <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                </FloatingLabel>
              ) : openGoFund ? (
                <FloatingLabel controlId="floatingInput" label="URL" className="mb-3">
                    <Form.Control
                      type="text"
                      name="url"
                      placeholder="url"
                      onChange={(event) => handleChange(event, "url")}
                      isInvalid={errors.url}
                    />
                    <Form.Control.Feedback type="invalid">{errors.url}</Form.Control.Feedback>
                  </FloatingLabel>
              ) : openApple ? (
                <FloatingLabel controlId="floatingInput" label="Your Phone Number" className="mb-3">
                  <Form.Control
                    type="text"
                    name="phone"
                    placeholder="phone"
                    onChange={(event) => handleChange(event, "phone")}
                    isInvalid={errors.phone}
                  />
                  <Form.Control.Feedback type="invalid">{errors.phone}</Form.Control.Feedback>
                </FloatingLabel>
              ) : (
                <>
                <FloatingLabel controlId="floatingInput" label="Username" className="mb-3" style={{ position: "relative" }}>
                  <Form.Control
                    type="text"
                    name="username"
                    placeholder="username"
                    onChange={(event) => handleChange(event, "username")}
                    isInvalid={errors.username}
                  />
                  <small>
                    <strong>Note: </strong>Do not add symbols before your
                    username( example: @ or $).
                  </small>
                  <Form.Control.Feedback type="invalid">{errors.username}</Form.Control.Feedback>
                </FloatingLabel>
                  {/* {fields.service_id && (parseInt(fields.service_id) === 1 || parseInt(fields.service_id) === 4 || parseInt(fields.service_id) === 2) && <div className="mb-3">
                  <Input
                    type="number"
                    name="default_amount"
                    placeholder="Default Amount"
                    value={fields.default_amount !== undefined ? fields.default_amount : ""}
                    invalid={errors.default_amount ? true : false}
                    onChange={(event) => handleChange(event, "default_amount")}
                    min={1}
                  />
                  <FormFeedback invalid>{errors.default_amount}</FormFeedback>
                </div>} */}
                </>
              )}
              <Modal.Footer>
                <Button
                  type="button"
                  variant="danger"
                  onClick={()=>{
                    setFields({ ...fields, ['service_id']: '' });
                    setConfirmDwolla(false);
                    setShowServiceForm(false)}
                  }
                >
                Close
              </Button>
                <Button
                  disabled={serviceSubmitted}
                  type="submit"
                  variant="success"
                >
                {serviceSubmitted && (
                  <Spinner
                    variant="light"
                    size="sm"
                  />
                )}
                Save
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>    
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="pymentList" direction="horizontal">
            {(provided) => (
              <ul
                className="tipping_app_icon"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {payList &&
                  payList.length > 0 &&
                  payList.map((ele, index) => (
                    <Draggable draggableId={ele.id} index={index} key={ele.id}>
                      {(provided) => (
                        <li
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          ref={provided.innerRef}
                        >
                          {parseInt(ele.service_id) === 7 ||
                          parseInt(ele.service_id) === 15 ||
                          parseInt(ele.service_id) === 17 ||
                          parseInt(ele.service_id) === 22 ||
                          parseInt(ele.service_id) === 23 ||
                          parseInt(ele.service_id) === 24 ? (
                            <Link>
                              <IoClose className="close" onClick={(e) => deleteService(e, ele.id)}/>
                              <img src={`/assets/${ele.appname.icon}`} onClick={() => {
                                if (ele.phone){ 
                                  toggleZelleModel(ele.phone);}
                                else toggleZelleModel(ele.email);
                              }}/>
                              <span>{ele.appname.name}</span>
                              {/* <IoCopyOutline className="copy text-danger" onClick={(e) =>
                                  copyToClipBoard(
                                    e,
                                    `${ele.email ? ele.email : ele.url}`
                                  )
                                }/> */}
                            </Link>
                          ) : (
                            <a href={ele.url} target="_blank">
                              <IoClose className="close" onClick={(e) => deleteService(e, ele.id)}/>
                              <img src={`/assets/${ele.appname.icon}`} />
                              <span>{ele.appname.name}</span>
                              {/* <IoCopyOutline className="copy" onClick={(e) =>
                                  copyToClipBoard(
                                    e,
                                    `${ele.email ? ele.email : ele.url}`
                                  )
                                }/> */}
                            </a>
                          )}
                        </li>
                      )}
                    </Draggable>
                  ))}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
        </DragDropContext>
        {zelleEmailModal && (
          <ZelleModal
            zelleEmailModal={zelleEmailModal}
            setZelleEmailModal={setZelleEmailModal}
            zelleEmail={zelleEmail}
            closeModal={() => setZelleEmailModal(false)}
          />
        )}
        <Offcanvas show={confirmDwolla} onHide={()=>resetPaymentService()} placement="top" style={{zIndex:999999999999999}}>
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Confirm</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body className="text-center">
            <h4>Have you configured your Dwolla account under Settings?</h4>
            {showDwollaErrorText && <p className="text-danger"><strong>Error:</strong> It seems you have not configured your Dwolla account to receive payments. Please <Link to="/settings/dwolla-profile">Click here</Link> to configure.</p>}
            <p className="mt-2">
              <Button
                variant="success"
                type="button"
                onClick={()=>checkDwollaSetting()}
                className="me-2"
              >
                {loading && (
                  <Spinner
                    variant="light"
                    size="sm"
                  />
                )} Yes
              </Button>
              <Link className="btn btn-danger ml-2 text-light" to="/settings/dwolla-profile">No</Link>
            </p>
          </Offcanvas.Body>
        </Offcanvas>
        {confirmCreditDebit && <AddDcCc 
          isOpen={confirmCreditDebit}
          resetPaymentService={resetPaymentService}
          /*eventId={props.selectedEventId}*/
          handleSubmit={submitServiceForm} 
        />}
      </div>
      <p className="text-center">
        <Button onClick={() => toggleServiceFrom()} variant="primary" size="sm">
          <IoIosAdd />Add Payment Service
        </Button>
      </p>
    </div>
  );
}

export default PaymentServices;

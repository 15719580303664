/* eslint-disable react/prop-types */
import React, { Component } from "react";
import {
    Button, Modal, InputGroup, Col, Spinner, Row, Card, Form, FloatingLabel
} from "react-bootstrap";
import { connect } from "react-redux";
import events from "../../../services/events";
import 'react-form-builder2/dist/app.css';
import { toast } from "react-toastify";
import ZelleModal from "../Common/ZelleModal";
import PassedEventModal from "../Common/PassedEventModal";
import moment from "moment";
import PaypalPaymentForm from "../Common/PaypalPaymentForm";
import common from "../../../services/common";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

class SaleTickets extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            fields: {
                event_id: this.props.eventDetails.id,
                name: this.props.eventDetails.user.name,
                tickets: [],
                total_price: 0,
                donation_amount: 0,
                service_fee: this.props.eventDetails.service_fee
            },
            tickets: [],
            errors: {},
            submitted: false,
            zelleEmailModal: false,
            zelleEmail: null,
            ticketLoader: true,
            appId: null,
            exceptThisSymbols: ["e", "E", "+", "-", "."],
            showPassedEventModal: false,
            paypalPaymentFormModal: false,
            booking_id: null
        };
    }
    handleValidation = () => {
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;
        if (fields["tickets"] && fields["tickets"].length === 0) {
            formIsValid = false;
            errors["tickets"] = "Please choose ticket.";
        }
        if (this.props.eventDetails.apps.length > 0 && !this.checkCreditDebitPaymentOption(this.props.eventDetails.apps)) {
            if (!fields["first_name"]) {
                formIsValid = false;
                errors["first_name"] = "Please enter first name.";
            }
            if (!fields["last_name"]) {
                formIsValid = false;
                errors["last_name"] = "Please enter last name.";
            }
            if (!fields["email"]) {
                formIsValid = false;
                errors["email"] = "Please enter email.";
            }
            if (!fields["phone"]) {
                formIsValid = false;
                errors["phone"] = "Please enter phone number.";
            }
        }
        this.setState({ errors: errors });
        return formIsValid;
    };
    handleSubmit = app => {
        if (this.handleValidation()) {
            //alert();
            this.setState({ submitted: true });
            let fields = this.state.fields;
            fields['service_id'] = app.service_id;
            let params = { fields: fields };/* 
            console.log(params);
            this.setState({ submitted: false});
            return false; */
            events.saveSoldTicket(params).then((res) => {
                if (res.data.success) {
                    this.setState({ submitted: false, showModal: false, booking_id: res.data.booking_id }, () => {
                        this.openDonnerModal(app);
                    });
                } else if (res.data.error) {
                    toast.error(res.data.message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                    this.setState({ errors: res.data.message, submitted: false });
                }
            }).catch(() => {
                toast.error("Unexpected Error !", {
                    position: toast.POSITION.TOP_RIGHT,
                });
            });
        }
    };
    handleChange(e, field) {
        let fields = this.state.fields;
        if (e.target.type === 'radio') {
            fields['oneTicketPrice'] = this.props.eventDetails.tickets.filter(t => parseInt(t.id) === parseInt(e.target.value))[0].price;
        }
        else if(e.target.type === 'checkbox'){
            if(e.target.checked){
                fields['term_accepted'] = 1;
            }
            else{
                fields['term_accepted'] = 0;
            }
        }
        else{
            fields[field] = e.target.value;
        }
        this.setState({ fields }, () => {
            this.calculateTotal();
        });
    }
    calculateTotal = () => {
        let fields = this.state.fields;
        if (fields.tickets.length > 0) {
            fields.total_price = fields.tickets.reduce(function (previousValue, currentValue) {
                if(currentValue.custom_price !== undefined && parseInt(currentValue.custom_price) > 0){
                    return previousValue + parseInt(currentValue.custom_price);
                }
                else{
                    return previousValue + currentValue.total_price;
                }
            }, 0);
        }
        else {
            fields.total_price = 0;
        }
        
        const donation_amount = fields.donation_amount !== "" ? fields.donation_amount : 0;
        /* let service_fee = fields.service_fee ? fields.service_fee : 0;
        if (service_fee) {
            service_fee = fields.total_price * parseInt(service_fee) / 100;
        } */
        let service_fee = 0;
        fields.total_price = fields.total_price + parseInt(donation_amount) + service_fee;

        this.setState({ fields },()=>{
            console.log(this.state.fields.tickets);
        });
    }
    trackEventApp = (appDetails) => {
        if (
            parseInt(appDetails.service_id) === 7 ||
            parseInt(appDetails.service_id) === 17 ||
            parseInt(appDetails.service_id) === 15 ||
            parseInt(appDetails.service_id) === 22 ||
            parseInt(appDetails.service_id) === 23 ||
            parseInt(appDetails.service_id) === 1 ||
            parseInt(appDetails.service_id) === 24
        ) {
            this.toggleZelleModel(appDetails);
        } else {
            events
                .trackEventApp({
                    event_id: appDetails.event_id,
                    service_id: appDetails.service_id,
                })
                .then((res) => {
                    if (res.data.success) {
                    } else if (res.data.error) {
                        toast.error(res.data.message, {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                    }
                })
                .catch(() => {
                    toast.error("Unexpected Error !", {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                });
            let url = appDetails.url;
            //url = appDetails.default_amount === null ? url:url+"/"+appDetails.default_amount
            let aTag = document.createElement("a");
            aTag.setAttribute("href", url);
            //aTag.setAttribute("target", "_blank");
            document.body.appendChild(aTag);
            setTimeout(function () {
                aTag.click();
                document.body.removeChild(aTag);
            }, 500);
        }
    };
    openDonnerModal = (appDetails) => {
        if (parseInt(appDetails.service_id) === 9) {
            this.trackEventApp(appDetails);
        } else if (parseInt(this.props.eventDetails.fundraising_for) === 1) {
            this.trackEventApp(appDetails);
        } else if (
            parseInt(appDetails.service_id) === 7 ||
            parseInt(appDetails.service_id) === 17 ||
            parseInt(appDetails.service_id) === 15 ||
            parseInt(appDetails.service_id) === 22 ||
            parseInt(appDetails.service_id) === 23 ||
            parseInt(appDetails.service_id) === 1 ||
            parseInt(appDetails.service_id) === 24
        ) {
            this.toggleZelleModel(appDetails);
        }
        else if (parseInt(appDetails.service_id) === 73) {
            this.setState({ paypalPaymentFormModal: true });
        }
        else {
            this.trackEventApp(appDetails);
        }
    };
    toggleZelleModel = (appDetails) => {
        let zelleEmail = null;
        if (appDetails.url !== "" && appDetails.url.search("cash.app")) {
            zelleEmail = appDetails.url.split("/")[3];
        }
        else if (appDetails.phone && appDetails.phone !== "") {
            zelleEmail = appDetails.phone;
        } else {
            zelleEmail = appDetails.email;
        }
        this.setState({ zelleEmail, zelleEmailModal: true, appId: appDetails.service_id }, () => {
            events
                .trackEventApp({
                    event_id: appDetails.event_id,
                    service_id: appDetails.service_id,
                })
                .then((res) => {
                    if (res.data.success) {
                    } else if (res.data.error) {
                        toast.error(res.data.message, {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                    }
                })
                .catch(() => {
                    toast.error("Unexpected Error !", {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                });
        });
    };
    updateTicketNumber(e, id, action) {
        let currentTicketValue = parseInt(this.getTicketValue(id, 'total_tickets'));
        if (action) {
            currentTicketValue++;
        }
        else if (currentTicketValue > 0) {
            currentTicketValue--;
        }
        
        let target = e.target;
        let fields = this.state.fields;
        if (fields.tickets && fields.tickets.length > 0) {
            const index_to_be_modified = fields.tickets.findIndex(t => {
                return parseInt(t.ticket_id) === parseInt(id);
            });
            if (index_to_be_modified < 0) {
                const ticket = {
                    ticket_id: id,
                    total_tickets: currentTicketValue,
                    ticket_price: target.dataset.price,
                    total_price: target.dataset.price * currentTicketValue,
                    description: target.dataset.description,
                }
                fields.tickets.push(ticket);
            }
            else {
                fields.tickets[index_to_be_modified].total_tickets = currentTicketValue;
                fields.tickets[index_to_be_modified].total_price = currentTicketValue * fields.tickets[index_to_be_modified].ticket_price;
            }
        }
        else {
            const ticket = {
                ticket_id: id,
                total_tickets: currentTicketValue,
                ticket_price: target.dataset.price,
                total_price: target.dataset.price * currentTicketValue,
                description: target.dataset.description,
            }
            fields.tickets.push(ticket);
        }
        if(currentTicketValue === 0){
            const index_to_be_removed = fields.tickets.findIndex(t => {
                return parseInt(t.ticket_id) === parseInt(id);
            });
            fields['tickets'].splice(index_to_be_removed, 1);
        }
        this.setState({ fields }, () => {
            this.calculateTotal();
        });
    }
    updateCustomPrice(e, id) {
        let currentTicketValue = parseInt(this.getTicketValue(id, 'total_tickets'));
        if (currentTicketValue === 0) {
            currentTicketValue = 1;
        }
        let target = e.target;
        let fields = this.state.fields;
        if (fields.tickets && fields.tickets.length > 0) {
            const index_to_be_modified = fields.tickets.findIndex(t => {
                return parseInt(t.ticket_id) === parseInt(id);
            });
            if (index_to_be_modified < 0) {
                const ticket = {
                    ticket_id: id,
                    total_tickets: currentTicketValue,
                    ticket_price: target.dataset.price,
                    total_price: target.dataset.price * currentTicketValue,
                    description: target.dataset.description,
                    custom_price: target.value 
                }
                fields.tickets.push(ticket);
            }
            else {
                fields.tickets[index_to_be_modified].total_tickets = currentTicketValue;
                fields.tickets[index_to_be_modified].total_price = currentTicketValue * fields.tickets[index_to_be_modified].ticket_price;
                fields.tickets[index_to_be_modified].custom_price = target.value;
            }
        }
        else {
            const ticket = {
                ticket_id: id,
                total_tickets: currentTicketValue,
                ticket_price: target.dataset.price,
                total_price: target.dataset.price * currentTicketValue,
                description: target.dataset.description,
                custom_price: target.value 
            }
            fields.tickets.push(ticket);
        }
        if(currentTicketValue === 0){
            const index_to_be_removed = fields.tickets.findIndex(t => {
                return parseInt(t.ticket_id) === parseInt(id);
            });
            fields['tickets'].splice(index_to_be_removed, 1);
        }
        this.setState({ fields }, () => {
            this.calculateTotal();
        });
    }
    chooseTicketType = (e) => {
        let fields = this.state.fields;
        if (fields['tickets']) {
            if (e.target.checked) {
                let ticket = {
                    ticket_id: e.target.value,
                    total_tickets: 1,
                    ticket_price: e.target.dataset.price,
                    total_price: e.target.dataset.price * 1,
                    description: e.target.dataset.description,
                }
                fields['tickets'].push(ticket);
            }
            else {
                const index_to_be_removed = fields.tickets.findIndex(t => {
                    return parseInt(t.ticket_id) === parseInt(e.target.value);
                });
                fields['tickets'].splice(index_to_be_removed, 1);
            }
        }
        this.setState({ fields }, () => {
            this.calculateTotal();
        });
    };
    getTicketValue = (id, key) => {
        let fields = this.state.fields;
        if (fields.tickets) {
            let ticket = fields.tickets.filter(t => parseInt(t.ticket_id) === parseInt(id));
            if (ticket.length > 0) {
                if (key === 'total_tickets') {
                    return ticket[0].total_tickets;
                }
                else if (key === 'total_price') {
                    return ticket[0].total_price.toFixed(2);
                }
                else if (key === 'checkbox') {
                    return true;
                }
                else if (key === 'description') {
                    return ticket[0].description;
                }
                else if (key === 'custom_price') {
                    return ticket[0].custom_price;
                }
            }
            else {
                if (key === 'total_tickets') {
                    return 0;
                }
                else if (key === 'total_price') {
                    return 0;
                }
                else if (key === 'custom_price') {
                    return '';
                }
            }
        }
    }
    toggleSaleTicketForm = () => {
        if (moment(moment(this.props.eventDetails.end_date).format("YYYY-MM-DD")).isBefore(moment().format("YYYY-MM-DD"))) {
            this.setState({ showPassedEventModal: true });
        }
        else {
            this.setState((prevState) => ({
                showModal: !prevState.showModal,
                fields: {
                    event_id: this.props.eventDetails.id,
                    name: this.props.eventDetails.user.name,
                    tickets: [],
                    total_price: 0,
                    donation_amount: 0,
                    service_fee: this.props.eventDetails.service_fee
                },
                ticketLoader: true
            }), () => {
                events
                    .getTicketsWithDetails({
                        event_id: this.props.eventDetails.id
                    })
                    .then((res) => {
                        if (res.data.success) {
                            this.setState({ tickets: res.data.tickets, ticketLoader: false  }, () => {
                                let fields  = this.state.fields;
                                if(res.data.tickets.length > 0){
                                    let ticket = {
                                        ticket_id: res.data.tickets[0].id,
                                        total_tickets: 1,
                                        ticket_price: res.data.tickets[0].price,
                                        total_price: res.data.tickets[0].price * 1,
                                        description: res.data.tickets[0].description,
                                    }
                                    if(parseInt(this.props.eventDetails.allow_custom_price) === 1){
                                        ticket = {...ticket, custom_price: ''}
                                    }
                                    fields.tickets.push(ticket);
                                    this.setState({ fields }, () => {
                                        this.calculateTotal();
                                    });
                                }
                            })
                        } else if (res.data.error) {
                            console.log(res.data.message);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            });
        }

    }
    ticketsAvailable = (ticket_id) => {
        const ticket = this.state.tickets.filter(ticket => parseInt(ticket.id) === parseInt(ticket_id));
        let totalTicketsSold = ticket[0].bookings.reduce(function (previousValue, currentValue) {
            //return previousValue + parseInt(currentValue.total_tickets);
            if (currentValue.booking && parseInt(currentValue.booking.status) === 1) {
                return previousValue + parseInt(currentValue.total_tickets);
            }
            else {
                return previousValue;
            }
        }, 0);
        return parseInt(ticket[0].max_limit) - totalTicketsSold
        //return 5;
    }
    checkCreditDebitPaymentOption = (apps) => {
        let forBookingApps = apps.filter((app) => parseInt(app.for_booking) === 1);
        if (forBookingApps.length === 1 && parseInt(forBookingApps[0].service_id) === 73) {
            return true;
        }
        else {
            return false;
        }
    }
    render() {
        return (
            <>
                <p className="d-grid"> 
                <Button
                    variant="warning"
                    type="button"
                    onClick={() => this.toggleSaleTicketForm()}
                    size="lg"
                >
                    <strong>{this.props.eventDetails.button_text !== null ? this.props.eventDetails.button_text : 'Buy Tickets'}</strong>
                </Button>
                </p>
                <p className="text-center"><strong>Note:</strong> Click here to purchase ticket.</p>
                <Card className="p-3 mb-3">
                    <h4>Location</h4>
                    <p>{this.props.eventDetails.venue_name && <>{this.props.eventDetails.venue_name}<br /></>} {this.props.eventDetails.location}</p>
                    <h4>Refund Policy</h4>
                    <p>No Refund</p>
                </Card>
                {this.state.showModal &&
                    <Modal 
                        show={this.state.showModal} 
                        onHide={()=>this.setState({showModal:false})}
                        className="ticket_model"
                        keyboard={false}
                        centered
                        size="lg"
                    >
                        <Modal.Header closeButton><p className="mb-1" style={{fontSize:25}}>Book Ticket</p></Modal.Header>
                        <Modal.Body className="pt-3">
                            <p className="text-center">{this.props.eventDetails.title}</p>
                            {this.state.ticketLoader ? <div className="text-center pb-3"><Spinner /></div> : this.state.tickets.map((ticket, index) => <Row key={`ticket-index-${index}`}>
                                <Col>
                                    <Card className={(this.getTicketValue(ticket.id, 'checkbox') || false) ? "border-primary mb-3" : "mb-3"}>
                                        <Card.Body className="p-3">
                                            <Row>
                                                <Col>
                                                    <h5>{ticket.name}</h5>
                                                    <p className="mb-2">{common.currencySymbolsArr[this.props.eventDetails.currency]}{ticket.price} / person</p>
                                                    <p className="mb-2">{ticket.description}</p>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm={12} md={7}>
                                                    <InputGroup size="lg">
                                                        <InputGroup.Text onClick={(event) => this.updateTicketNumber(event, ticket.id, false)} data-price={ticket.price} data-description={ticket.description} style={{ cursor: 'pointer' }}>-</InputGroup.Text>
                                                        <span className="form-control counter">{this.getTicketValue(ticket.id, 'total_tickets')}</span>
                                                        <InputGroup.Text onClick={(event) => this.updateTicketNumber(event, ticket.id, true)} data-price={ticket.price} data-description={ticket.description} style={{ cursor: 'pointer' }}>+</InputGroup.Text>
                                                        <span className="ms-2 pt-3 total"><h4>Total: {common.currencySymbolsArr[this.props.eventDetails.currency]}{this.getTicketValue(ticket.id, 'total_price')}</h4></span>
                                                    </InputGroup>
                                                </Col>
                                                {parseInt(this.props.eventDetails.allow_custom_price) === 1 && <Col sm={12} md={5}>
                                                    <InputGroup size="lg">
                                                        <Form.Control type="number" onKeyDown={e => this.state.exceptThisSymbols.includes(e.key) && e.preventDefault()} onChange={(event)=>this.updateCustomPrice(event, ticket.id)} data-price={ticket.price} data-description={ticket.description} value={this.getTicketValue(ticket.id, 'custom_price')}/>
                                                        <InputGroup.Text style={{fontSize:15, fontWeight:'bold'}}>Your own price</InputGroup.Text>
                                                    </InputGroup>                           
                                                </Col>}
                                            </Row>
                                        </Card.Body>
                                        {this.props.userId && this.props.eventDetails.user_id === this.props.userId && <Card.Footer> {this.ticketsAvailable(ticket.id)} Ticket available </Card.Footer>}
                                    </Card>
                                </Col>
                            </Row>)}
                            {this.state.errors["tickets"] && <p className="text-danger">{this.state.errors["tickets"]}</p>}
                            {this.props.eventDetails.apps.length > 0 && !this.checkCreditDebitPaymentOption(this.props.eventDetails.apps) && <Row className="mt-3">
                                <Col md={12}><h3>Please enter the basic details</h3></Col>
                                <Form.Group as={Col} md={6} sm={12}>
                                    <FloatingLabel
                                        controlId="floatingInput"
                                        label="First Name"
                                        className="mb-3"
                                    >
                                        <Form.Control
                                            type="email"
                                            name="first_name"
                                            placeholder="first_name"
                                            onChange={(event) => this.handleChange(event, "first_name")}
                                            isInvalid={!!this.state.errors.first_name}
                                        />
                                        <Form.Control.Feedback type="invalid">{this.state.errors.first_name}</Form.Control.Feedback>
                                    </FloatingLabel>
                                </Form.Group>
                                <Form.Group as={Col} md={6} sm={12}>
                                    <FloatingLabel
                                        controlId="floatingInput"
                                        label="Last Name"
                                        className="mb-3"
                                    >
                                        <Form.Control
                                            type="email"
                                            name="last_name"
                                            placeholder="last_name"
                                            onChange={(event) => this.handleChange(event, "last_name")}
                                            isInvalid={!!this.state.errors.last_name}
                                        />
                                        <Form.Control.Feedback type="invalid">{this.state.errors.last_name}</Form.Control.Feedback>
                                    </FloatingLabel>
                                </Form.Group>
                                <Form.Group as={Col} md={6} sm={12}>
                                    <FloatingLabel
                                        controlId="floatingInput"
                                        label="Email"
                                        className="mb-3"
                                    >
                                        <Form.Control
                                            type="email"
                                            name="email"
                                            placeholder="email"
                                            onChange={(event) => this.handleChange(event, "email")}
                                            isInvalid={!!this.state.errors.email}
                                        />
                                        <Form.Control.Feedback type="invalid">{this.state.errors.email}</Form.Control.Feedback>
                                    </FloatingLabel>
                                </Form.Group>
                                <Form.Group as={Col} md={6} sm={12}>
                                    <Form.Label htmlFor="phone">Phone</Form.Label>
                                    <PhoneInput
                                        country={'us'}
                                        value={this.state.fields.phone ? this.state.fields.phone : ""}
                                        onChange={(phone) => {
                                            let fields = this.state.fields;
                                            fields['phone'] = phone;
                                            this.setState({fields})
                                        }}
                                        inputClass="w-100"
                                        enableSearch={true}
                                    />
                                    {this.state.errors.phone && <p className="text-danger">{this.state.errors.phone}</p>}
                                </Form.Group>
                                <Form.Group as={Col} md={6} sm={12}>
                                    <FloatingLabel
                                        controlId="floatingInput"
                                        label="Your payment app username"
                                        className="mb-3"
                                    >
                                        <Form.Control
                                            type="text"
                                            name="app_username"
                                            placeholder="app_username"
                                            onChange={(event) => this.handleChange(event, "app_username")}
                                            isInvalid={!!this.state.errors.app_username}
                                        />
                                        <Form.Control.Feedback type="invalid">{this.state.errors.app_username}</Form.Control.Feedback>
                                    </FloatingLabel>
                                </Form.Group>
                                <Col md={12} sm={12}>
                                    <span className="font-weight-bold">Grand Total: <span style={{ fontSize: 25 }}>{common.currencySymbolsArr[this.props.eventDetails.currency]}{this.state.fields.total_price.toFixed(2)}</span>{/* {parseInt(this.props.eventDetails.service_fee) > 0 && <small> (Additional {this.props.eventDetails.service_fee}% applied.)</small>} */}</span>
                                </Col>
                                <Col md={12} sm={12}>
                                    <Form.Check type="checkbox" name="term_accepted" value="1" onChange={(event)=>this.handleChange(event, 'terms_accepted')} label={<>By checking this box you agree to <a href="/terms-and-conditions">Our Terms Of Services</a> and <a href="/privacy-policy">Privacy Policy</a> as well as our partner's policies.</>}/>
                                </Col>
                            </Row>}
                            <p className="mb-0 mt-3 text-center">Please choose below payment method to pay the amount. <b>{common.currencySymbolsArr[this.props.eventDetails.currency]}{this.state.fields.total_price.toFixed(2)}</b></p>
                            <Row>
                                {this.props.eventDetails.apps.map((ele, index) => {
                                    if (parseInt(ele.for_booking) === 1) {
                                        return <Col md={12} sm={12} key={index} className="d-grid"><Button type="button" className="mt-3" variant="success" onClick={() => this.handleSubmit(ele)} disabled={this.state.submitted}>Pay <span style={{ fontSize: 25, fontWeight: 600 }}>{common.currencySymbolsArr[this.props.eventDetails.currency]}{this.state.fields.total_price.toFixed(2)}</span> with <img src={`/assets/${ele.appname.icon}`} width={32} /> {ele.appname.name} </Button></Col>
                                    }
                                })}
                            </Row>
                        </Modal.Body>
                    </Modal>
                }
                {this.state.zelleEmailModal && (
                    <ZelleModal
                        size="md"
                        zelleEmailModal={this.state.zelleEmailModal}
                        closeModal={() => {
                            this.setState({ zelleEmailModal: false });
                        }}
                        zelleEmail={this.state.zelleEmail}
                        appId={this.state.appId}
                    />
                )}
                {this.state.paypalPaymentFormModal && (
                    <PaypalPaymentForm
                        showModal={this.state.paypalPaymentFormModal}
                        closeModal={() => {
                            this.setState({ paypalPaymentFormModal: false });
                        }}
                        eventDetails={this.props.eventDetails}
                        amount={this.state.fields.total_price}
                        booking_id={this.state.booking_id}
                        editAmount={false}
                        capturePhone={(this.props.eventDetails.apps.length > 0 && !this.checkCreditDebitPaymentOption(this.props.eventDetails.apps)) ? false : true}
                        phone={this.state.fields.phone ? this.state.fields.phone : null}
                    />
                )}
                {this.state.showPassedEventModal && (
                    <PassedEventModal
                        showModal={this.state.showPassedEventModal}
                        closeModal={() => {
                            this.setState({ showPassedEventModal: false });
                        }}
                    />
                )}
            </>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        apiUrl: state.apiUrl,
        baseUrl: state.baseUrl,
        userId: state.userId,
        userName: state.userName,
    };
};
export default connect(mapStateToProps)(SaleTickets);
import React, { Component } from "react";
import {
  Button,
  Row,
  Col, Collapse, Spinner, Card
} from "react-bootstrap";
import events from "../../../services/events";
import { toast } from "react-toastify";
import ZelleModal from "../Common/ZelleModal";
import GroupDonorForm from "./GroupDonorForm"
import { connect } from "react-redux";
import { IoIosAdd } from "react-icons/io";
// import TransferFund from "./TransferFund";
// import StripePaymentModel from "./StripePaymentModel";
// import VerifyBank from "./VerifyBank";
// import DwollaPaymentForm from "./DwollaPaymentForm";
import PaypalPaymentForm from "../Common/PaypalPaymentForm";
import moment from "moment";
import PassedEventModal from "../Common/PassedEventModal";

class EventGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPassedEventModal:false,
      showModal: false,
      zelleEmailModal: false,
      zelleEmail: null,
      leaderShipLoader: true,
      groupCollections: [],
      visibleGroup: null,
      appList: [],
      selectedApp:null,
      transferDwollaFundModal:false,
      transferStripeFundModal:false,
      verifyBankModal:false,
      dwollaPaymentFormModal:false,
      appId:null,
      paypalPaymentFormModal:false,
      amount:0 // This amount is set for Credit debit card payment option
    };
  }

  toggleDonors = (groupId) => {
    this.setState({ visibleGroup: groupId })
  }
  
  toggleZelleModel = (appDetails) => {
    let zelleEmail = null;
    if (appDetails.url !== "" && appDetails.url.search("cash.app")) {
      zelleEmail = appDetails.url.split("/")[3];
    }
    else if (appDetails.phone && appDetails.phone !== "") {
      zelleEmail = appDetails.phone;
    } else {
      zelleEmail = appDetails.email;
    }
    this.setState({appId:appDetails.service_id, zelleEmail, zelleEmailModal: true }, () => {
      events
        .trackEventApp({
          event_id: appDetails.event_id,
          service_id: appDetails.service_id,
        })
        .then((res) => {
          if (res.data.success) {
          } else if (res.data.error) {
            toast.error(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch(() => {
          toast.error("Unexpected Error !", {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    });
  };

  trackEventApp = (appDetails) => {
    if (
      parseInt(appDetails.service_id) === 7 ||
      parseInt(appDetails.service_id) === 17 ||
      parseInt(appDetails.service_id) === 15 ||
      parseInt(appDetails.service_id) === 22 ||
      parseInt(appDetails.service_id) === 23 ||
      parseInt(appDetails.service_id) === 1 ||
      parseInt(appDetails.service_id) === 24
    ) {
      this.toggleZelleModel(appDetails);
    } else {
      events
        .trackEventApp({
          event_id: appDetails.event_id,
          service_id: appDetails.service_id,
        })
        .then((res) => {
          if (res.data.success) {
          } else if (res.data.error) {
            toast.error(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch(() => {
          toast.error("Unexpected Error !", {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
      let url = appDetails.url;
      //url = appDetails.default_amount === null ? url:url+"/"+appDetails.default_amount
      let aTag = document.createElement("a");
      aTag.setAttribute("href", url);
      //aTag.setAttribute("target", "_blank");
      document.body.appendChild(aTag);
      setTimeout(function () {
        aTag.click();
        document.body.removeChild(aTag);
      }, 500);
    }
  };

  openDonnerModal = (appDetails) => {
    if (
      moment(
        moment(this.props.eventDetails.end_date).format("YYYY-MM-DD")
      ).isBefore(moment().format("YYYY-MM-DD"))
    ) {
      this.setState({ showPassedEventModal: true });
    }
    else if (parseInt(appDetails.service_id) === 9) {
      this.trackEventApp(appDetails);
    } 
    else if (parseInt(appDetails.service_id) === 64) {
      this.setState({dwollaPaymentFormModal:true})
    }
    else if (parseInt(appDetails.service_id) === 65) {
      this.setState({transferStripeFundModal:true})
    }
    else if (parseInt(this.props.eventDetails.fundraising_for) === 1) {
      this.trackEventApp(appDetails);
    } else if (
      parseInt(appDetails.service_id) === 7 ||
      parseInt(appDetails.service_id) === 17 ||
      parseInt(appDetails.service_id) === 15 ||
      parseInt(appDetails.service_id) === 22 ||
      parseInt(appDetails.service_id) === 23 ||
      parseInt(appDetails.service_id) === 1 ||
      parseInt(appDetails.service_id) === 24
    ) {
      this.toggleZelleModel(appDetails);
    } 
    else if (parseInt(appDetails.service_id) === 73) {
      this.setState({paypalPaymentFormModal:true});
    }else {
      this.trackEventApp(appDetails);
    }
  };
  componentDidMount = () => {
    this.getEventApps();
    events
      .getLeadershipBoard({
        event_id: this.props.eventDetails.id,
      })
      .then((res) => {
        if (res.data.success) {
          this.setState({ groupCollections: res.data.records, leaderShipLoader: false })
        } else if (res.data.error) {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(() => {
        toast.error("Unexpected Error !", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  }
  getEventApps = () => {
    events
      .getEventApps({ event_id: this.props.eventDetails.id })
      .then((res) => {
        if (res.data.success) {
          this.setState({ appList: res.data.apps });
        }
        this.props.setAppListUpdateStatus(false);
      })
      .catch(() => {
        toast.error("Unexpected Error !", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };
  setAmount = (amount) => {
    this.setState({amount})
  }
  render() {
    return (
      <>
        <p className="d-grid mb-3">
          <Button
            variant="warning"
            type="button"
            onClick={() => this.setState({showModal:true, selectedApp:null})}
            size="lg"
          ><strong>Donate</strong></Button>
        </p>
        <Card className="mb-3">
          <Card.Header className="bg-light">
            <Row>
              <Col><h4 className="p-0 m-0 mt-1">Tipping Apps</h4></Col>
              <Col className="text-end">{this.props.userId && parseInt(this.props.userId) === parseInt(this.props.eventDetails.user_id) && (
              <span className="float-right">
                <Button
                  size="sm"
                  color="success"
                  onClick={() => this.props.setOpenTippingModal(true)}
                >
                  <IoIosAdd />
                  Add
                </Button>
              </span>
            )}</Col>
            </Row>
          </Card.Header>
          <Card.Body>
            <ul className="tipping-apps">
              {this.state.appList.map((ele, index) => <li key={`app-key-${index}`}>
                <a
                  href="javascript:void(0)"
                  style={{ cursor: "inherit" }}
                  onClick={()=>this.setState({selectedApp:ele, showModal: true})}
                >
                <div className="tipping-app-payment">
                  <img
                    src={`/assets/${ele.appname.icon}`}
                    style={{ cursor: "pointer" }}
                    width={60}
                  />
                </div></a></li>)}
            </ul>
          </Card.Body>
        </Card>
        <Card className="mb-3">
          <Card.Header className="bg-light"><h4 className="mb-0">Donation Board</h4></Card.Header>
          <Card.Body>
            {this.state.leaderShipLoader ? <Row><Col className="text-center mt-5"><Spinner /></Col></Row> : this.state.groupCollections.map((gc, gcIndex) => <Row key={gcIndex} className="mt-2 mb-2 border-bottom">
              <Col md={8} className="text-primary" style={{ cursor: `pointer` }} onClick={() => this.toggleDonors(gc.group_id)}><h5>{gc.group.name}</h5></Col>
              <Col md={4} className="text-end" style={{ cursor: `pointer` }}><h5 className="font-weight-bold">${parseFloat(gc.total).toFixed(2)}</h5></Col>
              {gc.donors.length > 0 && gc.donors.map((donor, dindex) => <Donor donor={donor} key={`donors-${dindex}`} slide={parseInt(this.state.visibleGroup) === parseInt(gc.group_id)} />)}
            </Row>)}
          </Card.Body>
        </Card>
       
        {this.state.showModal && <GroupDonorForm
            eventDetails={this.props.eventDetails}
            showModal={this.state.showModal}
            closeModal={() => this.setState({ showModal: false })}
            openDonnerModal={this.openDonnerModal}
            selectedApp={this.state.selectedApp}
            setAmount={this.setAmount}
        />}
        {this.state.zelleEmailModal && (
          <ZelleModal
            size="md"
            zelleEmailModal={this.state.zelleEmailModal}
            closeModal={() => {
              this.setState({ zelleEmailModal: false });
            }}
            zelleEmail={this.state.zelleEmail}
          />
        )}
       {/*  {this.state.dwollaPaymentFormModal && (
          <DwollaPaymentForm
            showModal={this.state.dwollaPaymentFormModal}
            closeModal={() => {
              this.setState({ dwollaPaymentFormModal: false });
            }}
            initiateVefification={(dwollaCustomerUrl)=>this.setState({verifyBankModal:true, dwollaPaymentFormModal:false, dwollaCustomerUrl})}
            
          />
        )}
        {this.state.verifyBankModal && (
          <VerifyBank
            showModal={this.state.verifyBankModal}
            closeModal={()=>this.setState({verifyBankModal:false})}
            initiateTranser={(dwollaFundingSource)=>this.setState({verifyBankModal:false, transferDwollaFundModal:true, dwollaFundingSource})}
            dwollaCustomerUrl={this.state.dwollaCustomerUrl}
            //memberId={this.state.memberId}
            eventId={this.props.eventDetails.id}
          />
        )}

        {this.state.transferDwollaFundModal && (
          <TransferFund
            showModal={this.state.transferDwollaFundModal}
            closeModal={()=>this.setState({transferDwollaFundModal:false})}
            eventId={this.props.eventDetails.id}
            dwollaFundingSource={this.state.dwollaFundingSource}
          />
        )} */}
        {/* {this.state.transferStripeFundModal && (
          <StripePaymentModel
            showModal={this.state.transferStripeFundModal}
            closeModal={()=>this.setState({transferStripeFundModal:false})}
            eventId={this.props.eventDetails.id}
          />
        )} */}
        {this.state.paypalPaymentFormModal && (
          <PaypalPaymentForm
            showModal={this.state.paypalPaymentFormModal}
            closeModal={()=>this.setState({paypalPaymentFormModal:false})}
            eventDetails={this.props.eventDetails}
            amount={this.state.amount}
            editAmount={true}
            
          />
        )}
        {this.state.showPassedEventModal && (
          <PassedEventModal
            showModal={this.state.showPassedEventModal}
            closeModal={() => {
              this.setState({ showPassedEventModal: false });
            }}
          />
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    apiUrl: state.apiUrl,
    baseUrl: state.baseUrl,
    userId: state.userId,
    userName: state.userName,
  };
};
export default connect(mapStateToProps)(EventGroup);

const Donor = (props) => {
  return <Collapse in={props.slide} className="col-md-12">
    <Row>
      <Col md={8} className="text-primary"><h6 className="pl-2">{props.donor.first_name} {props.donor.last_name}</h6></Col>
      <Col md={4} className="text-end"><h6>${props.donor.amount}</h6></Col>
    </Row>
  </Collapse>
};

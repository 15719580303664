/* eslint-disable react/prop-types */
import { Button, Table, Row,Col,Form,Spinner,Badge } from "react-bootstrap";
import React, { Component } from "react";
import LoadingOverlay from "react-loading-overlay";
import { toast } from "react-toastify";
import events from "../../../services/events";
import user from "../../../services/user";
import moment from "moment";
import { connect } from "react-redux";
import { FaListAlt, FaCheckCircle, FaFileExcel } from "react-icons/fa";
import DonorDetails from "../Event/DonorDetails";
import Pagination from "react-js-pagination";
import common from "../../../services/common";
import TableLoader from "../Loaders/TableLoader"

LoadingOverlay.propTypes = undefined

class ListDonors extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageNo: 1,
      loader: false,
      donors: [],
      pageDetails: {},
      disabled: false,
      showDonorDetailModal: false,
      fields:{},
      errors:{},
      submitted:false,
      events:[],
      selectedDonors:[]
    };
  }
  handleChange = (e, field) => {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
  };
  handleSubmit = (e = null) => {
    if (e) e.preventDefault();
    this.setState({ submitted: true },()=>{
        this.listDonors();
    });
  };
  
  listDonors = () => {
    this.setState({ loader: true });
    events
      .listDonors({
        event_id: this.props.selectedEventId,
        page: this.state.pageNo,
        verified:this.props.verified?this.props.verified:0,
        fields:this.state.fields
      })
      .then((res) => {
        if (res.data.success) {
          this.setState({ donors: res.data.donors, loader: false, submitted:false, pageDetails:res.data.pages });
        } else if (res.data.error) {
          console.log(res.data.message);
        }
        this.setState({ loader: false });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  componentDidMount = () => {
    events
    .list({ published: 1 })
    .then((res) => {
      if (res.data.success) {
        this.setState({ events: res.data.events });
      } else if (res.data.error) {
        toast.error(res.data.error.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    })
    .catch(() => {
      toast.error("Unexpected error !", {
        position: toast.POSITION.TOP_RIGHT,
      });
    });
    this.listDonors();
  };
  showDonorDetails = (id) => {
    this.setState({ showDonorDetailModal: true, event_id: id });
  };
  sendFollowUpEmail = (donor_id) =>{
    events
      .sendFollowUpEmail({
        donor_id: donor_id,
      })
      .then((res) => {
        if (res.data.success) {
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else if (res.data.error) {
          console.log(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  deleteDonor = (id) => {
    if (window.confirm('Are you sure to delete?')) {
      let params = { id: id }
      events.deleteDonor(params).then((response) => {
        if (response.data.success) {
          let donors = this.state.donors.filter((donor) => parseInt(donor.id) !== parseInt(id))
          this.setState({donors},()=>{
            toast.success(response.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            })
          })
        } else {
          toast.error(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          })
        }
      }).catch((err) => {
        console.log(err)
        toast.error('Unexpected error !', {
        position: toast.POSITION.TOP_RIGHT,
        })
      })
    }
  }

  deleteMultipleDonors = () => {
    if (window.confirm('Are you sure to delete?')) {
      let params = { id: this.state.selectedDonors }
      events.deleteDonor(params).then((response) => {
        if (response.data.success) {
          toast.success(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          })
          this.setState({selectedDonors:[]},()=>this.listDonors());
        } else {
          toast.error(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          })
        }
      }).catch((err) => {
        console.log(err)
        toast.error('Unexpected error !', {
        position: toast.POSITION.TOP_RIGHT,
        })
      })
    }
  }

  handlePageChange = (pageNo) => {
    this.setState({ pageNo }, () => {
      this.listDonors();
    });
  };
  selectDonor = (e) => {
    let selectedDonors = this.state.selectedDonors;
    if (e.target.checked) {
      selectedDonors.push(e.target.value);
    } else {
      let index_to_be_removed = selectedDonors.indexOf(e.target.value);
      selectedDonors.splice(index_to_be_removed, 1);
    }
    this.setState({ selectedDonors });
  };
  selectAll = (e) => {
    let checkboxes = document.querySelectorAll('Form.Control[type=checkbox]');
    checkboxes.forEach(c=>{
      c.checked = e.target.checked;
    });
    let selectedDonors = [];
    if(e.target.checked){
      this.state.donors.forEach((d,index)=>{
        selectedDonors[index] = d.id;
      });
    }
    this.setState({selectedDonors});
  }
  exportExcel = () =>{
    this.setState({loader:true});
    user.exportDonors({export:true}).then((res) => {
        if (res) {
          const fileDetails = new Blob([res.data],{ type: res.data.type, encoding: 'UTF-8' })
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(fileDetails);
          link.download = 'donors-'+moment().format("MM-DD-YYYY")+'.xlsx';
          link.click();
          link.remove();
        } else if (res.data.error) {
          toast.error(res.data.error.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
        this.setState({loader:false});
      })
      .catch(() => {
        toast.error("Unexpected error !", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  }
  render() {
    return (
      <>
        <Form onSubmit={this.handleSubmit}>
            <Row>
                <Col>
                      <Form.Select
                          name="event_id"
                          onChange={(e) => {
                              this.handleChange(e, "event_id");
                          }}
                      >
                          <option value="">-- Select Event --</option>
                          {this.state.events.map((ele, index) => (
                              <option key={index} value={ele.id}>
                                  {ele.title}
                              </option>
                          ))}
                      </Form.Select>
                </Col>
                <Col>
                    <Form.Control type="text" placeholder="Donor Name" onChange={(e) => {
                          this.handleChange(e, "name");
                      }}
                      />
                </Col>
                <Col>
                    <Form.Control type="text" placeholder="Donor Email" onChange={(e) => {
                          this.handleChange(e, "email");
                      }}
                          
                      />
                </Col>
                <Col>
                    <Form.Control type="text" placeholder="Donor Phone" onChange={(e) => {
                          this.handleChange(e, "phone");
                      }}
                          
                      />
                </Col>
                <Col>
                    <Form.Control type="number" placeholder="Amount" onChange={(e) => {
                          this.handleChange(e, "amount");
                      }}
                          
                      />
                </Col>
                <Col className="d-grid">
                    <Button type="submit" className="btn-block" variant="success" disabled={this.state.submitted}>{this.state.submitted && <Spinner size="sm"/>} Search</Button>
                </Col>
            </Row>
        </Form>
        <LoadingOverlay
            active={this.state.loader}
            text={<TableLoader />}
        >
          {this.state.donors.length > 0 && <Row>
            <Col md={12} className="text-end">
                <Button type="button" variant="warning" onClick={()=>this.exportExcel()}><FaFileExcel size="1em" className="mb-1 mr-1"/>Export</Button>
            </Col>
          </Row>}
          
            <Table className="table table-responsive customise_table">
                <thead>
                    <tr>
                    <th><Form.Check type="checkbox" onChange={this.selectAll} value={0}/></th>
                    <th>#</th>
                    <th>Fist Name</th>
                    <th>Last Name</th>
                    <th>Email</th>
                    <th>Amount Donated</th>
                    <th>Added On</th>
                    <th colSpan="2">Action</th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.donors.length > 0 ? (
                    this.state.donors.map((ele, index) => (
                        <tr key={index}>
                        <th><Form.Control type="checkbox" onChange={this.selectDonor} value={ele.id}/></th>
                        <td>{this.state.pageNo === 1
                            ? index + 1
                            : (this.state.pageNo - 1) * 50 + index + 1}
                            . </td>
                        <td>{ele.donor_first_name}</td>
                        <td>{ele.donor_last_name}</td>
                        <td> {ele.donor_email}</td>
                        <td> {common.currencySymbols[ele.currency]} {ele.amount_donated}</td>
                        <td> {moment(ele.added_on).format("MMM Do YYYY")}</td>
                        <td>
                            <Button type="button" className="btn-sm mr-1" variant="primary" onClick={() => this.showDonorDetails(ele.id)}> {parseInt(ele.verified) === 1 ? <FaCheckCircle size="1.5em"/>:<FaListAlt size="1.5em"/>}</Button>
                            <Button type="button" className="btn-sm mr-1" variant="warning" onClick={() => this.sendFollowUpEmail(ele.id)}>Follow Up</Button>
                            <Button type="button" className="btn-sm mr-1" variant="danger" onClick={() => this.deleteDonor(ele.id)}>Delete</Button>
                        </td>
                        </tr>
                    ))
                    ) : (
                    <tr>
                        <td colSpan="8" className="text-center" style={{height:300}}>
                            Record not found.
                        </td>
                    </tr>
                    )}
                    <tr>
                        <td colSpan="2"><Button type="button" variant="danger" disabled={this.state.selectedDonors.length === 0} onClick={this.deleteMultipleDonors}><Badge variant="primary" style={{width:'auto',overflow:'auto'}}>{this.state.selectedDonors.length}</Badge> Delete</Button></td>
                        <td colSpan="6">
                          <Pagination
                            activePage={this.state.pageNo}
                            itemsCountPerPage={30}
                            totalItemsCount={
                              this.state.pageDetails.totalCount?parseInt(this.state.pageDetails.totalCount):0
                            }
                            pageRangeDisplayed={5}
                            onChange={(e) => this.handlePageChange(e)}
                          />
                        </td>
                      </tr>
                </tbody>
            </Table>
        </LoadingOverlay>
        

        {this.state.showDonorDetailModal && (
          <DonorDetails
            showModal={this.state.showDonorDetailModal}
            id={this.state.event_id}
            closeModal={() => this.setState({ showDonorDetailModal: false })}
            reloadDonors={this.listDonors}
            editAmount={true}
          />
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    apiUrl: state.apiUrl,
    baseUrl: state.baseUrl,
    userId: state.userId,
    userName: state.userName,
  };
};
export default connect(mapStateToProps)(ListDonors);

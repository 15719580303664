/* eslint-disable react/prop-types */
import { Button, Table, Row, Col, Card, Form } from "react-bootstrap";
import React, { Component } from "react";
import LoadingOverlay from "react-loading-overlay";
import { toast } from "react-toastify";
import events from "../../../services/events";
import common from "../../../services/common";
import { connect } from "react-redux";
import AddEditTicket from "./AddEditTicket";

class ManageTickets extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageNo: 1,
      pageSize: 20,
      loader: false,
      records: [],
      showModal: false,
      showDesignMembershipDueFormModal: false,
      ticketId: null,
      exceptThisSymbols:["e", "E", "+", "-", "."]
    };
  }
  componentDidMount = () => {
    this.listRecords();
    console.log(this.props.formData)
  }
  listRecords = () => {
    this.setState({ loader: true });
    events
      .getTickets({
        page: this.state.pageNo,
        event_id: this.props.eventId
      })
      .then((res) => {
        if (res.data.success) {
          this.setState({ records: res.data.tickets, pageDetails: res.data.pages });
        } else if (res.data.error) {
          console.log(res.data.message);
        }
        this.setState({ loader: false });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  deleteRecord = (index) => {
    if (window.confirm('Are you sure to delete?')) {
      if (this.state.records[index].event_id === undefined) {
        let records = this.state.records.filter((r, indx) => index !== indx);
        this.setState({ records });
      }
      else {
        let params = { id: this.state.records[index].id }
        events.deleteTicket(params).then((response) => {
          if (response.data.success) {
            let records = this.state.records.filter((r) => parseInt(r.id) !== parseInt(this.state.records[index].id))
            this.setState({ records }, () => {
              toast.success(response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
              })
            })
          } else {
            toast.error(response.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            })
          }
        }).catch((err) => {
          console.log(err)
          toast.error('Unexpected error !', {
            position: toast.POSITION.TOP_RIGHT,
          })
        })
      }
    }
  }
  updateRecords = (records) => {
    let newRecords = [...this.state.records, records];
    this.setState({ records: newRecords, showModal: false }, ()=>{
      let ticketsWithoutEventId = newRecords.filter(nr=>nr.event_id === undefined);
      this.props.updateTickets(ticketsWithoutEventId);
    });
  }
  updateFormData = (formData) => {
    this.setState({showDesignMembershipDueFormModal:false}, ()=>{
      this.props.updateFormData(formData);
    })
  }
  render() {
    return (
      <>
        <Card style={{ border: '1px solid #c8ced3' }} className="mb-2">
          <Card.Header style={{ backgroundColor: '#f0f3f5', borderBottom: '1px solid #c8ced3' }}>
            <Row>
              <Col><h4>Manage Tickets</h4></Col>
              <Col className="text-end">
                <Button type="button" size="sm" varient="success" style={{ backgroundColor: 'green', width: 'auto' }} onClick={() => this.setState({ showModal: true })}>Add New Ticket</Button>
              </Col>
            </Row>
          </Card.Header>
          <Card.Body className="p-2">
            <LoadingOverlay
              active={this.state.loader}
              spinner
              text="Loading..."
            >
              <Table className="table table-responsive customise_table mb-0">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Price</th>
                    <th>Limit</th>
                    <th>Physical Ticket Limit</th>
                    <th colSpan={2} width="20%">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.records.length > 0 ? (
                    this.state.records.map((ele, index) => 
                      <tr key={`member--${index}`}>
                        <td>{this.state.pageNo === 1
                          ? index + 1
                          : (this.state.pageNo - 1) * this.state.pageSize + index + 1}
                          . </td>
                        <td>{ele.name}</td>
                        <td>{common.currencySymbolsArr[this.props.currency]}{ele.price}</td>
                        <td>{ele.max_limit}</td>
                        <td>{ele.physical_ticket_limit}</td>
                        {ele.event_id && <td><Button type="button" className="btn-sm" color="primary" style={{ backgroundColor: 'blue', width: 'auto' }} onClick={() => this.setState({ ticketId: ele.id, showModal: true })}>Edit</Button></td>}
                        <td><Button type="button" className="btn-sm" color="danger" style={{ backgroundColor: 'red', width: 'auto' }} onClick={() => this.deleteRecord(index)}>Delete</Button></td>
                      </tr>
                    )
                  ) : (
                    <tr>
                      <td colSpan="15" className="text-center" style={{ height: 100 }}>Record not found.</td>
                    </tr>
                  )}
                  {this.state.records.length > 0 && <tr>
                      <td colSpan="15">
                        <Form.Check type="checkbox" id="allow_custom_price" label="Allow buyer to enter their own price." inline    className="font-weight-bold" defaultChecked={parseInt(this.props.allowCustomPrice) === 1} name='allow_custom_price' onChange={(event) => this.props.handleChange(event, "allow_custom_price")}/>
                      </td>
                  </tr>}
                </tbody>
              </Table>
            </LoadingOverlay>
            {this.state.showModal && (
              <AddEditTicket
                showModal={this.state.showModal}
                eventId={this.props.eventId}
                closeModal={() => this.setState({ showModal: false, ticketId: null })}
                listRecords={this.listRecords}
                ticketId={this.state.ticketId}
                updateRecords={this.updateRecords}
              />
            )}
            {/* {this.state.records.length > 0 && 
              <div className="d-flex justify-content-center form-inline">
                <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                  <Label for="exampleEmail" className="mr-sm-2">Enter Service Fee (%)</Label>
                  <InputGroup>
                    <Input type="number" name="service_fee" onChange={(event) => this.props.handleChange(event, "service_fee")} value={this.props.service_fee} onKeyDown={e => this.state.exceptThisSymbols.includes(e.key) && e.preventDefault()}/>
                    <InputGroupAddon addonType="append">%</InputGroupAddon>
                  </InputGroup>
                </FormGroup>
              </div>
            } */}
          </Card.Body>
        </Card>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    apiUrl: state.apiUrl,
    baseUrl: state.baseUrl,
    userId: state.userId,
    userName: state.userName,
  };
};
export default connect(mapStateToProps)(ManageTickets);
